import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from 'events';

@Component({
    selector: 'projeto-abertura-caixa-postal-grid-filtro',
    templateUrl: './projeto-abertura-caixa-postal-grid-filtro.component.html',
    styleUrls: ['./projeto-abertura-caixa-postal-grid-filtro.component.scss']
})
export class ProjetoAberturaCaixaPostalGridFiltroComponent
    implements OnInit {

    @Output() filtrar = new EventEmitter();

    constructor() {
    }

    ngOnInit() {

    }

    pesquisarPorTexto() {

    }

    limparCampoPesquisa() {

    }

}
