import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { DataUtil } from '@utils/utils/class/data.util';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';
import { UsuarioService } from 'app/main/usuario/shared/services/usuario.service';
import { ProjetoStatus } from '../../shareds/interfaces/projeto-status.interface';
import { ProjetoRequest } from '../../shareds/interfaces/projeto.interface';
import { Time } from 'app/main/time/shareds/interfaces/time.interface';
import { ProjetoStatusService } from '../../shareds/services/projeto-status.service';
import { StatusReportService } from '../../shareds/services/status-report.service';
import { ProjetoService } from '../../shareds/services/projeto.service';
import { TimeService } from 'app/main/time/shareds/services/time.service';
import { SimNao } from '@utils/interfaces/shareds/sim-nao.interface';
import { SimNaoService } from '@utils/services/sim-nao.service';

@Component({
    selector: 'projeto-alteracao-lote',
    templateUrl: './projeto-alteracao-lote.component.html',
    styleUrls: ['./projeto-alteracao-lote.component.scss']
})
export class ProjetoAlteracaoLoteComponent extends FormBase implements OnInit {

    private _projetoEditar: ProjetoRequest;

    isViewProjetosIds: boolean = false;
    checkStatus = false;
    checkStatusReport = false;
    checkFaturado = false;
    checkDataInicio = false;
    checkDataFim = false;
    checkResponsavel = false;
    checkTime = false;
    checkObservacao = false;

    listaStatus: Array<ProjetoStatus> = [];
    listaStatusReports: Array<any> = []; // COLOQUEI ANY POR QUE NÃO ENCONTREI O INTERFACE DO STATUS REPORT
    listaUsuarios: Array<Usuario> = [];
    listaTimes: Array<Time> = [];
    listaSimNao: Array<SimNao> = [];

    @Input() set idsProjetos(projetosEditar: any) {
        const idsProjetos = projetosEditar.projetosSelecionadas;
        this._projetoEditar = { idsProjetos: idsProjetos };
        this.isViewProjetosIds = (idsProjetos.length > 0);
    };
    @Output() atualizado: EventEmitter<string> = new EventEmitter();

    constructor(private _injector: Injector,
        private _statusService: ProjetoStatusService,
        private _statusReportService: StatusReportService,
        private _projetoService: ProjetoService,
        private _usuarioService: UsuarioService,
        private _simNaoService: SimNaoService,
        private _timeService: TimeService) {
        super(_injector);
    }

    protected crateForm(): { [key: string]: any; } {
        return {
            status: [],
            statusReport: [],
            dataInicio: [],
            dataFim: [],
            responsavel: [],
            faturado: [],
            time: [],
            observacao: [],
            projetoFaturado: []
        };
    }

    ngOnInit() {
        this._spinner.show();
        this._statusService.find({}).subscribe(
            (result) => {
                this._spinner.hide();
                this.listaStatus = result.data;
            },
            (error) => this.error(error)
        );
        this._spinner.show();
        this._statusReportService.find({}).subscribe(
            (result) => {
                this._spinner.hide();
                this.listaStatusReports = result.data;
            },
            (error) => this.error(error)
        );
        this._spinner.show();
        this._usuarioService.find({}).subscribe(
            (result) => {
                this._spinner.hide();
                this.listaUsuarios = result.data;
            },
            (error) => this.error(error)
        );
        this._spinner.show();
        this._timeService.find({}).subscribe(
            (result) => {
                this._spinner.hide();
                this.listaTimes = result.data;
            },
            (error) => this.error(error)
        );

        this._simNaoService.find().subscribe(
            (result) => {
                this.listaSimNao = result;
            },
            (error) => this.error(error)
        );
    }

    selecionaTodos(check) {
        if (!check) {
            this.form.reset();
        }
        this.checkStatus = check;
        this.checkStatusReport = check;
        this.checkDataInicio = check;
        this.checkDataFim = check;
        this.checkResponsavel = check;
        this.checkFaturado = check;
        this.checkTime = check;
        this.checkObservacao = check;
    }

    isDesabled(chave: string) {
        switch (chave) {
            case 'checkStatus':
                return !this.checkStatus
                break;
            case 'checkStatusReport':
                return !this.checkStatusReport
                break;
            case 'checkDataInicio':
                return !this.checkDataInicio;
                break;
            case 'checkDataFim':
                return !this.checkDataFim;
                break;
            case 'checkResponsavel':
                return !this.checkResponsavel;
                break;
            case 'checkTime':
                return !this.checkTime;
                break;
            case 'checkFaturado':
                return !this.checkFaturado;
                break;
            case 'checkObservacao':
                return !this.checkObservacao;
                break;
        }
    }

    selecionaCheckStatus(check: boolean) {
        this.checkStatus = check;
        if (!check) {
            this.form.get('status').setValue('');
        }
    }

    selecionaCheckStatusReport(check: boolean) {
        this.checkStatusReport = check;
        if (!check) {
            this.form.get('statusReport').setValue('');
        }
    }

    selecionaCheckFaturado(check: boolean) {
        this.checkFaturado = check;
        if (!check) {
            this.form.get('projetoFaturado').setValue('');
        }
    }

    selecionaCheckDataInicio(check: boolean) {
        this.checkDataInicio = check;
        if (!check) {
            this.form.get('dataInicio').setValue('');
        }
    }

    selecionaCheckDataFim(check: boolean) {
        this.checkDataFim = check;
        if (!check) {
            this.form.get('dataFim').setValue('');
        }
    }

    selecionaCheckResponsavel(check: boolean) {
        this.checkResponsavel = check;
        if (!check) {
            this.form.get('checkResponsavel').setValue('');
        }
    }

    selecionaCheckTime(check: boolean) {
        this.checkTime = check;
        if (!check) {
            this.form.get('time').setValue('');
        }
    }

    selecionaCheckObservacao(check: boolean) {
        this.checkTime = check;
        if (!check) {
            this.form.get('observacao').setValue('');
        }
    }

    alterar() {
        const dataInicioReal = this.form.get('dataInicio').value;
        const dataFimReal = this.form.get('dataFim').value;
        const status = this.form.get('status').value
        const statusReport = this.form.get('statusReport').value
        const responsavel = this.form.get('responsavel').value
        const time = this.form.get('time').value
        const observacao = this.form.get('observacao').value
        const projetoFaturado = this.form.get('projetoFaturado').value




        this._projetoEditar = Object.assign({}, this._projetoEditar, {
            idStatus: (this.checkStatus == true) && status ? status : null,
            idStatusReport: (this.checkStatusReport == true) && statusReport ? statusReport : null,
            dataInicioReal: (this.checkDataInicio == true) && dataInicioReal ? DataUtil.toDateBackend(dataInicioReal) : null,
            dataFimReal: (this.checkDataFim == true) && dataFimReal ? DataUtil.toDateBackend(dataFimReal) : null,
            responsavel: (this.checkResponsavel == true) && responsavel ? responsavel : null,
            idTime: (this.checkTime == true) && time ? time : null,
            faturado: (this.checkFaturado == true) && projetoFaturado ? parseInt(projetoFaturado) : null,
            observacao: observacao,
        });

        if (this._projetoEditar.idsProjetos) {
            this._spinner.show();
            this._projetoService.batchUpdate(this._projetoEditar).subscribe(
                () => {
                    this._spinner.hide();
                    this.atualizado.emit('true');
                    this.sucess(
                        'Cotações alteradas com sucesso!'
                    );
                },
                (error) => this.error(error)
            );
        }
    }

}
