import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { Response } from '@utils/interfaces/http/response.interface';
import { FiltroProjetoAbertura } from '../interfaces/filtro-projeto-abertura.interface';
import { ProjetoAbertura } from '../interfaces/projeto-abertura.interface';
import { Projeto } from '../interfaces/projeto.interface';
import { ProjetoAberturaConfiguracao, ProjetoAberturaData } from '../interfaces/projeto-abertura-configuracao.interface';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ProjetoAberturaService extends ServiceGridBase<
ProjetoAbertura,
FiltroProjetoAbertura
> {
    private path: string = `${environment.server}/projetoabertura`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroProjetoAbertura
    ): Observable<Response<Array<ProjetoAbertura>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/busca${params}`;
        return this._http.get<Response<Array<ProjetoAbertura>>>(url);
    }

    findById(id: number | string): Observable<ProjetoAberturaConfiguracao> {
        const url = `${environment.server}/os/osabertura/${id}`;
        return this._http.get<Response<any>>(url).pipe(map((resp) => this.fromTo(resp.data)));
    }

    update(id: number | string, aberturaConfiguracao: ProjetoAberturaData): Observable<Response<ProjetoAberturaData>> {
        const url = `${environment.server}/os/osabertura/${id}`;
        return this._http.put<Response<ProjetoAberturaData>>(url, aberturaConfiguracao);
    }

    validarMapa(mapa: string): Observable<Response<Array<any>>> {
        const url = `${environment.server}/configurador/validar-mapa/${mapa}`;
        return this._http.get<Response<Array<any>>>(url);
    }

    private fromTo(response: any): ProjetoAberturaConfiguracao {
        let projetoAbertura = {
            id: response.configuracao.id,
            layoutRemessaId: response.configuracao.layoutRemessa.id,
            layoutRetornoId: response.configuracao.layoutRetorno.id,
            layoutAtivacaoId: (response.configuracao.layoutAtivacao) ? response.configuracao.layoutAtivacao.id : null,
            core: undefined,
            dsnames: undefined,
            empresaId: response.empresa.id,
            produtoFinanceiro: response.produtoFinanceiro,
        };

        projetoAbertura.core = {
            id: response.id,
            empresaNome: response.empresa.nome,
            empresaInsc: response.empresa.inscricao,
            banco: (response.os.banco.codigo) ? response.os.banco.codigo : null,
            bancoNome: response.os.banco.nome,
            agencia: response.agencia,
            agenciaDV: response.agenciaDV,
            conta: response.conta,
            contaDV: response.contaDV,
            convenio: response.convenio,
        };

        projetoAbertura.dsnames = {
            remessa: {
                id!: response.configuracao.dsnameRemessa.id,
                custoTrafego!: (response.configuracao.dsnameRemessa.custoTrafego) ? response.configuracao.dsnameRemessa.custoTrafego.id : null,
                dsname!: (response.configuracao.dsnameRemessa.dsname) ? response.configuracao.dsnameRemessa.dsname : null,
                rename!: (response.configuracao.dsnameRemessa.rename) ? response.configuracao.dsnameRemessa.rename : null,
                maxRecSize!: (response.configuracao.dsnameRemessa.maxRecSize) ? response.configuracao.dsnameRemessa.maxRecSize : null,
                insertSeqNumberLen!: (response.configuracao.dsnameRemessa.insertSeqNumberLen) ? response.configuracao.dsnameRemessa.insertSeqNumberLen : null,
                insertSeqNumberMax!: (response.configuracao.dsnameRemessa.insertSeqNumberMax) ? response.configuracao.dsnameRemessa.insertSeqNumberMax : null,
                insertSeqNumberMin!: (response.configuracao.dsnameRemessa.insertSeqNumberMin) ? response.configuracao.dsnameRemessa.insertSeqNumberMin : null,
                mapas!: (response.configuracao.dsnameRemessa.mapas) ? response.configuracao.dsnameRemessa.mapas : null,
                cxpIdOrigem!: (response.configuracao.dsnameRemessa.caixaPostalOrigem) ? response.configuracao.dsnameRemessa.caixaPostalOrigem.id : null,
                cxpIdDestino!: (response.configuracao.dsnameRemessa.caixaPostalDestino) ? response.configuracao.dsnameRemessa.caixaPostalDestino.id : null,
                cxpCodigoOrigem!: (response.configuracao.dsnameRemessa.caixaPostalOrigem) ? response.configuracao.dsnameRemessa.caixaPostalOrigem.codigo : null,
                cxpCodigoDestino!: (response.configuracao.dsnameRemessa.caixaPostalDestino) ? response.configuracao.dsnameRemessa.caixaPostalDestino.codigo : null,
                fileRegexEbcdic!: (response.configuracao.dsnameRemessa.fileRegexEbcdic) ? response.configuracao.dsnameRemessa.fileRegexEbcdic : null,
                maxRecSizeEbcdic!: (response.configuracao.dsnameRemessa.maxRecSizeEbcdic) ? response.configuracao.dsnameRemessa.maxRecSizeEbcdic : null,
                formato!: (response.configuracao.dsnameRemessa.formato) ? response.configuracao.dsnameRemessa.formato.id : null,
                crlf!: (response.configuracao.dsnameRemessa.crlf) ? response.configuracao.dsnameRemessa.crlf.id : null,
                timestampTx!: (response.configuracao.dsnameRemessa.timestampTx) ? response.configuracao.dsnameRemessa.timestampTx.id : null,
                timestampRx!: (response.configuracao.dsnameRemessa.timestampRx) ? response.configuracao.dsnameRemessa.timestampRx.id : null,
                removeAcentos!: (response.configuracao.dsnameRemessa.removeAcentos) ? response.configuracao.dsnameRemessa.removeAcentos.id : null,
                insertTimestamp!: (response.configuracao.dsnameRemessa.insertTimestamp) ? response.configuracao.dsnameRemessa.insertTimestamp.id : null,
                insertSeqType!: (response.configuracao.dsnameRemessa.insertSeqType) ? response.configuracao.dsnameRemessa.insertSeqType.id : null,
                insertSeq!: (response.configuracao.dsnameRemessa.insertSeq) ? response.configuracao.dsnameRemessa.insertSeq.id : null,
            },
            retorno: {
                id!: response.configuracao.dsnameRetorno.id,
                custoTrafego!: (response.configuracao.dsnameRetorno.custoTrafego) ? response.configuracao.dsnameRetorno.custoTrafego.id : null,
                dsname!: (response.configuracao.dsnameRetorno.dsname) ? response.configuracao.dsnameRetorno.dsname : null,
                rename!: (response.configuracao.dsnameRetorno.rename) ? response.configuracao.dsnameRetorno.rename : null,
                maxRecSize!: (response.configuracao.dsnameRetorno.maxRecSize) ? response.configuracao.dsnameRetorno.maxRecSize : null,
                insertSeqNumberLen!: (response.configuracao.dsnameRetorno.insertSeqNumberLen) ? response.configuracao.dsnameRetorno.insertSeqNumberLen : null,
                insertSeqNumberMax!: (response.configuracao.dsnameRetorno.insertSeqNumberMax) ? response.configuracao.dsnameRetorno.insertSeqNumberMax : null,
                insertSeqNumberMin!: (response.configuracao.dsnameRetorno.insertSeqNumberMin) ? response.configuracao.dsnameRetorno.insertSeqNumberMin : null,
                mapas!: (response.configuracao.dsnameRetorno.mapas) ? response.configuracao.dsnameRetorno.mapas : null,
                cxpIdOrigem!: (response.configuracao.dsnameRetorno.caixaPostalOrigem) ? response.configuracao.dsnameRetorno.caixaPostalOrigem.id : null,
                cxpIdDestino!: (response.configuracao.dsnameRetorno.caixaPostalDestino) ? response.configuracao.dsnameRetorno.caixaPostalDestino.id : null,
                cxpCodigoOrigem!: (response.configuracao.dsnameRetorno.caixaPostalOrigem) ? response.configuracao.dsnameRetorno.caixaPostalOrigem.codigo : null,
                cxpCodigoDestino!: (response.configuracao.dsnameRetorno.caixaPostalDestino) ? response.configuracao.dsnameRetorno.caixaPostalDestino.codigo : null,
                fileRegexEbcdic!: (response.configuracao.dsnameRetorno.fileRegexEbcdic) ? response.configuracao.dsnameRetorno.fileRegexEbcdic : null,
                maxRecSizeEbcdic!: (response.configuracao.dsnameRetorno.maxRecSizeEbcdic) ? response.configuracao.dsnameRetorno.maxRecSizeEbcdic : null,
                formato!: (response.configuracao.dsnameRetorno.formato) ? response.configuracao.dsnameRetorno.formato.id : null,
                crlf!: (response.configuracao.dsnameRetorno.crlf) ? response.configuracao.dsnameRetorno.crlf.id : null,
                timestampTx!: (response.configuracao.dsnameRetorno.timestampTx) ? response.configuracao.dsnameRetorno.timestampTx.id : null,
                timestampRx!: (response.configuracao.dsnameRetorno.timestampRx) ? response.configuracao.dsnameRetorno.timestampRx.id : null,
                removeAcentos!: (response.configuracao.dsnameRetorno.removeAcentos) ? response.configuracao.dsnameRetorno.removeAcentos.id : null,
                insertTimestamp!: (response.configuracao.dsnameRetorno.insertTimestamp) ? response.configuracao.dsnameRetorno.insertTimestamp.id : null,
                insertSeqType!: (response.configuracao.dsnameRetorno.insertSeqType) ? response.configuracao.dsnameRetorno.insertSeqType.id : null,
                insertSeq!: (response.configuracao.dsnameRetorno.insertSeq) ? response.configuracao.dsnameRetorno.insertSeq.id : null,
            },
            ativacao: {
                id!: response.configuracao.dsnameAtivacao.id,
                custoTrafego!: (response.configuracao.dsnameAtivacao.custoTrafego) ? response.configuracao.dsnameAtivacao.custoTrafego.id : null,
                dsname!: (response.configuracao.dsnameAtivacao.dsname) ? response.configuracao.dsnameAtivacao.dsname : null,
                rename!: (response.configuracao.dsnameAtivacao.rename) ? response.configuracao.dsnameAtivacao.rename : null,
                maxRecSize!: (response.configuracao.dsnameAtivacao.maxRecSize) ? response.configuracao.dsnameAtivacao.maxRecSize : null,
                insertSeqNumberLen!: (response.configuracao.dsnameAtivacao.insertSeqNumberLen) ? response.configuracao.dsnameAtivacao.insertSeqNumberLen : null,
                insertSeqNumberMax!: (response.configuracao.dsnameAtivacao.insertSeqNumberMax) ? response.configuracao.dsnameAtivacao.insertSeqNumberMax : null,
                insertSeqNumberMin!: (response.configuracao.dsnameAtivacao.insertSeqNumberMin) ? response.configuracao.dsnameAtivacao.insertSeqNumberMin : null,
                mapas!: (response.configuracao.dsnameAtivacao.mapas) ? response.configuracao.dsnameAtivacao.mapas : null,
                cxpIdOrigem!: (response.configuracao.dsnameAtivacao.caixaPostalOrigem) ? response.configuracao.dsnameAtivacao.caixaPostalOrigem.id : null,
                cxpIdDestino!: (response.configuracao.dsnameAtivacao.caixaPostalDestino) ? response.configuracao.dsnameAtivacao.caixaPostalDestino.id : null,
                cxpCodigoOrigem!: (response.configuracao.dsnameAtivacao.caixaPostalOrigem) ? response.configuracao.dsnameAtivacao.caixaPostalOrigem.codigo : null,
                cxpCodigoDestino!: (response.configuracao.dsnameAtivacao.caixaPostalDestino) ? response.configuracao.dsnameAtivacao.caixaPostalDestino.codigo : null,
                fileRegexEbcdic!: (response.configuracao.dsnameAtivacao.fileRegexEbcdic) ? response.configuracao.dsnameAtivacao.fileRegexEbcdic : null,
                maxRecSizeEbcdic!: (response.configuracao.dsnameAtivacao.maxRecSizeEbcdic) ? response.configuracao.dsnameAtivacao.maxRecSizeEbcdic : null,
                formato!: (response.configuracao.dsnameAtivacao.formato) ? response.configuracao.dsnameAtivacao.formato.id : null,
                crlf!: (response.configuracao.dsnameAtivacao.crlf) ? response.configuracao.dsnameAtivacao.crlf.id : null,
                timestampTx!: (response.configuracao.dsnameAtivacao.timestampTx) ? response.configuracao.dsnameAtivacao.timestampTx.id : null,
                timestampRx!: (response.configuracao.dsnameAtivacao.timestampRx) ? response.configuracao.dsnameAtivacao.timestampRx.id : null,
                removeAcentos!: (response.configuracao.dsnameAtivacao.removeAcentos) ? response.configuracao.dsnameAtivacao.removeAcentos.id : null,
                insertTimestamp!: (response.configuracao.dsnameAtivacao.insertTimestamp) ? response.configuracao.dsnameAtivacao.insertTimestamp.id : null,
                insertSeqType!: (response.configuracao.dsnameAtivacao.insertSeqType) ? response.configuracao.dsnameAtivacao.insertSeqType.id : null,
                insertSeq!: (response.configuracao.dsnameAtivacao.insertSeq) ? response.configuracao.dsnameAtivacao.insertSeq.id : null,
            }
        }


        return projetoAbertura;
    }
}
