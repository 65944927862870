import { Component, Injector, OnInit } from '@angular/core';
import { SimNao } from '@utils/interfaces/shareds/sim-nao.interface';
import { ChecklistConectividadeBase } from '../checklist-conectividade-base';
import { ChecklistConectividadesWebServiceEdi } from '../shareds/interfaces/checklist-conectividades-web-service-edi.interface';

@Component({
  selector: 'checklist-conectividades-web-service-edi',
  templateUrl: './checklist-conectividades-web-service-edi.component.html',
  styleUrls: ['./checklist-conectividades-web-service-edi.component.scss']
})
export class ChecklistConectividadesWebServiceEdiComponent extends ChecklistConectividadeBase<ChecklistConectividadesWebServiceEdi> implements OnInit {

  public possuiCaixaPostal: string = null;

  public listaSimNao: Array<SimNao> = [
    { codigo: 'SIM', descricao: 'Sim' },
    { codigo: 'NAO', descricao: 'Não' }
  ]

  constructor(
    protected _injector: Injector
  ) {
    super(_injector)
  }

  ngOnInit() {
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      possuiCaixaPostal: [],
      possuiCaixaPostalQual: []
    }
  }

  protected setForm(data: ChecklistConectividadesWebServiceEdi) {
    const possuiCaixaPostal = this.listaSimNao.find(val => val.codigo === data.possuiCaixaPostal)
    this.possuiCaixaPostal = data.possuiCaixaPostal;
    this.form.patchValue({
      possuiCaixaPostal: possuiCaixaPostal,
      possuiCaixaPostalQual: data.possuiCaixaPostalQual
    })
  }

  private getForm() {
    const possuiCaixaPostalVal = this.form.get('possuiCaixaPostal').value
    const data: ChecklistConectividadesWebServiceEdi = {
      possuiCaixaPostal: possuiCaixaPostalVal.codigo,
      possuiCaixaPostalQual: this.form.get('possuiCaixaPostalQual').value
    }

    this.values.emit(data);
  }

  changePossuiCaixaPostal() {
    const possuiCaixaPostal = this.form.get('possuiCaixaPostal').value
    this.possuiCaixaPostal = possuiCaixaPostal.codigo;
    this.limpaSubForm();
    this.getForm();
  }

  limpaSubForm() {
    this.form.get('possuiCaixaPostalQual').reset();
  }

}
