import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnInit,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { ValidacaoDigitoAgenciaContaService } from 'app/main/validacao/validacao-digito-agencia-conta/validacao-digito-agencia-conta.service';
import { CustoTrafego } from '../../shareds/interfaces/custo-trafego.interface';
import { FiltroOsAbertura } from '../../shareds/interfaces/filtro-os-abertura.interface';
import { Layout } from '../../shareds/interfaces/layout.interface';
import { OsAbertura } from '../../shareds/interfaces/os-abertura.interface';
import { OsEmpresa } from '../../shareds/interfaces/os-empresa.interface';
import { Os } from '../../shareds/interfaces/os.interface';
import { ProdutoFinanceiro } from '../../shareds/interfaces/produto-financeiro.interface';
import { CustoTrafegoService } from '../../shareds/services/custo-trafego.service';
import { LayoutService } from '../../shareds/services/layout.service';
import { OsAberturaService } from '../../shareds/services/os-abertura.service';
import { OsEmpresaModalService } from '../../shareds/services/os-empresa-modal.service';
import { ProdutoFinanceiroService } from '../../shareds/services/produto-financeiro.service';

@Component({
    selector: 'os-abertura',
    templateUrl: './os-abertura.component.html',
    styleUrls: ['./os-abertura.component.scss'],
})
export class OsAberturaComponent extends GridBase<OsAbertura, FiltroOsAbertura, OsAberturaService> implements OnInit {
    isEdit: boolean = false;
    empresa: OsEmpresa;
    produtosFinanceiros: Array<ProdutoFinanceiro> = [];
    custosTrafego: Array<CustoTrafego> = [];
    layouts: Array<Layout> = [];

    public form: FormGroup;
    private _os: Os;

    get os(): Os {
        return this._os;
    }
    @Input() set os(os: Os) {
        this._os = os;
        this.filtro.idOs = os.id;
        this.initGrid(this.filtro);
    }

    constructor(
        protected _osService: OsAberturaService,
        protected _injector: Injector,
        protected _changeDetectorRef: ChangeDetectorRef,
        private _produtoFinanceiroService: ProdutoFinanceiroService,
        private _custoTrafegoService: CustoTrafegoService,
        private _layoutService: LayoutService,
        private _modalOsEmpresa: OsEmpresaModalService,
        private _validacaoDigitoAgenciaContaService: ValidacaoDigitoAgenciaContaService
    ) {
        super(_osService, _injector, _changeDetectorRef);
        this.form = this._formBuilder.group(this.crateForm() || null);
    }

    ngOnInit() {
        this.selecionaProdutoFinanceiro();
        this._spinner.show();
        this._produtoFinanceiroService.find({ sortField: 'nome', sortOrder: 'asc' }).subscribe(
            (result) => {
                this.produtosFinanceiros = result.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );

        this._custoTrafegoService.find({}).subscribe(
            (result) => {
                this.custosTrafego = result.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'empresa.nome', label: '' },
            { chave: 'empresa.inscricao', label: '' },
            { chave: 'agencia', label: '' },
            { chave: 'agenciaDV', label: '' },
            { chave: 'conta', label: '' },
            { chave: 'contaDV', label: '' },
            { chave: 'convenio', label: '' },
            { chave: 'produtoFinanceiro.nome', label: '' },
            { chave: 'configuracao.layoutRemessa.descricao', label: '' },
            { chave: 'configuracao.dsnameRemessa.custoTrafego.descricao', label: '' },
            { chave: 'configuracao.dsnameRemessa.dsname', label: '' },
            { chave: 'configuracao.dsnameRemessa.rename', label: '' },
            { chave: 'configuracao.layoutRetorno.descricao', label: '' },
            { chave: 'configuracao.dsnameRetorno.custoTrafego.descricao', label: '' },
            { chave: 'configuracao.dsnameRetorno.dsname', label: '' },
            { chave: 'configuracao.dsnameRetorno.rename', label: '' },
        ];
    }

    protected crateForm(): { [key: string]: any } {
        return {
            id: [],
            agencia: ['', Validators.required],
            dvAgencia: [],
            conta: ['', Validators.required],
            dvConta: [],
            convenio: [],
            produtoFinanceiro: ['', Validators.required],
            layoutRemessa: [],
            custoTrafegoRemessa: [],
            idDsnameRemessa: [],
            dsnameRemessa: [],
            renameRemessa: [],
            idDsnameRetorno: [],
            layoutRetorno: [],
            dsnameRetorno: [],
            renameRetorno: [],
            custoTrafegoRetorno: [],
        };
    }
    private getForm(): OsAbertura {
        const produtoFinanceiro = this.form.get('produtoFinanceiro').value;
        const layoutRemessa = this.form.get('layoutRemessa').value;
        const layoutRetorno = this.form.get('layoutRetorno').value;
        const custoTrafegoRemessa = this.form.get('custoTrafegoRemessa').value;
        const custoTrafegoRetorno = this.form.get('custoTrafegoRetorno').value;

        const idDsnameRemessa = this.form.get('idDsnameRemessa').value;
        const idDsnameRetorno = this.form.get('idDsnameRetorno').value;


        const osAbertura: OsAbertura = {};
        osAbertura.id = this.form.get('id').value;
        osAbertura.agencia = this.form.get('agencia').value;
        osAbertura.agenciaDV = this.form.get('dvAgencia').value;
        osAbertura.conta = this.form.get('conta').value;
        osAbertura.contaDV = this.form.get('dvConta').value;
        osAbertura.convenio = this.form.get('convenio').value;
        osAbertura.produtoFinanceiroId = produtoFinanceiro
            ? produtoFinanceiro.id
            : null;
        osAbertura.empresaId = this.empresa ? this.empresa.id : null;
        osAbertura.configuracao = {
            layoutRemessaId: layoutRemessa ? layoutRemessa.id : null,
            layoutRetornoId: layoutRetorno ? layoutRetorno.id : null,

            dsnames: {
                remessa: {
                    id: idDsnameRemessa ? idDsnameRemessa : undefined,
                    custoTrafego: custoTrafegoRemessa
                        ? custoTrafegoRemessa.id
                        : null,
                    dsname: this.form.get('dsnameRemessa').value,
                    rename: this.form.get('renameRemessa').value,
                },
                retorno: {
                    id: idDsnameRetorno ? idDsnameRetorno : undefined,
                    custoTrafego: custoTrafegoRetorno
                        ? custoTrafegoRetorno.id
                        : null,
                    dsname: this.form.get('dsnameRetorno').value,
                    rename: this.form.get('renameRetorno').value,
                },
            },
        };

        if (
            !osAbertura.configuracao.layoutRemessaId &&
            !osAbertura.configuracao.dsnames.remessa.custoTrafego &&
            !osAbertura.configuracao.dsnames.remessa.dsname &&
            !osAbertura.configuracao.dsnames.remessa.rename
        ) {
            osAbertura.configuracao.dsnames.remessa = {};
        }

        if (
            !osAbertura.configuracao.layoutRetornoId &&
            !osAbertura.configuracao.dsnames.retorno.custoTrafego &&
            !osAbertura.configuracao.dsnames.retorno.dsname &&
            !osAbertura.configuracao.dsnames.retorno.rename
        ) {
            osAbertura.configuracao.dsnames.retorno = {};
        }

        return osAbertura;
    }

    limparForm() {
        this.isEdit = false;
        this.empresa = {};
        this.form.reset();
    }

    validaConfiguracao() {
        const aberturaConfiguracao: any = Object.assign(
            {},
            this.form.value,
            {}
        );

        var fields = [
            'layoutRemessa',
            'custoTrafegoRemessa',
            'dsnameRemessa',
            'renameRemessa',
            'layoutRetorno',
            'custoTrafegoRetorno',
            'dsnameRetorno',
            'renameRetorno',
        ];

        fields.forEach((e) => {
            this.form.get(e).clearValidators();
            this.form.get(e).updateValueAndValidity();
        });

        if (
            aberturaConfiguracao.layoutRemessa ||
            aberturaConfiguracao.custoTrafegoRemessa ||
            aberturaConfiguracao.dsnameRemessa ||
            aberturaConfiguracao.renameRemessa
        ) {
            this.form.get('layoutRemessa').setValidators([Validators.required]);
            this.form.get('layoutRemessa').updateValueAndValidity();

            this.form
                .get('custoTrafegoRemessa')
                .setValidators([Validators.required]);
            this.form.get('custoTrafegoRemessa').updateValueAndValidity();


            this.form.get('dsnameRemessa').setValidators([Validators.required]);
            this.form.get('dsnameRemessa').updateValueAndValidity();

            this.form.get('renameRemessa').setValidators([Validators.required]);
            this.form.get('renameRemessa').updateValueAndValidity();
        }

        if (
            aberturaConfiguracao.layoutRetorno ||
            aberturaConfiguracao.custoTrafegoRetorno ||
            aberturaConfiguracao.dsnameRetorno ||
            aberturaConfiguracao.renameRetorno
        ) {
            this.form.get('layoutRetorno').setValidators([Validators.required]);
            this.form.get('layoutRetorno').updateValueAndValidity();

            this.form
                .get('custoTrafegoRetorno')
                .setValidators([Validators.required]);
            this.form.get('custoTrafegoRetorno').updateValueAndValidity();

            this.form.get('dsnameRetorno').setValidators([Validators.required]);
            this.form.get('dsnameRetorno').updateValueAndValidity();

            this.form.get('renameRetorno').setValidators([Validators.required]);
            this.form.get('renameRetorno').updateValueAndValidity();
        }
    }

    adicionar() {
        const abertura: OsAbertura = Object.assign({}, this.getForm(), {
            idOs: this.filtro.idOs,
        });

        if (!abertura.empresaId) {
            this._notification.error('Selecione uma empresa!');
            return;
        }

        this._spinner.show()
        this._validacaoDigitoAgenciaContaService.validate({
            banco: this._os.banco && this._os.banco.codigo ? parseInt(this._os.banco.codigo) : undefined,
            agencia: abertura.agencia ? parseInt(abertura.agencia) : undefined,
            conta: abertura.conta ? parseInt(abertura.conta) : undefined,
            agenciaDv: abertura.agenciaDV,
            contaDv: abertura.contaDV
        }).subscribe(result => {
            if (result) {
                this._osService.save(abertura).subscribe(
                    () => {
                        this._spinner.hide();
                        this._notification.sucess('OS Abertura criada com sucesso');
                        this.atualizarGrid();
                        this.limparForm();
                    },
                    (error) => this.error(error)
                );
            }
        },
            error => this.error(error))



    }

    atualizar() {
        this._spinner.show();
        this._osService.update(this.getForm()).subscribe(
            () => {
                this.sucess('OS Abertura atualizada com sucesso');
                this.atualizarGrid();
                this.limparForm();
            },
            (error) => this.error(error)
        );
    }

    remover(osAbertura: OsAbertura) {
        this._confirmation
            .confirm({
                titulo: 'Deseja excluir a OS?',
                mensagem: 'Ao remover a OS o mesmo não ficará mais disponível',
            })
            .subscribe((result) => {
                if (result) {
                    this._osService.delete(osAbertura).subscribe(
                        () => {
                            this.atualizarGrid();
                            this.sucess(
                                'OS removida com sucesso!'
                            );
                            this.limparForm();
                        },
                        (error) => this.error(error)
                    );
                }
            });
    }

    editar(osAbertura: OsAbertura) {
        this.isEdit = true;

        this.form
            .get('produtoFinanceiro')
            .patchValue(osAbertura.produtoFinanceiro);
        this.selecionaProdutoFinanceiro();
        setTimeout(() => {
            this.empresa = osAbertura.empresa;
            const dsnameRemessa =
                osAbertura.configuracao && osAbertura.configuracao.dsnameRemessa
                    ? osAbertura.configuracao.dsnameRemessa
                    : {};
            const dsnameRetorno =
                osAbertura.configuracao && osAbertura.configuracao.dsnameRetorno
                    ? osAbertura.configuracao.dsnameRetorno
                    : {};
            const layoutRemessa =
                osAbertura.configuracao && osAbertura.configuracao.layoutRemessa
                    ? osAbertura.configuracao.layoutRemessa
                    : {};
            const layoutRetorno =
                osAbertura.configuracao && osAbertura.configuracao.layoutRetorno
                    ? osAbertura.configuracao.layoutRetorno
                    : {};

            console.log(osAbertura.configuracao)

            this.form.patchValue({
                id: osAbertura.id,
                agencia: osAbertura.agencia,
                dvAgencia: osAbertura.agenciaDV,
                conta: osAbertura.conta,
                dvConta: osAbertura.contaDV,
                convenio: osAbertura.convenio,
                //produtoFinanceiro: osAbertura.produtoFinanceiro,
                layoutRemessa: layoutRemessa,
                custoTrafegoRemessa: dsnameRemessa.custoTrafego,
                idDsnameRemessa: dsnameRemessa.id,
                dsnameRemessa: dsnameRemessa.dsname,
                renameRemessa: dsnameRemessa.rename,
                layoutRetorno: layoutRetorno,
                custoTrafegoRetorno: dsnameRetorno.custoTrafego,
                idDsnameRetorno: dsnameRetorno.id,
                dsnameRetorno: dsnameRetorno.dsname,
                renameRetorno: dsnameRetorno.rename,
            });
        }, 500);
    }

    selectedOption(o1: any, o2: any) {
        if (o2) {
            return o1.id == o2.id;
        }
    }

    pesquisaEmpresaOs() {
        this._modalOsEmpresa.empresaGrid({}).subscribe((empresa) => {
            if (empresa) {
                this.empresa = empresa;
                this.os.idEmpresa = empresa.id;
            }
        });
    }

    selecionaProdutoFinanceiro() {
        const produtoFinanceiro = this.form.get('produtoFinanceiro').value;
        this.layouts = [];
        if (!produtoFinanceiro) {
            this.layouts = [
                {
                    id: null,
                    descricao: 'Selecione um produto financeiro',
                },
            ];
        } else {
            this._layoutService
                .find({
                    produtoFinanceiro: produtoFinanceiro.id,
                })
                .subscribe(
                    (result) => {
                        this.form.get('layoutRemessa').setValue(null);
                        this.form.get('layoutRetorno').setValue(null);
                        this.validaConfiguracao();
                        this.layouts = result.data;
                    },
                    (error) => this.error(error)
                );
        }
    }
}
