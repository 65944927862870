export enum Role {
    // Home
    HOME_INDEX = 'home-index',
    CLIENTE_HOME_INDEX = 'cliente-home-index',

    // Usuario
    USUARIO_EXIBIR = 'usuario-exibir',
    USUARIO_LISTAR = 'usuario-listar',
    USUARIO_ALTERAR_PERFIL = 'usuario-alterar-perfil',
    USUARIO_ALTERAR_STATUS = 'usuario-alterar-status',
    USUARIO_ALTERAR_SENHA = 'usuario-alterar-senha',

    // Permissao
    PERMISSAO_LISTAR = 'permissao-listar',
    PERMISSAO_GRUPO_LISTAR = 'permissao-grupo-listar',

    // Usuario Tipo
    USUARIOTIPO_LISTAR = 'usuariotipo-listar',

    // Perfil
    PERFIL_CADASTRAR = 'perfil-cadastrar',
    PERFIL_ATUALIZAR = 'perfil-atualizar',
    PERFIL_LISTAR = 'perfil-listar',
    PERFIL_EXIBIR = 'perfil-exibir',
    PERFIL_REMOVER = 'perfil-remover',

    // Cotacao
    COTACAO_LISTAR = 'cotacao-listar',
    COTACAO_EXIBIR = 'cotacao-exibir',
    COTACAO_CADASTRAR = 'cotacao-cadastrar',
    COTACAO_STATUS_LISTAR = 'cotacao-status-listar',
    COTACAO_LISTAR_USUARIO = 'cotacao-listar-usuario',
    COTACAO_GERAR_RELATORIO = 'relatorio-cotacao',
    COTACAO_EDITAR = 'cotacao-alterar',
    COTACAO_HISTORIO = 'checklist-historico-listar',
    COTACAO_CADASTRAR_SITUACAO_EMPRESA = 'cotacao-situacao-empresa-cadastrar',
    COTACAO_EDITAR_SITUACAO_EMPRESA = 'cotacao-situacao-empresa-atualizar',

    COTACAO_INICIAR_FATURAMENTO = 'cotacao-listar', //ATUALIZAR COM O BACKEND

    COTACAO_LISTAR_MODULOS_POR_COTACAO = 'cotacao-listar-modulos-por-cotacao',
    COTACAO_CANCELAR = 'cotacao-listar',

    // Importacao
    IMPORTACAO_UPLOAD = 'importacao-upload',
    IMPORTACAO_LISTAR = 'importacao-listar',
    IMPORTACAO_EXIBIR = 'importacao-exibir',
    IMPORTACAO_ERROS = 'importacao-erros',

    // Checklist
    CHECKLIST_LISTAR = 'checklist-listar',
    CHECKLIST_CONTATO_ATUALIZAR = 'checklist-contato-atualizar',
    CHECKLIST_CONTATO_LISTAR = 'checklist-contato-listar',
    CHECKLIST_CONECTIVIDADE_CADASTRAR = 'checklist-conectividade-cadastrar',
    CHECKLIST_CONECTIVIDADE_LISTAR = 'checklist-conectividade-listar',
    CHECKLIST_RELACIONAMENTO_LISTAR = 'checklist-relacionamento-listar',
    CHECKLIST_RELACIONAMENTO_ATUALIZAR = 'checklist-relacionamento-atualizar',
    CHECKLIST_CONFIGURACAO_LISTAR = 'checklist-configuracao-listar',
    CHECKLIST_CONFIGURACAO_ALTERAR = 'checklist-configuracao-alterar',
    CHECKLIST_CONFIGURACAO_DSNAME_LIMITE_LISTAR = 'checklist-configuracao-dsname-limite-listar',
    CHECKLIST_CONFIGURACAO_ANEXO_CADASTRAR = 'checklist-configuracao-anexo-cadastrar',
    CHECKLIST_CONFIGURACAO_ANEXO_EXCLUIR = 'checklist-configuracao-anexo-excluir',
    CHECKLIST_CONFIGURACAO_ANEXO_DOWNLOAD = 'checklist-configuracao-anexo-download',
    CHECKLIST_EXIBIR = 'checklist-exibir',
    CHECKLIST_HOME_LISTAR = 'checklist-home-listar',
    CHECKLIST_STATUS = 'checklist-status',
    CHECKLIST_VINCULAR_RELACIONAMENTOS_A_ITEM_DA_COTACAO = 'checklist-vincular-relacionamentos-a-item-da-cotacao',
    CHECKLIST_LISTAR_ITENS_DA_COTACAO_COM_RELACIONAMENTOS_VINCULADOS = 'checklist-listar-itens-da-cotacao-com-relacionamentos-vinculados',
    CHECKLIST_EXIBIR_ITEM_DA_COTACAO_COM_RELACIONAMENTOS_VINCULADOS = 'checklist-exibir-item-da-cotacao-com-relacionamentos-vinculados',
    CHECKLIST_GERAR_RELATORIO = 'relatorio-checklist',
    CHECKLIST_ALTERAR = 'checklist-alterar',
    CHECKLIST_HISTORIO = 'checklist-historico-listar',

    //Dsname
    DSNAME_CAMPO_LISTAR = 'dsname-campo-listar',
    DSNAME_CAMPO_TIPO_LISTAR = 'dsname-campo-tipo-listar',
    DSNAME_DIRECAO_LISTAR = 'dsname-direcao-listar',

    // Empresa
    EMPRESA_LISTAR = 'empresa-listar',
    EMPRESA_EXIBIR = 'empresa-exibir',
    EMPRESA_EXCLUIR = 'empresa-excluir',
    EMPRESA_CADASTRAR = 'empresa-cadastrar',
    EMPRESA_ALTERAR = 'empresa-alterar',

    //Banco
    BANCO_LISTAR = 'banco-listar',
    BANCO_CADASTRAR = 'banco-listar',
    BANCO_BUSCAR = 'banco-listar',
    BANCO_ALTERAR = 'banco-listar',
    BANCO_HISTORICO = 'banco-listar',

    //Time
    TIME_CADASTRAR = 'time-cadastrar',
    TIME_USUARIO_ATUALIZAR = 'time-usuario-atualizar',
    TIME_ALTERAR = 'time-alterar',
    TIME_BUSCAR = 'time-buscar',
    TIME_LISTAR = 'time-listar',

    //Projeto
    PROJETO_CRIAR = 'projeto-criar',
    PROJETO_EXIBIR = 'projeto-exibir',
    PROJETO_BUSCA = 'projeto-busca',
    PROJETO_ALTERAR = 'projeto-alterar',
    PROJETO_ALTERAR_RESPONSAVEL = 'projeto-alterar-responsavel',
    PROJETO_STATUS_LISTAR = 'projeto-status-listar',
    PROJETO_LISTAR = 'projeto-listar',
    PROJETO_ABERTURA_BUSCA = 'projeto-abertura-busca',
    PROJETO_GERAR_RELATORIO = 'relatorio-projeto',
    PROJETO_HISTORIO = 'projeto-historico-listar',

    PROJETO_ABERTURA_EXIBIR = 'projeto-exibir',
    PROJETO_ABERTURA_ALTERAR = 'projeto-exibir',
    PROJETO_ABERTURA_HISTORIO = 'projeto-listar',


    //Produto
    PRODUTOS_LISTAR = 'produtos-listar',

    // Modulo
    MODULOS_LISTAR = 'modulos-listar',

    // Os
    OS_MANUAL_CRIAR = 'os-manual-criar',
    OS_STATUS_LISTAR = 'os-status-listar',
    OS_CATEGORIAS_LISTAR = 'os-categorias-listar',
    OS_HISTORIO = 'os-historico-listar',

    //Contrato
    CONTRATO_LISTAR = 'contrato-listar',
    CONTRATO_EXIBIR = 'contrato-exibir',
    CONTRATO_ALTERAR = 'contrato-atualizar',
    CONTRATO_HISTORICO = 'contrato-historico-listar',
    CONTRATO_DOCUMENTOS = 'contrato-documento-listar',
    CONTRATO_ATUALIZAR_REAJUSTE = 'contrato-item-atualizar-reajuste',
    CONTRATO_ATUALIZAR_VIGENCIA = 'contrato-item-atualizar-reajuste',

    //GMUD
    GMUD_LISTAR = 'gmud-listar',
    GMUD_EXIBIR = 'gmud-exibir',
    GMUD_ALTERAR = 'gmud-atualizar',
    GMUD_HISTORICO = 'gmud-historico-listar',
    GMUD_CADASTRAR = 'imp-cadastrar',
    GMUD_EDITAR = 'imp-atualizar',
    GMUD_CONFIGURAR_APROVACAO = 'gmud-aprovacao-cadastrar',
    GMUD_APROVACAO = 'gmud-aprovar',
    GMUD_DOCUMENTACAO = 'gmud-manual-documento-upload',

    //Faturamento
    FATURAMENTO_LISTAR = 'projeto-listar',
    FATURAMENTO_CADASTRO = 'projeto-listar',
    FATURAMENTO_VISUALIZAR = 'projeto-listar',

    //RELATORIO
    RELATORIO_LISTAR = 'cotacao-listar',

    //Notificacao
    NOTIFICACAO_BUSCA = 'cotacao-listar',

    //Configuração
    CONFIGURACAO_BUSCA = 'cotacao-listar',

    //Relacionamento Abertura
    RELACIONAMENTO_ABERTURA_LISTAR = 'cotacao-listar',
    RELACIONAMENTO_ABERTURA_EXIBIR = 'cotacao-listar',
    RELACIONAMENTO_ABERTURA_ATIVACAO_PORTAL = 'cotacao-listar',
    RELACIONAMENTO_ABERTURA_EDITAR = 'cotacao-listar',
    RELACIONAMENTO_ABERTURA_GERAR_RELATORIO = 'cotacao-listar',
    RELACIONAMENTO_ABERTURA_HISTORIO = 'cotacao-listar',

}
