import { Component, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { AuthService } from 'app/main/public/auth/shared/services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { confirmaSenhaValidator } from '../../shared/validators/confirma-senha.validator';
import { senhaForteValidator } from '../../shared/validators/senha-forte.validator';

@Component({
    selector: 'profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent extends FormBase implements OnInit {
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _injector: Injector,
        private _authService: AuthService
    ) {
        super(_injector);
        this._unsubscribeAll = new Subject();

        this.form
            .get('senha')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.form.get('senhaConfirm').updateValueAndValidity();
            });
    }

    ngOnInit(): void { }

    protected crateForm(): { [key: string]: any } {
        return {
            senha: [
                '',
                Validators.compose([Validators.required, senhaForteValidator]),
            ],
            senhaConfirm: [
                '',
                Validators.compose([
                    Validators.required,
                    confirmaSenhaValidator,
                ]),
            ],
        };
    }

    salvar(): void {
        const senha = this.form.get('senha').value;
        const senhaConfirm = this.form.get('senhaConfirm').value;
        if (senha != senhaConfirm) {
            this._notification.error('Senhas informadas não conferem');
            return;
        }
        this._spinner.show();
        this._authService.alterarSenhaLogado(senha).subscribe(
            () => {
                this.form.reset();
                this.sucess('Senha alterada com sucesso');
            },
            (error) => {
                this.error(error);
            }
        );
    }
}
