import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotificacaoGuard } from './notificacao.guard';
import { NotificacaoHistoricoPageComponent } from './pages/notificacao-historico-page/notificacao-historico-page.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [NotificacaoGuard],
        canLoad: [NotificacaoGuard],
        resolve: {

        },
        component: NotificacaoHistoricoPageComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class NotificacaoRoutingModule { }
