import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Notificacao } from '../interfaces/notificacao.interface';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { NotificacaoWebsocketService } from './notificacao-websocket.service';
import { FiltroNotificacao } from '../interfaces/filtro-notificacao.interface';
import { map } from 'rxjs/operators';
import { NotificacaoData } from '../interfaces/notificacao-data.interface';
import { DataUtil } from '@utils/utils/class/data.util';

@Injectable({
    providedIn: 'root',
})
export class NotificacaoService extends ServiceGridBase<Notificacao, FiltroNotificacao> {
    private path = `${environment.server}/usuario-listar-notificacao`;

    constructor(
        private _http: HttpClient,
        private _notificacaoWS: NotificacaoWebsocketService
    ) {
        super();
    }

    find(filtro?: FiltroNotificacao): Observable<Response<Array<Notificacao>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<NotificacaoData>>>(url).pipe(
            map((result: Response<Array<NotificacaoData>>) => {
                const notificacoes: Response<Array<Notificacao>> = Object.assign({}, result, { data: null })
                notificacoes.data = result.data.map((val: NotificacaoData) => {
                    let notificacao: Notificacao = {};
                    if (val.data) {
                        const obj = JSON.parse(val.data)
                        notificacao = {
                            id: val.id,
                            data: obj.data,
                            titulo: obj.titulo,
                            mensagem: obj.mensagem,
                            link: obj.link,
                            lido: !!val.readAt
                        }
                    }
                    return notificacao;
                })
                return notificacoes
            })
        );
    }

    destaques(): Observable<Array<Notificacao>> {
        return this.find({ nread: true }).pipe(map(result => result.data))
    }

    lido(idNotificacao: number): Observable<Response<void>> {
        const url = `${environment.server}/notificacaoLeitura`;
        return this._http.put<Response<void>>(url, { id: idNotificacao.toString(), readAt: DataUtil.toDateTimeBackend(new Date()) });
    }

    naoLido(idNotificacao: number): Observable<Response<void>> {
        const url = `${environment.server}/notificacaoLeitura`;
        return this._http.put<Response<void>>(url, { id: idNotificacao.toString() });
    }

    lidoTodos(): Observable<Response<void>> {
        const url = `${environment.server}/notificacaoLeituraTodas`;
        return this._http.put<Response<void>>(url, { readAt: DataUtil.toDateTimeBackend(new Date()) });
    }

    naoLidoTodos(): Observable<Response<void>> {
        const url = `${environment.server}/notificacaoLeituraTodas`;
        return this._http.put<Response<void>>(url, {});
    }

    qtdeNaoLido(): Observable<number> {
        const url = `${environment.server}/usuario-contar-notificacao`;

        return this._http.get<Response<any>>(url).pipe(
            map((result: any) => {
                return result.data && result.data[1] ? result.data[1] : 0;
            })
        );
    }

}
