import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { ChecklistConectividade } from '../interfaces/checklist-conectividade.interface';
import { FiltroChecklistConectividade } from '../interfaces/filtro-checklist-conectividade.interface';
import { Response } from '@utils/interfaces/http/response.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistConectividadeService extends ServiceBase<ChecklistConectividade> {
    private path = `${environment.server}/checklist/conectividade`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroChecklistConectividade
    ): Observable<Response<Array<ChecklistConectividade>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/${filtro.idCotacao}`;
        return this._http.get<Response<Array<ChecklistConectividade>>>(url);
        //return this.mockList();
    }


    save(idCotacao: number, conectividade: any): Observable<Response<any>> {
        const url = `${this.path}/${idCotacao}`;
        return this._http.put<Response<any>>(url, conectividade);
    }


    mockList(): Observable<Response<Array<ChecklistConectividade>>> {
        return of({
            data: [
                {
                    id: 1,
                    chave: 'FAST_WEB',
                    nome: 'Fast Web',
                    descricao: 'Client próprio da Finnet baseado no protocolo OFTP',
                    selected: true,
                    isOutros: false,
                    form: {
                        possuiCaixaPostal: 'NAO',
                        possuiCaixaPostalQual: 'Não sei',
                        produtoBancario: 'Qualquer um',
                        razaoSocial: 'Empresa 1',
                        cnpj: '32123214567898',
                        diretorioRemessa: 'Direita',
                        nomenclaturaRemessa: 'batata frita',
                        diretorioRetorno: 'Esquerda',
                        nomenclaturaRetorno: 'Coca-cola',
                    }
                },
                {
                    id: 2,
                    chave: 'WEB_SERVICE_EDI',
                    nome: 'Web Service EDI',
                    descricao: 'Este tipo de conectividade exigirá que a empresa desenvolva um serviço para comunicar-se a um Web Service SOAP da Finnet permitindo trocar arquivos CNAB criptografados em Base64 através de objetos XML',
                    selected: false,
                    isOutros: false,
                    form: {
                        possuiCaixaPostal: 'SIM',
                        possuiCaixaPostalQual: 'CAIXA290980-11',
                    }
                },
                {
                    id: 3,
                    chave: 'API_OPEN_BANKING',
                    nome: 'API Open Banking',
                    descricao: 'URL definida pelo cliente que receberá os retornos bancários e correlation para o cliente realizar a request e obter o resultado.',
                    selected: false,
                    isOutros: false,
                    form: {
                        possuiConexaoApi: 'SIM',
                        urlCallback: '/callback',
                    }
                },
                {
                    id: 4,
                    chave: 'SFTP',
                    nome: 'SFTP',
                    descricao: 'Para este protocolo não é exigida a utilização de um client específico, a empresa tem a liberdade de escolher se utilizará um client pago ou gratuito',
                    selected: false,
                    isOutros: false,
                    form: {
                        servidorFinnetSftp: 'SIM',
                        caixaPostal: 'CAIXA290980',
                        caixaPostalSenha: 'Senha123',
                        sftpHost: 'http://localhost',
                        sftpPorta: '3000',
                        sftpUsuario: 'usuario1',
                        sftpSenha: 'senha123',
                        sftpDiretorioRetorno: '/retorno',
                        sftpDiretorioRemessa: '/remessa',
                        sftpNomenclaturaRetorno: 'NOMECLATURA',
                    }
                },
                {
                    id: 5,
                    chave: 'FINNET_FAST_CLIENT',
                    nome: 'Finnet Fast Client (RVS)',
                    descricao: 'Client próprio da Finnet baseado no protocolo OFTP',
                    selected: false,
                    isOutros: false,
                    form: {
                        caixaPostal: 'CAIXA290980',
                        caixaPostalSenha: 'Senha123',
                    }
                },
                {
                    id: 6,
                    chave: 'WEB_DAV',
                    nome: 'Web Dav',
                    descricao: 'Este tipo de conectividade é baseado em Web Services e apenas o Banco Citybank o utiliza',
                    selected: false,
                    isOutros: false,
                    form: {
                        caixaPostal: 'CAIXA290980',
                        caixaPostalSenha: 'Senha123',
                    }
                },
                {
                    id: 7,
                    chave: 'CONNECT_DIRECT',
                    nome: 'Connect Direct',
                    descricao: 'Client da IBM baseado no protocolo FTP otimizado para a troca de grandes volumes de arquivos entre empresas',
                    selected: false,
                    isOutros: false,
                    form: {
                        enderecoIp: '192.168.0.255',
                        nodeNo: '123',
                        usuario: 'Maria',
                        porta: '80',
                        possuiSecurityPlus: 'SIM',
                    }
                },
                {
                    id: 8,
                    chave: 'B2BI',
                    nome: 'B2bi',
                    descricao: 'Este tipo de conectividade é uma ferramenta da Axway que comporta múltiplos protocolos',
                    selected: false,
                    isOutros: false,
                    form: {
                        protocolo: 'TCP',
                        idProtocolo: '01',
                        enderecoIp: '192.168.0.0',
                        porta: '8080',
                        senha: '123',
                    }
                },
            ]
        })
    }
}
