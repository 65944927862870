import { Component, Injector, OnInit } from '@angular/core';
import { SimNao } from '@utils/interfaces/shareds/sim-nao.interface';
import { ChecklistConectividadeBase } from '../checklist-conectividade-base';
import { ChecklistConectividadesConnectDirect } from '../shareds/interfaces/checklist-conectividades-connect-direct.interface';
@Component({
  selector: 'checklist-conectividades-connect-direct',
  templateUrl: './checklist-conectividades-connect-direct.component.html',
  styleUrls: ['./checklist-conectividades-connect-direct.component.scss']
})
export class ChecklistConectividadesConnectDirectComponent extends ChecklistConectividadeBase<ChecklistConectividadesConnectDirect> implements OnInit {

  public listaSimNao: Array<SimNao> = [
    { codigo: 'SIM', descricao: 'Sim' },
    { codigo: 'NAO', descricao: 'Não' }
  ]

  constructor(
    protected _injector: Injector
  ) {
    super(_injector)
  }

  ngOnInit() {
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      enderecoIp: [],
      nodeNo: [],
      usuario: [],
      porta: [],
      possuiSecurityPlus: [],
    }
  }

  protected setForm(data: ChecklistConectividadesConnectDirect) {
    const possuiSecurityPlusVal = this.listaSimNao.find(val => val.codigo === data.possuiSecurityPlus)
    this.form.patchValue({
      enderecoIp: data.enderecoIp,
      nodeNo: data.nodeNo,
      usuario: data.usuario,
      porta: data.porta,
      possuiSecurityPlus: possuiSecurityPlusVal,
    })
  }

  getForm() {
    const possuiSecurityPlusVal = this.form.get('possuiSecurityPlus').value
    const data: ChecklistConectividadesConnectDirect = {
      enderecoIp: this.form.get('enderecoIp').value,
      nodeNo: this.form.get('nodeNo').value,
      usuario: this.form.get('usuario').value,
      porta: this.form.get('porta').value,
      possuiSecurityPlus: possuiSecurityPlusVal.codigo,
    }

    this.values.emit(data);
  }

}
