import { Component, Injector, Input, OnInit } from '@angular/core';
import { inject } from '@angular/core/testing';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { Banco } from 'app/main/banco/shareds/interfaces/banco.interface';
import { Empresa } from 'app/main/empresa/shareds/interfaces/empresa.interface';
import { ChecklistDadosEmpresa } from '../../shared/interfaces/checklist-dados-empresa.interface';
import { ChecklistRelacionamento } from '../../shared/interfaces/checklist-relacionamento.interface';
import { ChecklistRelacionamentoRequest } from '../../shared/interfaces/checklist-relacionamentoRequest.interface';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { ChecklistDadosEmpresaRelacionamentoService } from '../../shared/services/checklist-dados-empresa-relacionamento.service';
import { ChecklistRelacionamentoService } from '../../shared/services/checklist-relacionamento.service';

@Component({
    selector: 'checklist-relacionamento-empresa-cadastro',
    templateUrl: './checklist-relacionamento-empresa-cadastro.component.html',
    styleUrls: ['./checklist-relacionamento-empresa-cadastro.component.scss'],
})
export class ChecklistRelacionamentoEmpresaCadastroComponent extends ComponentBase implements OnInit {
    @Input() relacionamento: ChecklistRelacionamento;
    @Input() checklist: Checklist;
    @Input() bancos: Array<Banco>;
    @Input() empresas: Array<Empresa>;

    campoPesquisa: string;
    relacionamentosFull: Array<ChecklistDadosEmpresa> = [];
    relacionamentosFullTotal: Array<ChecklistDadosEmpresa> = [];
    relacionamentosSelecionados: Array<ChecklistDadosEmpresa> = [];
    relacionamentosFullCheckbox: Array<ChecklistDadosEmpresa> = [];
    relacionamentosSelecionadosCheckbox: Array<ChecklistDadosEmpresa> = [];
    todosRelacionamentosFull: boolean = false;
    todosRelacionamentosSelecionado: boolean = false;
    private campoPesquisaOld: string;

    constructor(
        private _checklistDadosEmpresaRelacionamentoService: ChecklistDadosEmpresaRelacionamentoService,
        private _checklistRelacionamentoService: ChecklistRelacionamentoService,
        private _injector: Injector
    ) {
        super(_injector);
    }

    ngOnInit() {
        this.inicializaListas();
    }

    pesquisarPorTexto(): void {
        this.relacionamentosFull = this.relacionamentosFullTotal.filter(
            (rel) => {
                const regex = new RegExp(
                    this.campoPesquisa
                        .toLocaleLowerCase()
                        .replace(/\./g, '')
                        .replace(/\-/g, '')
                        .replace(/\//g, '')
                        .replace(/ /g, '')
                );
                return (
                    rel.empresa.inscricao.toLowerCase().match(regex) ||
                    rel.empresa.nome.toLowerCase().match(regex)
                );
            }
        );
    }

    limparCampoPesquisa(): void {
        this.campoPesquisa = '';
        this.relacionamentosFull = this.relacionamentosFullTotal;
    }

    // Relacionamentos Full
    addFullCheckbox(): void {
        const relacionamentosFullCheckbox = Array.from(
            this.relacionamentosFullCheckbox
        );

        relacionamentosFullCheckbox.forEach(
            (r: ChecklistDadosEmpresa, i: number) => {
                const index = this.relacionamentosFull.findIndex(
                    (rel) => rel.id === r.id
                );
                r.selecionado = false;
                this.adicionarSelecionado(r, index);
            }
        );

        this.todosRelacionamentosFull = false;
        this.relacionamentosFullCheckbox = [];
    }

    removeFullCheckbox(relacionamento: ChecklistDadosEmpresa): void {
        const index = this.relacionamentosFullCheckbox.findIndex(
            (r) => r.id === relacionamento.id
        );
        this.relacionamentosFullCheckbox.splice(index, 1);
    }

    adicionarFull(relacionamento: ChecklistDadosEmpresa, index: number): void {
        relacionamento.selecionado = false;
        this.relacionamentosFull.push(relacionamento);
        this.relacionamentosFullTotal = this.relacionamentosFull;
        this.removeSelecionadoCheckboxArray(relacionamento);
        this.removeRelacionamentoSelecionado(index);
    }

    removeRelacionamentoFull(index: number): void {
        this.relacionamentosFull.splice(index, 1);
        this.relacionamentosFullTotal = this.relacionamentosFull;
    }

    selecionaFull(
        relacionamento: ChecklistDadosEmpresa,
        checked: boolean
    ): void {
        if (checked) {
            this.relacionamentosFullCheckbox.push(relacionamento);
        } else {
            this.removeFullCheckbox(relacionamento);
        }
    }

    setTodosRelacionamentosFull(selecionado: boolean) {
        this.todosRelacionamentosFull = selecionado;
        if (this.relacionamentosFull === null) {
            return;
        }
        if (selecionado) {
            this.relacionamentosFullCheckbox = Array.from(
                this.relacionamentosFull
            );
        } else {
            this.relacionamentosFullCheckbox = [];
        }
        this.relacionamentosFull.forEach((r) => (r.selecionado = selecionado));
    }

    // Relacionamentos Selecionados
    removeSelecionadosCheckbox(): void {
        const relacionamentosSelecionadosCheckbox = Array.from(
            this.relacionamentosSelecionadosCheckbox
        );

        relacionamentosSelecionadosCheckbox.forEach(
            (r: ChecklistDadosEmpresa, i: number) => {
                const index = this.relacionamentosSelecionados.findIndex(
                    (rel) => rel.id === r.id
                );
                r.selecionado = false;
                this.adicionarFull(r, index);
            }
        );

        this.todosRelacionamentosSelecionado = false;
        this.relacionamentosSelecionadosCheckbox = [];
    }

    adicionarSelecionado(
        relacionamento: ChecklistDadosEmpresa,
        index: number
    ): void {
        if (this.validacaoQuantidades(relacionamento)) {
            relacionamento.selecionado = false;
            this.relacionamentosSelecionados.push(relacionamento);
            this.removeFullCheckbox(relacionamento);
            this.removeRelacionamentoFull(index);
        }
    }

    removeSelecionadoCheckboxArray(
        relacionamento: ChecklistDadosEmpresa
    ): void {
        const index = this.relacionamentosFullCheckbox.findIndex(
            (r) => r.id === relacionamento.id
        );
        this.relacionamentosFullCheckbox.splice(index, 1);
    }

    removeRelacionamentoSelecionado(index: number): void {
        this.relacionamentosSelecionados.splice(index, 1);
    }

    selecionaSelecionado(
        relacionamento: ChecklistDadosEmpresa,
        checked: boolean
    ): void {
        if (checked) {
            this.relacionamentosSelecionadosCheckbox.push(relacionamento);
        } else {
            this.removeSelecionadoCheckboxArray(relacionamento);
        }
    }

    setTodosRelacionamentosSelecionado(selecionado: boolean) {
        this.todosRelacionamentosSelecionado = selecionado;
        if (this.relacionamentosSelecionados === null) {
            return;
        }
        if (selecionado) {
            this.relacionamentosSelecionadosCheckbox = Array.from(
                this.relacionamentosSelecionados
            );
        } else {
            this.relacionamentosSelecionadosCheckbox = [];
        }
        this.relacionamentosSelecionados.forEach(
            (r) => (r.selecionado = selecionado)
        );
    }

    salvar() {
        const idsRelacionamentos: Array<number> = [];
        this.relacionamentosSelecionados.forEach((rel) =>
            idsRelacionamentos.push(rel.idChecklistRelacionamento)
        );
        const relacionamento: ChecklistRelacionamentoRequest = {
            idChecklist: this.checklist.id,
            cotacaoItemCotacaoId: this.relacionamento.cotacaoItemCotacaoId,
            checklistRelacionamentosIds: idsRelacionamentos,
        };
        this._spinner.show();
        this._checklistRelacionamentoService.save(relacionamento).subscribe(
            () => {
                this.sucess('Relacionamentos salvos com sucesso!');
            },
            (error) => this.error(error)
        );
    }

    private inicializaListas() {
        this._spinner.show();
        this._checklistDadosEmpresaRelacionamentoService
            .findRelacionamento(this.checklist.id)
            .subscribe(
                (dadosEmpresa) => {
                    this._spinner.hide();
                    this.relacionamentosFull = [];
                    dadosEmpresa.data.forEach((d) => {
                        d.banco = this.bancos.find((b) => b.id === d.idBanco);
                        d.empresa = this.empresas.find(
                            (e) => e.id === d.idEmpresa
                        );
                        this.relacionamentosFull.push(d);
                    });
                    this.relacionamentosFullTotal = this.relacionamentosFull;

                    this.relacionamento.checklistRelacionamentosIds.forEach(
                        (idRelacionamento) => {
                            this.relacionamentosFull.forEach((rel, idxFull) => {
                                if (rel.idChecklistRelacionamento === idRelacionamento) {
                                    this.adicionarSelecionado(rel, idxFull);
                                }
                            });
                        }
                    );
                },
                (error) => this.error(error)
            );
    }

    private validacaoQuantidades(rel: ChecklistDadosEmpresa): boolean {
        let isValid = true;
        const bancos = [
            ...new Set(
                [].concat(
                    this.relacionamentosSelecionados.map((r) => r.idBanco),
                    rel.idBanco
                )
            ),
        ];

        const empresas = [
            ...new Set(
                [].concat(
                    this.relacionamentosSelecionados.map((r) => r.idEmpresa),
                    rel.idEmpresa
                )
            ),
        ];

        const dadosBancarios = [
            ...new Set(
                [].concat(
                    this.relacionamentosSelecionados.map((r) => r.idBanco + r.agencia + r.conta),
                    rel.idBanco + rel.agencia + rel.conta
                )
            ),
        ];

        if (bancos.length > this.relacionamento.qtdBancosContratados) {
            isValid = false;
            this._notification.warning(
                `A quantidade de bancos está acima da contratada. Quantidade de contas contratadas: ${this.relacionamento.qtdBancosContratados}`
            );
        }

        if (empresas.length > this.relacionamento.qtdCNPJsContratados) {
            isValid = false;
            this._notification.warning(
                `A quantidade de empresas está acima da contratada. Quantidade de empresas contratadas: ${this.relacionamento.qtdCNPJsContratados}`
            );
        }

        if (dadosBancarios.length > this.relacionamento.qtdContasContratadas) {
            isValid = false;
            this._notification.warning(
                `A quantidade de contas está acima da contratada. Quantidade de contas contratadas: ${this.relacionamento.qtdContasContratadas}`
            );
        }

        return isValid;
    }
}
