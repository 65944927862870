import { Injector } from '@angular/core';
import { UsuarioLogado } from '@utils/interfaces/usuario/usuario-logado.interface';
import { AuthLocalStorageService } from '@utils/services/auth-local-storage.service';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { environment } from 'environments/environment';
import Echo from "laravel-echo"
import socketio from 'socket.io-client'


export abstract class WebSocketService {
    protected ws
    private _localStorage: AuthLocalStorageService;
    protected usuarioLogado: UsuarioLogado
    protected _notification: NotificationService;

    constructor(
        injector: Injector,
    ) {
        this._localStorage = injector.get(AuthLocalStorageService);
        this._notification = injector.get(NotificationService);
    }

    init() {
        this.usuarioLogado = this._localStorage.getUsuario()

        this.ws = new Echo({
            broadcaster: 'socket.io',
            host: `ws://${environment.websocket}`,
            auth: {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${this._localStorage.getToken()}`
                }
            },
            client: socketio
        });
    }
}

