import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Injector,
    Input,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { Banco } from 'app/main/banco/shareds/interfaces/banco.interface';
import { BancoService } from 'app/main/banco/shareds/services/banco.service';
import { Projeto } from 'app/main/projeto/shareds/interfaces/projeto.interface';
import { FiltroOsVincular } from '../../shareds/interfaces/filtro-os-vincular.interface';
import { FiltroOs } from '../../shareds/interfaces/filtro-os.interface';
import { OsStatus } from '../../shareds/interfaces/os-status.interface';
import { OsVincular } from '../../shareds/interfaces/os-vincular.interface';
import { OsVincularRequest } from '../../shareds/interfaces/os-vincularRequest.interface';
import { OsStatusService } from '../../shareds/services/os-status.service';
import { OsVincularService } from '../../shareds/services/os-vincular.service';

@Component({
    selector: 'os-vincular',
    templateUrl: './os-vincular.component.html',
    styleUrls: ['./os-vincular.component.scss'],
})
export class OsVincularComponent
    extends FormFiltroBase<FiltroOs>
    implements OnInit {
    @Output() filtrando = new EventEmitter();
    @Input() projeto: Projeto;
    statuss: Array<OsStatus>;
    bancos: Array<Banco>;
    form: FormGroup;
    isPesquisa: boolean = false;

    campoPesquisa: string;
    osVinculosFull: Array<OsVincular> = [];
    osVinculosSelecionados: Array<OsVincular> = [];
    osVinculosFullCheckbox: Array<OsVincular> = [];
    osVinculosSelecionadosCheckbox: Array<OsVincular> = [];
    osVinculosFullTotal: Array<OsVincular> = [];
    todosOsVinculosFull: boolean = false;
    todosOsVinculosSelecionado: boolean = false;
    osVincularRequest: Array<OsVincularRequest> = [];
    listaStatus: Array<OsStatus> = [];
    listaOsVinculos: Array<OsVincular> = [];
    private campoPesquisaOld: string;

    constructor(
        protected _notification: NotificationService,
        protected _spinner: SpinnerService,
        protected _osVincularService: OsVincularService,
        protected _bancoService: BancoService,
        protected _statusService: OsStatusService,

        protected _route: ActivatedRoute,
        injector: Injector
    ) {
        super('OsVincular', injector);
    }

    ngOnInit() {
        this._spinner.show();
        this._bancoService.find().subscribe(
            (result) => {
                this.bancos = result.data;
                this._statusService.find().subscribe(
                    (result) => {
                        this.statuss = result.data;
                        this._spinner.hide();
                    },
                    (error) => this.error(error)
                );
            },
            (error) => this.error(error)
        );
        this._osVincularService
            .findByIdProjeto(this.projeto.id)
            .subscribe((dados) => (this.osVinculosSelecionados = dados.data));
    }

    camposFiltro(): string[] {
        return [];
    }

    protected crateForm(): { [key: string]: any } {
        return {
            status: [],
            inscricao: [],
            empresa: [],
            numeroOs: [],
            banco: [],
            dataCriacao: [],
        };
    }

    filtrarExec(): void {
        const filtro: FiltroOsVincular = {};
        filtro.idStatus = this.form.get('status').value;
        filtro.inscricao = this.form.get('inscricao').value;
        filtro.empresaNome = this.form.get('empresa').value;
        filtro.numeroOs = this.form.get('numeroOs').value;
        filtro.idBanco = this.form.get('banco').value;
        if (this.form.get('dataCriacao').value) {
            filtro.dataCriacaoOs = this.form.get('dataCriacao').value.toDate();
        }
        if (
            !filtro.idStatus &&
            !filtro.inscricao &&
            !filtro.empresaNome &&
            !filtro.numeroOs &&
            !filtro.idBanco &&
            !filtro.dataCriacaoOs
        ) {
            this._notification.info(
                'Preencha pelo ao menos um campo de pesquisa'
            );
            return;
        }
        this.isPesquisa = true;
        this.filtrando.emit(filtro);
        this.inicializaListas(filtro);
    }

    limpar(): void {
        this.isPesquisa = false;
        this.form.reset();
        this.filtro = Object.assign({});
        this.filtrar.emit(this.filtro);
    }

    private inicializaListas(filtro: FiltroOsVincular) {
        this._spinner.show();
        this._osVincularService.find(filtro).subscribe(
            (dados) => {
                this._spinner.hide();
                const osVinculosFuller = [];
                console.log(dados)
                dados.data.forEach((d) => {
                    d.status = this.statuss.find((b) => b.id === d.status.id);
                    d.banco = this.bancos.find((e) => e.id === d.banco.id);
                    osVinculosFuller.push(d);
                });

                this.osVinculosFull = osVinculosFuller;

                osVinculosFuller.forEach((val, index) => {
                    this.osVinculosSelecionados.forEach((sel) => {
                        if (val.id === sel.id) {
                            this.osVinculosFull.splice(index, 1);
                        }
                    });
                });

                this.osVinculosFullTotal = this.osVinculosFull;
            },
            (error) => this.error(error)
        );
    }

    pesquisarPorTexto(): void {
        if (
            this.campoPesquisa &&
            this.campoPesquisa !== this.campoPesquisaOld
        ) {
            this.campoPesquisaOld = this.campoPesquisa;
        }
    }

    limparCampoPesquisa(): void {
        this.campoPesquisa = '';
    }

    // OsVinculosFull
    addFullCheckbox(): void {
        const osVinculosFullCheckbox = Array.from(this.osVinculosFullCheckbox);

        osVinculosFullCheckbox.forEach((r: OsVincular, i: number) => {
            const index = this.osVinculosFull.findIndex(
                (rel) => rel.id === r.id
            );
            r.selecionado = false;
            this.adicionarSelecionado(r, index);
        });

        this.todosOsVinculosFull = false;
        this.osVinculosFullCheckbox = [];
    }

    removeFullCheckbox(osVincular: OsVincular): void {
        const index = this.osVinculosFullCheckbox.findIndex(
            (r) => r.id === osVincular.id
        );
        this.osVinculosFullCheckbox.splice(index, 1);
    }

    adicionarFull(osVincular: OsVincular, index: number): void {
        osVincular.selecionado = false;
        this.osVinculosFull.push(osVincular);
        this.removeSelecionadoCheckboxArray(osVincular);
        this.removeOsVincularSelecionado(index);
    }

    removeOsVincularFull(index: number): void {
        this.osVinculosFull.splice(index, 1);
    }

    selecionaFull(osVincular: OsVincular, checked: boolean): void {
        if (checked) {
            this.osVinculosFullCheckbox.push(osVincular);
        } else {
            this.removeFullCheckbox(osVincular);
        }
    }

    setTodosOsVinculosFull(selecionado: boolean) {
        this.todosOsVinculosFull = selecionado;
        if (this.osVinculosFull === null) {
            return;
        }
        if (selecionado) {
            this.osVinculosFullCheckbox = Array.from(this.osVinculosFull);
        } else {
            this.osVinculosFullCheckbox = [];
        }
        this.osVinculosFull.forEach((r) => (r.selecionado = selecionado));
    }

    // OsVinculos Selecionados
    removeSelecionadosCheckbox(): void {
        const osVinculosSelecionadosCheckbox = Array.from(
            this.osVinculosSelecionadosCheckbox
        );

        osVinculosSelecionadosCheckbox.forEach((r: OsVincular, i: number) => {
            const index = this.osVinculosSelecionados.findIndex(
                (rel) => rel.id === r.id
            );
            r.selecionado = false;
            this.adicionarFull(r, index);
        });

        this.todosOsVinculosSelecionado = false;
        this.osVinculosSelecionadosCheckbox = [];
    }

    adicionarSelecionado(osVincular: OsVincular, index: number): void {
        osVincular.selecionado = false;
        this.osVinculosSelecionados.push(osVincular);
        this.removeFullCheckbox(osVincular);
        this.removeOsVincularFull(index);
    }

    removeSelecionadoCheckboxArray(osVincular: OsVincular): void {
        const index = this.osVinculosFullCheckbox.findIndex(
            (r) => r.id === osVincular.id
        );
        this.osVinculosFullCheckbox.splice(index, 1);
    }

    removeOsVincularSelecionado(index: number): void {
        this.osVinculosSelecionados.splice(index, 1);
    }

    selecionaSelecionado(osVincular: OsVincular, checked: boolean): void {
        if (checked) {
            this.osVinculosSelecionadosCheckbox.push(osVincular);
        } else {
            this.removeSelecionadoCheckboxArray(osVincular);
        }
    }

    setTodosOsVinculosSelecionado(selecionado: boolean) {
        this.todosOsVinculosSelecionado = selecionado;
        if (this.osVinculosSelecionados === null) {
            return;
        }
        if (selecionado) {
            this.osVinculosSelecionadosCheckbox = Array.from(
                this.osVinculosSelecionados
            );
        } else {
            this.osVinculosSelecionadosCheckbox = [];
        }
        this.osVinculosSelecionados.forEach(
            (r) => (r.selecionado = selecionado)
        );
    }

    salvar() {
        const idsOsVinculos: Array<number> = [];
        this.osVinculosSelecionados.forEach((rel) =>
            idsOsVinculos.push(rel.id)
        );
        const osVincularRequest: OsVincularRequest = {
            projeto: this.projeto,
            oss: [],
        };
        this.osVinculosSelecionados.forEach((s) => {
            osVincularRequest.oss.push({
                id: s.id,
            });
        });
        this._spinner.show();
        this._osVincularService.save(osVincularRequest).subscribe(
            () => {
                this.sucess('Ação executada com sucesso');
            },
            (error) => this.error(error)
        );
    }
}
