import { Injector, Input, Output, EventEmitter } from '@angular/core';
import { FiltroUtil } from '@utils/utils/class/filtro.util';
import { FiltroService } from '@utils/utils/filtro/filtro.service';
import { FiltroModalService } from '@utils/utils/filtro/shareds/services/filtro-modal.service';
import { FormBase } from '../form-base/form-base';
import { OptionsConfig } from '../form-base/options-config.interface';
import { FiltroBase } from './interfaces/filtro-base.interface';
export abstract class FormFiltroBase<F extends FiltroBase> extends FormBase {
    protected filtro: F;
    private filtroUtil: FiltroUtil<F>;
    private chaveFiltro: string;
    private filtroService: FiltroService
    private filtroModalService: FiltroModalService
    private camposHabilitados: Array<string> = []

    @Output() filtrar = new EventEmitter<F>();

    abstract camposFiltro(): Array<string>;

    constructor(
        _chaveFiltro: string,
        _injector: Injector,
        optionsConfig?: Array<OptionsConfig>,
    ) {
        super(_injector, optionsConfig);
        this.chaveFiltro = _chaveFiltro;
        this.filtroService = _injector.get(FiltroService);
        this.filtroModalService = _injector.get(FiltroModalService);
        this.filtroUtil = new FiltroUtil<F>();
        this.carregaConfiguracoesFiltro();
    }

    get filtroAttr(): F {
        return this.filtro;
    }

    @Input('filtro')
    set filtroAttr(val: F) {
        this.form.setValue(val);
        this.filtro = val;
    }

    limpar(): void {
        this.form.reset();
        this.filtro = Object.assign({});
        this.filtrar.emit(this.filtro);
    }

    filtrarExec(): void {
        const filtro: F = this.form.value;
        this.filtrar.emit(filtro);
    }

    salvaFiltro() {
        const filtro: F = this.form.value;
        const filtroCampos: Array<string> = Object.keys(filtro);

        const requestFiltro = {
            "filtroValores": filtro ? JSON.stringify(filtro) : '',
            "tela": this.chaveFiltro
        }

        this._spinner.show();
        this.filtroService.save(requestFiltro).subscribe(
            result => {
                this.sucess("Filtro salvo com sucesso!")
            },
            error => this.error(error)
        )
    }

    configurarFiltro() {
        this.filtroModalService.configuraFiltro({ campos: this.camposFiltro(), chave: this.chaveFiltro }).subscribe(result => {
            if (result) {
                this.carregaConfiguracoesFiltro();
            }
        })
    }

    private carregaConfiguracoesFiltro() {
        this._spinner.show();
        this.filtroService.findByChave(this.chaveFiltro).subscribe(
            result => {
                this._spinner.hide();
                const filtroSalvarDados = result.data
                const filtro = filtroSalvarDados.filtroValores ? JSON.parse(filtroSalvarDados.filtroValores) : undefined
                const campos = filtroSalvarDados.filtroCampos ? JSON.parse(filtroSalvarDados.filtroCampos) : undefined
                this.setValueFiltro(filtro)
                this.setCamposHabilitados(campos)
            },
            error => {
                this._spinner.hide();
            }
        )

    }

    private setValueFiltro(filtro: F) {
        if (filtro) {
            this.form.setValue(filtro)
            this.filtro = Object.assign({}, filtro);
            setTimeout(() => {
                this.filtrarExec();
            }, 100)
        }
        else {
            this.filtro = Object.assign({});
        }
    }

    private setCamposHabilitados(campos: any) {
        this.camposHabilitados = [];

        if (campos) {
            const todosCampos = this.form.value
            Object.keys(todosCampos).forEach((chave) => {
                this.ocultaCampo(chave, true)
            });

            Object.keys(campos).forEach((chave) => {
                this.ocultaCampo(chave, campos[chave] ? false : true)
            });
        }
    }

    ocultaCampo(chave: string, ocultar: boolean): void {
        const el = <HTMLElement>document.querySelector("[formControlName='" + chave + "']")
        if (el) {
            const div = el.closest("div div")
            const mat = div.closest("mat-form-field")
            if (mat) {
                this.ocultaControle(mat, ocultar)
                this.mostraCampoTipoData(chave, ocultar)
            }
            if (mat === null && div.classList.contains("field-section")) {
                this.ocultaControle(div, ocultar)
            }
        }
    }

    mostraCampoTipoData(chave: string, ocultar: boolean): void {
        if (
            chave == "dataInicio"
            || chave == "dataFim"
            || chave == "dataInicial"
            || chave == "dataFinal"
        ) {
            const el = <HTMLElement>document.querySelector("[formControlName='tipoData']")
            if (el) {
                this.ocultaControle(el, ocultar)
            }
        }
    }

    ocultaControle(el: HTMLElement | Element, ocultar: Boolean) {
        if (ocultar) {
            el.classList.add('hide-form')
        } else {
            el.classList.remove('hide-form')
        }
    }
}
