import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'perfis',
        title: 'Perfis',
        type: 'item',
        icon: 'https',
        url: '/acl/perfil',
        role: 'PERFIL_LISTAR',
        cy: 'menu-perfil',
    },
    {
        id: 'usuarios',
        title: 'Usuários',
        type: 'item',
        icon: 'group',
        url: '/usuario',
        role: 'USUARIO_LISTAR',
        cy: 'menu-usuario',
    },
    {
        id: 'cotacoes',
        title: 'Cotações',
        type: 'item',
        icon: 'assignment',
        url: '/cotacao',
        role: 'COTACAO_LISTAR',
        cy: 'menu-cotacao',
    },
    {
        id: 'importacao',
        title: 'Importações',
        type: 'item',
        icon: 'cloud_upload',
        url: '/importacao/cotacao',
        role: 'IMPORTACAO_LISTAR',
        cy: 'menu-importacao',
    },
    {
        id: 'checklist',
        title: 'Checklist',
        type: 'item',
        icon: 'playlist_add_check',
        url: '/checklist',
        role: 'CHECKLIST_LISTAR',
        cy: 'menu-checklist',
    },
    {
        id: 'os',
        title: 'OS',
        type: 'item',
        icon: 'business_center',
        url: '/os',
        role: 'PROJETO_BUSCA',
        cy: 'menu-os',
    },
    {
        id: 'relacionamento-abertura',
        title: 'Relacionamento Abertura',
        type: 'item',
        icon: 'business_center',
        url: '/relacionamento-abertura',
        role: 'RELACIONAMENTO_ABERTURA_LISTAR',
        cy: 'menu-os',
    },
    {
        id: 'projeto',
        title: 'Projetos',
        type: 'item',
        icon: 'assignment_turned_in',
        url: '/projeto',
        role: 'PROJETO_BUSCA',
        cy: 'menu-projetos',
    },
    {
        id: 'time',
        title: 'Time',
        type: 'item',
        icon: 'group_work',
        url: '/time',
        role: 'TIME_LISTAR',
        cy: 'menu-time',
    },
    {
        id: 'contrato',
        title: 'Contrato',
        type: 'item',
        icon: 'archive',
        url: '/contrato',
        role: 'CONTRATO_LISTAR',
        cy: 'menu-contrato',
    },
    {
        id: 'gmud',
        title: 'GMUD',
        type: 'item',
        icon: 'dvr',
        url: '/gmud',
        role: 'GMUD_LISTAR',
        cy: 'menu-gmud',
    },
    {
        id: 'faturamento',
        title: 'Faturamento',
        type: 'item',
        icon: 'attach_money',
        url: '/faturamento',
        role: 'FATURAMENTO_LISTAR',
    },
    {
        id: 'relatorio',
        title: 'Relatórios',
        type: 'item',
        icon: 'pie_chart',
        url: '/relatorio',
        role: 'RELATORIO_LISTAR',
        cy: 'menu-relatorios',
    },
    // {
    //     id: 'configuracoes',
    //     title: 'Configurações',
    //     type: 'collapsable',
    //     icon: 'build',
    //     url: '',
    //     role: 'RELATORIO_LISTAR',
    //     cy: 'menu-configuracao',
    //     children: [
    //         {
    //             id: 'banco',
    //             title: 'Bancos',
    //             type: 'item',
    //             icon: 'account_balance',
    //             url: '/banco',
    //             role: 'BANCO_LISTAR',
    //             cy: 'menu-banco',
    //         }
    //     ]
    // },
];
