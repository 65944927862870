import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@utils/utils.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
} from '@angular/material';

import { OsRoutingModule } from './os-routing.module';
import { OsGuard } from './os.guard';
import { OsCadastroPageComponent } from './pages/os-cadastro/os-cadastro-page.component';
import { OsCadastroComponent } from './componentes/os-cadastro/os-cadastro.component';
import { OsHistoricoComponent } from './componentes/os-historico/os-historico.component';
import { OsAberturaComponent } from './componentes/os-abertura/os-abertura.component';
import { OsDetalheComponent } from './componentes/os-detalhe/os-detalhe.component';
import { OsMenuComponent } from './componentes/os-menu/os-menu.component';
import { OsDetalheResolver } from './shareds/resolvers/os-detalhe.resolver';
import { OsAberturaService } from './shareds/services/os-abertura.service';
import { OsService } from './shareds/services/os.service';
import { OsFasesService } from './shareds/services/os-fases.service';
import { OsFasesComponent } from './componentes/os-fases/os-fases.component';
import { OsGridPageComponent } from './pages/os-grid-page/os-grid-page.component';
import { OsGridComponent } from './componentes/os-grid/os-grid.component';
import { OsGridFiltroComponent } from './componentes/os-grid-filtro/os-grid-filtro.component';
import { OsHistoricoService } from './shareds/services/os-historico.service';
import { OsDocumentacaoComponent } from './componentes/os-documentacao/os-documentacao.component';
import { OsProjetoComponent } from './componentes/os-projeto/os-projeto.component';
import { OsDocumentoDownloadService } from './shareds/services/os-documento-download.service';
import { OsDocumentoUploadService } from './shareds/services/os-documento-upload.service';
import { OsStatusListaResolver } from './shareds/resolvers/os-status.resolver';
import { OsStatusService } from './shareds/services/os-status.service';
import { ModalOsVincularComponent } from './modal/modal-os-vincular/modal-os-vincular.component';
import { OsVincularService } from './shareds/services/os-vincular.service';
import { OsVincularModalService } from './shareds/services/os-vincular-modal.service';
import { OsVincularComponent } from './componentes/os-vincular/os-vincular.component';
import { OsEmpresaService } from './shareds/services/os-empresa.service';
import { ModalOsEmpresaGridComponent } from './modal/modal-os-empresa-grid/modal-os-empresa-grid.component';
import { ModalOsEmpresaCadastroComponent } from './modal/modal-os-empresa-cadastro/modal-os-empresa-cadastro.component';
import { OsEmpresaGridComponent } from './componentes/os-empresa-grid/os-empresa-grid.component';
import { OsEmpresaCadastroComponent } from './componentes/os-empresa-cadastro/os-empresa-cadastro.component';
import { OsCategoriaListaResolver } from './shareds/resolvers/os-categoria.resolver';
import { OsFaseStatusService } from './shareds/services/os-fase-status.service';
import { OsFaseNomeService } from './shareds/services/os-fase-nome.service';
import { OsFaseStatusListaResolver } from './shareds/resolvers/os-fase-status.resolver';
import { OsFaseNomeListaResolver } from './shareds/resolvers/os-fase-nome.resolver';
import { MatRadioModule } from '@angular/material/radio';
import { OsEmpresaListaResolver } from './shareds/resolvers/os-empresa.resolver';
import { CustoTrafegoService } from './shareds/services/custo-trafego.service';
import { ProdutoFinanceiroService } from './shareds/services/produto-financeiro.service';
import { LayoutService } from './shareds/services/layout.service';
import { ModalOsHistoricoComponent } from './modal/modal-os-historico/modal-os-historico.component';
import { OsHistoricoModalService } from './shareds/services/os-historico-modal.service';
import { OsHistoricoDetalheComponent } from './componentes/os-historico-detalhe/os-historico-detalhe.component';
import { OsProjetoService } from './shareds/services/os-projeto.service';
import { ModalEditarOsComponent } from './modal/modal-editar-os/modal-editar-os.component';
import { EditarOsComponent } from './componentes/editar-os/editar-os.component';
import { OsModalService } from './shareds/services/os-modal.service';
import { OsEdicaoService } from './shareds/services/os-edicao.service';
import { ModalVincularProjetoComponent } from './modal/modal-vincular-projeto/modal-vincular-projeto.component';
import { OsCotacaoResolver } from './shareds/resolvers/os-cotacao.resolver';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
    declarations: [
        OsCadastroComponent,
        OsCadastroPageComponent,
        OsHistoricoComponent,
        OsDetalheComponent,
        OsAberturaComponent,
        OsMenuComponent,
        OsFasesComponent,
        OsGridPageComponent,
        OsGridComponent,
        OsGridFiltroComponent,
        OsDocumentacaoComponent,
        OsProjetoComponent,
        ModalOsVincularComponent,
        OsVincularComponent,
        ModalOsEmpresaGridComponent,
        ModalOsEmpresaCadastroComponent,
        OsEmpresaCadastroComponent,
        OsEmpresaGridComponent,
        ModalOsHistoricoComponent,
        OsHistoricoDetalheComponent,
        ModalEditarOsComponent,
        EditarOsComponent,
        ModalVincularProjetoComponent,
    ],

    imports: [
        CommonModule,
        OsRoutingModule,
        UtilsModule,
        NgxMatSelectSearchModule,

        //TABLE
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,

        //FORMULARIO
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatRadioModule,
        NgxMatSelectSearchModule,
    ],

    providers: [
        OsAberturaService,
        OsGuard,
        OsDetalheResolver,
        OsService,
        OsFasesService,
        OsHistoricoService,
        OsDocumentoDownloadService,
        OsDocumentoUploadService,
        OsStatusListaResolver,
        OsStatusService,
        OsVincularService,
        OsVincularModalService,
        OsHistoricoModalService,
        OsEmpresaService,
        OsCategoriaListaResolver,
        OsFaseStatusService,
        OsFaseNomeService,
        OsFaseStatusListaResolver,
        OsFaseNomeListaResolver,
        OsEmpresaListaResolver,
        ProdutoFinanceiroService,
        CustoTrafegoService,
        LayoutService,
        OsProjetoService,
        OsModalService,
        OsEdicaoService,
        OsCotacaoResolver,
    ],

    entryComponents: [
        ModalOsVincularComponent,
        ModalOsEmpresaGridComponent,
        ModalOsEmpresaCadastroComponent,
        ModalOsHistoricoComponent,
        ModalEditarOsComponent,
        ModalVincularProjetoComponent,
    ],
})
export class OsModule { }
