import { Route } from '@angular/compiler/src/core';
import {
    Component,
    Injector,
    OnInit,
    Output,
    EventEmitter,
    ViewChild
} from '@angular/core';
import { MatOption, MatSelect } from "@angular/material"
import { ActivatedRoute } from '@angular/router';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { DataUtil } from '@utils/utils/class/data.util';
import { Banco } from 'app/main/banco/shareds/interfaces/banco.interface';
import { Time } from 'app/main/time/shareds/interfaces/time.interface';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';
import { FiltroOs } from '../../shareds/interfaces/filtro-os.interface';
import { OsCategoria } from '../../shareds/interfaces/os-categoria.interface';
import { OsStatus } from '../../shareds/interfaces/os-status.interface';
import { OsVincular } from '../../shareds/interfaces/os-vincular.interface';

@Component({
    selector: 'os-grid-filtro',
    templateUrl: './os-grid-filtro.component.html',
    styleUrls: ['./os-grid-filtro.component.scss'],
})
export class OsGridFiltroComponent
    extends FormFiltroBase<FiltroOs>
    implements OnInit {
    @Output() filtrar = new EventEmitter();
    statuss: Array<OsStatus> = [];
    categorias: Array<OsCategoria> = [];
    bancos: Array<Banco> = [];
    times: Array<Time> = [];
    usuarios: Array<Usuario> = [];
    os: OsVincular;
    allSelected = false;
    @ViewChild('statusSel', { static: true }) skillSel: MatSelect;

    constructor(injector: Injector) {
        super('OsGrid', injector, OsGridFiltroCombos.combos());
    }

    ngOnInit() {
        this._route.data.subscribe((data) => {
            this.optionList.listaBancos = data.bancos;
            this.optionList.listaStatuss = data.statuss;
            this.optionList.listaCategorias = data.categorias;
            this.optionList.listaTimes = data.times;
            this.optionList.listaUsuarios = data.usuarios;

            this.form.patchValue({ tipoData: 'data-criacao' })
            this.refreshOptionsConfig();
        });
    }

    camposFiltro(): string[] {
        return [
            'id',
            'status',
            'banco',
            'time',
            'usuario',
            'sistema',
            'categoria',
            'empresa',
            'inscricao',
            'dataInicio',
            'dataFim',
        ];
    }

    protected crateForm(): { [key: string]: any } {
        return {
            id: [],
            idFinnet: [],
            status: [],
            banco: [],
            time: [],
            usuario: [],
            sistema: [],
            categoria: [],
            empresa: [],
            inscricao: [],
            tipoData: [],
            dataInicio: [],
            dataFim: [],
            conta: [],
            convenio: [],
            emailContato: [],
        };
    }

    filtrarExec(): void {
        let status = this.form.get('status').value;
        let time = this.form.get('time').value;
        let categoria = this.form.get('categoria').value;
        let usuario = this.form.get('usuario').value;
        const id = this.form.get('id').value;
        const banco = this.form.get('banco').value;
        const sistema = this.form.get('sistema').value;

        const tipoData = this.form.get('tipoData').value;
        const dataInicio = this.form.get('dataInicio').value;
        const dataFim = this.form.get('dataFim').value;
        const empresaInscricao = this.form.get('inscricao').value;
        const empresaNome = this.form.get('empresa').value;

        const idFinnet = this.form.get('idFinnet').value;
        const conta = this.form.get('conta').value;
        const convenio = this.form.get('convenio').value;
        const contatoEmail = this.form.get('emailContato').value;

        status = status ? status : [];
        time = time ? time : [];
        categoria = categoria ? categoria : [];
        usuario = usuario ? usuario : [];
        const dataInicioStr = dataInicio ? DataUtil.toDateBackend(dataInicio) : '';
        const dataFimStr = dataFim ? DataUtil.toDateBackend(dataFim) : '';

        if (status.length > 0) {
            status = status.filter(function (i) {
                return i;
            });
        }

        console.log(status);

        const filtro: FiltroOs = {};

        if (id) filtro.idOs = id;
        if (banco) filtro.idBanco = banco.id;
        if (status) filtro.idOsStatus = [].concat(status.map((s) => s.id));
        if (time) filtro.idTime = [].concat(time.map((t) => t.id || 'is_null'));
        if (usuario)
            filtro.idResponsavel = [].concat(
                usuario.map((u) => u.id || 'is_null')
            );
        if (categoria)
            filtro.idCategoria = [].concat(categoria.map((c) => c.id));
        if (sistema) filtro.sistema = sistema;
        if (empresaInscricao) filtro.empresaInscricao = empresaInscricao;
        if (empresaNome) filtro.empresaNome = empresaNome;
        if (tipoData === 'data-criacao') {
            if (dataInicioStr) filtro.dataCriacaoInicio = dataInicioStr;
            if (dataFimStr) filtro.dataCriacaoFinal = dataFimStr;
        }
        if (tipoData === 'data-finalizacao') {
            if (dataInicioStr) filtro.dataFinalizacaoInicio = dataInicioStr;
            if (dataFimStr) filtro.dataFinalizacaoFinal = dataFimStr;
        }
        if (tipoData === 'data-sla') {
            if (dataInicioStr) filtro.dataPlanejadaInicio = dataInicioStr;
            if (dataFimStr) filtro.dataPlanejadaFinal = dataFimStr;
        }
        filtro.aberturaIdFinnet = idFinnet;
        filtro.contatoEmail = contatoEmail;
        filtro.conta = conta;
        filtro.convenio = convenio;

        this.filtrar.emit(filtro);
    }

    limpar(): void {
        this.form.reset();
        this.form.patchValue({ tipoData: 'data-criacao' })
        this.filtro = Object.assign({});
        this.filtrar.emit(this.filtro);
    }

    selecionarTudo(): void {
        this.allSelected = !this.allSelected;  // to control select-unselect

        if (this.allSelected) {
            this.skillSel.options.forEach((item: MatOption) => item.select());
        } else {
            this.skillSel.options.forEach((item: MatOption) => { item.deselect() });
        }
    }
}


export class OsGridFiltroCombos {
    static combos() {
        return [
            {
                listName: 'listaBancos',
                filterName: 'filterBancos',
                fieldValue: 'nome',
            },
            {
                listName: 'listaStatuss',
                filterName: 'filterStatus',
                fieldValue: 'nome',
            },
            {
                listName: 'listaCategorias',
                filterName: 'filterCategoria',
                fieldValue: 'nome',
            },
            {
                listName: 'listaTimes',
                filterName: 'filterTime',
                fieldValue: 'nome',
            },
            {
                listName: 'listaUsuarios',
                filterName: 'filterUsuario',
                fieldValue: 'nome',
            },
        ];
    }
}