import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProdutoListaResolver } from '../produto/shareds/resolvers/produto-lista.resolver';
import { ContratoGuard } from './contrato.guard';
import { ContratoDetalhePageComponent } from './pages/contrato-detalhe-page/contrato-detalhe-page.component';
import { ContratoGridPageComponent } from './pages/contrato-grid-page/contrato-grid-page.component';
import { ContratoDetalheResolver } from './shared/resolvers/contrato-detalhe.resolver';
import { ContratoSituacaoListaResolver } from './shared/resolvers/contrato-situacao-lista.resolver';
import { ContratoStatusListaResolver } from './shared/resolvers/contrato-status-lista.resolver';
const routes: Routes = [
    {
        path: '',
        canActivate: [ContratoGuard],
        canLoad: [ContratoGuard],
        resolve: {
            statuss: ContratoStatusListaResolver,
            situacoes: ContratoSituacaoListaResolver
        },
        component: ContratoGridPageComponent,
    },
    {
        path: ':id',
        canActivate: [ContratoGuard],
        canLoad: [ContratoGuard],
        resolve: {
            contrato: ContratoDetalheResolver,
            contratoProdutosLista: ProdutoListaResolver,
            situacoes: ContratoSituacaoListaResolver,
            statuss: ContratoStatusListaResolver
        },
        component: ContratoDetalhePageComponent,
    },
    {
        path: 'contratosituacao',
        canActivate: [ContratoGuard],
        canLoad: [ContratoGuard],
        resolve: {
        },
        component: ContratoGridPageComponent,
    },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ContratoRoutingModule { }
