import { Component, Injector, Input, OnInit, Output } from '@angular/core';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { Checklist } from 'app/main/checklist/shared/interfaces/checklist.interface';
import { ChecklistNavegationStep } from '../../shared/interfaces/checklist-navegation-step.interface';
import { ChecklistProdutoPortal } from '../../shared/interfaces/checklist-produto-portal.interface';
import { ChecklistNavegacaoService } from '../../shared/services/checklist-navegacao.service';
import { ChecklistService } from '../../shared/services/checklist.service';

@Component({
    selector: 'checklist-navegation',
    templateUrl: './checklist-navegation.component.html',
    styleUrls: ['./checklist-navegation.component.scss'],
})
export class ChecklistNavegationComponent extends ComponentBase implements OnInit {
    private _recarregar: boolean;
    private _isVisualizacao: boolean = false;

    isVisualizaProduto: boolean = false;
    checklist: Checklist = {};
    listSteps: Array<ChecklistNavegationStep> = [];
    produtosBUs: Array<ChecklistProdutoPortal> = [];

    stepsConfig = {
        'contato-empresa': 'stepContato',
        conectividade: 'stepConectividade',
        'dados-empresa': 'stepEmpresa',
        relacionamento: 'stepRelacionamento',
        configuracao: 'stepConfiguracao',
        finalizacao: '',
        finalizacaoStep: 'finalizadoSteps',
    };

    get recarregar(): any {
        return this._recarregar;
    }
    @Input() set recarregar(value: any) {
        this._recarregar = value;
        this.recarregarMenu();
    }

    constructor(
        private _checklistService: ChecklistService,
        private _checklistNavegacao: ChecklistNavegacaoService,
        private _injector: Injector
    ) {
        super(_injector);
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            this.checklist = data.checklist;
            this.produtosBUs = data.produtoPortal;
            this._isVisualizacao = data.isVisualizacao;
            this.render();
        });
    }

    selecionaProduto(
        produtoBu: ChecklistProdutoPortal,
        isFinalizadoStep: boolean
    ) {
        if (isFinalizadoStep) {
            window.open(produtoBu.urlChecklistPortalComToken, '_blank');
        }
    }

    private recarregarMenu() {
        this._spinner.show();
        const idChecklist = this._route.snapshot.params.id;
        this._checklistService.findById(idChecklist).subscribe(
            (result) => {
                this._spinner.hide();
                this.checklist = result.data;
                this.render();
            },
            (error) => this.error(error)
        );
    }

    private render() {
        this.isVisualizaProduto = false;
        const listSteps = [];
        const stepsClass = {
            'contato-empresa': 'contato-empresa',
            conectividade: 'conectividade-disabled',
            'dados-empresa': 'dados-empresa-disabled',
            relacionamento: 'relacionamento-disabled',
            configuracao: 'configuracao-disabled',
            finalizacao: 'finalizacao-disabled',
            'finalizacao-step': 'finalizacao-step-disabled',
        };

        if (this.checklist.finalizadoSteps && this.produtosBUs) {
            this.isVisualizaProduto = true;
        }

        if (this._isVisualizacao) {
            this._checklistNavegacao
                .getListaStepsVisualizacao(this.checklist.id, this.checklist.criarConfiguracao)
                .forEach((step) => {
                    step.id = `${step.chave}`;
                    step.isUrlEnable = true;
                    if (step.chave !== 'finalizacao' && step.chave !== 'finalizacao-step') {
                        listSteps.push(step);
                    }
                })
            this.listSteps = listSteps;

        } else {
            this._checklistNavegacao
                .getListaSteps(this.checklist.id, this.checklist.criarConfiguracao)
                .forEach((step) => {
                    const checked = this.checklist[this.stepsConfig[step.chave]];
                    let isAddStep = true;

                    if (
                        step.chave === 'finalizacao-step' &&
                        this.produtosBUs &&
                        this.produtosBUs.length === 0
                    ) {
                        isAddStep = false;
                    }
                    if (
                        step.chave === 'finalizacao' &&
                        this.produtosBUs &&
                        this.produtosBUs.length === 0
                        && this.checklist.stepConfiguracao
                    ) {
                        step.id = 'finalizacao';
                        step.isUrlEnable = true;
                    } else if (
                        this.checklist.finalizadoSteps &&
                        step.chave !== 'finalizacao'
                    ) {
                        step.id = `${step.chave}-disabled-checked`;
                        step.isUrlEnable = false;
                    } else if (
                        this.checklist.finalizadoSteps &&
                        this.checklist.finalizadoProdutos
                    ) {
                        step.id = 'finalizacao';
                        step.isUrlEnable = true;
                    } else if (checked) {
                        const prossimoStep = this.getStep(step.chave).proximoStep;
                        stepsClass[step.chave] = `${step.chave}-checked`;
                        step.id = stepsClass[step.chave];
                        step.isUrlEnable = true;

                        if (prossimoStep) {
                            stepsClass[prossimoStep] = `${prossimoStep}`;
                        }
                    } else {
                        if (/disabled/.test(stepsClass[step.chave])) {
                            step.id = stepsClass[step.chave];
                            step.isUrlEnable = false;
                        } else {
                            step.id = stepsClass[step.chave];
                            step.isUrlEnable = true;
                        }
                    }

                    if (
                        !(
                            !this.checklist.criarConfiguracao &&
                            step.chave === 'configuracao'
                        ) &&
                        isAddStep
                    ) {
                        listSteps.push(step);
                    }
                });

            this.listSteps = listSteps;
        }

    }

    private getStep(chave: string) {
        return this._checklistNavegacao
            .getListaSteps(this.checklist.id, this.checklist.criarConfiguracao)
            .find((val) => val.chave === chave);
    }

    seleciona(step: ChecklistNavegationStep): void {
        if (step.isUrlEnable) {
            this._router.navigateByUrl(step.url);
        }
    }
}
