import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProjetoAberturaConfiguracao } from '../../shareds/interfaces/projeto-abertura-configuracao.interface';

@Component({
    selector: 'app-modal-caixa-postal-projeto-abertura',
    templateUrl: './modal-caixa-postal-projeto-abertura.component.html',
    styleUrls: ['./modal-caixa-postal-projeto-abertura.component.scss']
})
export class ModalCaixaPostalProjetoAberturaComponent implements OnInit {
    tituloModal: string = 'Caixa Postal';

    constructor(
        public dialogRef: MatDialogRef<ModalCaixaPostalProjetoAberturaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ProjetoAberturaConfiguracao
    ) { }

    ngOnInit() {
    }

    selecionado(checked: any) {
        this.dialogRef.close(checked);
    }

    fechar(): void {
        this.dialogRef.close();
    }

}
