import { Component, OnInit } from '@angular/core';
import { debug } from 'console';
import { FiltroBanco } from '../../shareds/interfaces/filtro-banco.interface';

@Component({
  selector: 'app-banco-grid-page',
  templateUrl: './banco-grid-page.component.html',
  styleUrls: ['./banco-grid-page.component.scss']
})
export class BancoGridPageComponent implements OnInit {

  filtro: FiltroBanco;

  constructor() { }

  ngOnInit() {
  }

  filtrar(filtro: FiltroBanco) {
    this.filtro = Object.assign({}, filtro);
  }

}
