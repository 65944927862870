import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { ThemeConfig } from '@utils/interfaces/theme/theme-config.interface';
import { AuthLocalStorageService } from '@utils/services/auth-local-storage.service';
import { themeConfig } from 'app/fuse-config/theme.config';
import { AuthService } from 'app/main/public/auth/shared/services/auth.service';
import { ThemeUser } from '@utils/interfaces/theme/theme-user.interface';
import { NotificacaoService } from 'app/main/notificacao/shareds/services/notificacao.service';
import { Notificacao } from 'app/main/notificacao/shareds/interfaces/notificacao.interface';
import { NotificacaoWebsocketService } from 'app/main/notificacao/shareds/services/notificacao-websocket.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { Router } from '@angular/router';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
    themeConfig: ThemeConfig;
    themeUser: ThemeUser;
    adminUser: boolean;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    notificacoes: Array<Notificacao> = []
    totalNotificacoes: number = 0;


    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _authLocalStoregeService: AuthLocalStorageService,
        private _authService: AuthService,
        private _router: Router,
        private _spinner: SpinnerService,
        private _notification: NotificationService,
        private _notificacaoModuloService: NotificacaoService,
        private _notificacaoWS: NotificacaoWebsocketService
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50',
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107',
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336',
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD',
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161',
            },
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us',
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr',
            },
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.themeConfig = themeConfig;
        this.themeUser = this._authLocalStoregeService.getThemeUser();
        this.adminUser = this._authLocalStoregeService.getAdminUser();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang,
        });


        this._notificacaoModuloService.qtdeNaoLido().subscribe(qtde => {
            this.totalNotificacoes = qtde;
        })

        this._notificacaoWS.totalNotificacoes(quantidade => {
            this.totalNotificacoes = quantidade
        })

    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    sair(): void {
        this._authService.deslogar().subscribe();
    }

    configuracao(): void {
        this._router.navigate(['configuracao']);
    }

    sairAdmin(): void {
        this._authService.deslogarAdmin().subscribe();
    }

    visualizarNotificacoes(): void {
        this._spinner.show()
        this._notificacaoModuloService.destaques().subscribe(
            notificacoes => {
                this.notificacoes = []
                notificacoes.forEach(notificacao => {
                    notificacao.mensagem = this.quebraConteudo(notificacao.mensagem);
                    this.notificacoes.push(notificacao)
                })
                this._spinner.hide();
            },
            error => {
                this._spinner.hide();
                this._notification.error(error.error.message)
            })
    }

    notificacaoDetalhe(notificacao: Notificacao) {
        this._notificacaoModuloService.lido(notificacao.id).subscribe(() => {
            if (notificacao.link) {
                const link = notificacao.link.replace('/#/', '')
                this._router.navigateByUrl(link)
            }
            else {
                this._router.navigateByUrl(`notificacao`)
            }
        })
    }


    quebraConteudo(msg: string): string {
        let retorno = ''

        if (msg) {

            let y = 0;
            let numOfCharacters = 80;
            let msgArray: Array<string> = msg.split(' ');
            let paragrafo = '';
            msgArray.forEach(palavra => {
                paragrafo = `${paragrafo} ${palavra}`;
                if (paragrafo.length > numOfCharacters && y <= 2) {
                    retorno += y < 2 ? `<p style="line-height: 0.5;">${paragrafo.trim()}</p>` : `<p style="line-height: 0.5;">${paragrafo.trim()}...</p>`;
                    paragrafo = palavra;
                    y++;

                }
            });
            if (y === 0) {
                retorno = `<p style="line-height: 0.5;">${paragrafo.trim()}</p>`;
            }
        }

        return retorno
    }
}
