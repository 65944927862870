export enum CotacaoStatusEnum {
    PENDENTE_FATURAMENTO_SETUP = 1,
    ERRO_NA_INTEGRACAO_COM_SINTEGRA = 2,
    PAGAMENTO_EFETUADO = 3,
    CANCELADO = 4,
    PAGAMENTO_PARCIAL = 5,
    ISENTO = 6,
    SETUP_EM_ABERTO = 13,
    LIBERACAO_DIRETORIA_COM_SETUP = 14,
    PENDENCIA_CLIENTE_ERP = 16,
}