import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BancoListaResolver } from '../banco/shareds/resolvers/banco-lista.resolver';
import { TimeListaResolver } from '../time/shareds/resolvers/time-lista.resolver';
import { UsuarioListaResolver } from '../usuario/resolvers/usuario-lista.resolver';
import { OsGuard } from './os.guard';
import { OsCadastroPageComponent } from './pages/os-cadastro/os-cadastro-page.component';
import { OsGridPageComponent } from './pages/os-grid-page/os-grid-page.component';
import { OsCategoriaListaResolver } from './shareds/resolvers/os-categoria.resolver';
import { OsCotacaoResolver } from './shareds/resolvers/os-cotacao.resolver';
import { OsDetalheResolver } from './shareds/resolvers/os-detalhe.resolver';
import { OsStatusListaResolver } from './shareds/resolvers/os-status.resolver';

const routes: Routes = [
    {
        path: '',
        canActivate: [OsGuard],
        canLoad: [OsGuard],
        resolve: {
            bancos: BancoListaResolver,
            statuss: OsStatusListaResolver,
            categorias: OsCategoriaListaResolver,
            times: TimeListaResolver,
            usuarios: UsuarioListaResolver,
        },
        component: OsGridPageComponent,
    },
    {
        path: 'cadastro',
        canActivate: [OsGuard],
        canLoad: [OsGuard],
        resolve: {
            statuss: OsStatusListaResolver,
            categorias: OsCategoriaListaResolver,
            bancos: BancoListaResolver,
        },
        component: OsCadastroPageComponent,
    },
    {
        path: ':id/editar',
        canActivate: [OsGuard],
        canLoad: [OsGuard],
        resolve: {
            os: OsDetalheResolver,
            statuss: OsStatusListaResolver,
            categorias: OsCategoriaListaResolver,
            bancos: BancoListaResolver
        },
        component: OsCadastroPageComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OsRoutingModule { }
