import { Component, Injector, OnInit } from '@angular/core';
import { SimNao } from '@utils/interfaces/shareds/sim-nao.interface';
import { ChecklistConectividadeBase } from '../checklist-conectividade-base';
import { ChecklistConectividadesApiOpenBanking } from '../shareds/interfaces/checklist-conectividades-api-open-banking.interface';

@Component({
  selector: 'checklist-conectividades-api-open-banking',
  templateUrl: './checklist-conectividades-api-open-banking.component.html',
  styleUrls: ['./checklist-conectividades-api-open-banking.component.scss']
})
export class ChecklistConectividadesApiOpenBankingComponent extends ChecklistConectividadeBase<ChecklistConectividadesApiOpenBanking> implements OnInit {

  public possuiConexaoApiStr: string = null;

  public listaSimNao: Array<SimNao> = [
    { codigo: 'SIM', descricao: 'Sim' },
    { codigo: 'NAO', descricao: 'Não' }
  ]

  constructor(
    protected _injector: Injector
  ) {
    super(_injector)
  }

  ngOnInit() {
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      possuiConexaoApi: [],
      urlCallback: [],
    }
  }

  protected setForm(data: ChecklistConectividadesApiOpenBanking) {
    const possuiConexaoApiVal = this.listaSimNao.find(val => val.codigo === data.possuiConexaoApi)
    this.possuiConexaoApiStr = data.possuiConexaoApi;
    this.form.patchValue({
      possuiConexaoApi: possuiConexaoApiVal,
      urlCallback: data.urlCallback,
    })
  }

  getForm() {
    const possuiConexaoApiVal = this.form.get('possuiConexaoApi').value
    const data: ChecklistConectividadesApiOpenBanking = {
      possuiConexaoApi: possuiConexaoApiVal.codigo,
      urlCallback: this.form.get('urlCallback').value,
    }

    this.values.emit(data);
  }

  changePossuiConexaoApi() {
    const possuiConexaoApiVal = this.form.get('possuiConexaoApi').value
    this.possuiConexaoApiStr = possuiConexaoApiVal.codigo;
    this.limpaSubForm();
    this.getForm();
  }

  limpaSubForm() {
    this.form.get('urlCallback').reset();
  }


}
