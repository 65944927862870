import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { Configurador } from '../interfaces/configurador.interface';
import { FiltroConfigurador } from '../interfaces/filtro-configurador.interface';
import { Response } from '@utils/interfaces/http/response.interface';

@Injectable({
    providedIn: 'root'
})
export class ConfiguradorService extends ServiceGridBase<Configurador, FiltroConfigurador> {
    private path: string = `${environment.server}/configurador`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroConfigurador): Observable<Response<Array<Configurador>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}${params}`;
        //return this._http.get<Response<Array<Configurador>>>(url);
        return of(this.mockList())
    }

    mockList() {
        return {
            data: [
                { codigo: 1, nome: "Erro" },
                { codigo: 2, nome: "Enviado" },
                { codigo: 3, nome: "Migrado" }
            ]
        }
    }
}
