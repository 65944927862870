import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CotacaoListaResolver } from '../cotacao/shared/resolvers/cotacao-lista.resolver';
import { ChecklistGuard } from './checklist.guard';
import { ChecklistConectividadePageComponent } from './pages/checklist-conectividade-page/checklist-conectividade-page.component';
import { ChecklistConfirmacaoStepPageComponent } from './pages/checklist-confirmacao-step-page/checklist-confirmacao-step-page.component';
import { ChecklistContatoEmpresaPageComponent } from './pages/checklist-contato-empresa-page/checklist-contato-empresa-page.component';
import { ChecklistDadosEmpresaPageComponent } from './pages/checklist-dados-empresa/checklist-dados-empresa-page.component';
import { ChecklistGridPageComponent } from './pages/checklist-grid-page/checklist-grid-page.component';
import { ChecklistInicioPageComponent } from './pages/checklist-inicio-page/checklist-inicio-page.component';
import { ChecklistConectividadeListaResolver } from './shared/resolvers/checklist-conectividade-lista.resolver';
import { ChecklistContatoEmpresaListaResolver } from './shared/resolvers/checklist-contato-empresa-lista.resolver';
import { ChecklistDadosEmpresaListaResolver } from './shared/resolvers/checklist-dados-empresa-lista.resolver';
import { ChecklistRelacionamentoPageComponent } from './pages/checklist-relacionamento/checklist-relacionamento-page.component';
import { ChecklistConfiguracaoPageComponent } from './pages/checklist-configuracao-page/checklist-configuracao-page.component';
import { BancoListaResolver } from '../banco/shareds/resolvers/banco-lista.resolver';
import { EmpresaListaResolver } from '../empresa/shareds/resolvers/empresa-lista.resolver';
import { ChecklistStatusListaResolver } from './shared/resolvers/checklist-status-lista.resolver';
import { CotacaoModuloChecklistListaResolver } from '../cotacao/shared/resolvers/cotacao-modulo-checklist-lista.resolver';
import { ChecklistCondiguracaoDetalheResolver } from './shared/resolvers/checklist-configuracao-detalhe.resolver';
import { ChecklistCondiguracaoDsnameLimiteResolver } from './shared/resolvers/checklist-configuracao-dsname-limite.resolver';
import { ChecklistDsnameDirecaoListaResolver } from './shared/resolvers/checklist-dsname-direcao-lista.resolver';
import { ChecklistDsnameConfigTipoListaResolver } from './shared/resolvers/checklist-dsname-config-tipo-lista.resolver';
import { ChecklistStepGuard } from './checklist-step.guard';
import { CotacaoClienteContarResolver } from '../cotacao/shared/resolvers/cotacao-cliente-contar.resolver';
import { ChecklistDetalheResolver } from './shared/resolvers/checklist-detalhe.resolver';
import { ChecklistProdutoPortalResolver } from './shared/resolvers/checklist-produto-portal.resolver';
import { ChecklistConfirmacaoChecklistPageComponent } from './pages/checklist-confirmacao-checklist-page/checklist-confirmacao-checklist-page.component';
import { ChecklistBuPageComponent } from './pages/checklist-bu-page/checklist-bu-page.component';
import { ChecklistIsVisualizacaoResolver } from './shared/resolvers/checklist-is-visualizacao.resolver';
import { DocumentoImporteChecklistDadosEmpresaListaResolver } from '../document-import/shareds/resolvers/documento-importe-lista.resolver';

const routes: Routes = [
    {
        path: '',
        canActivate: [ChecklistGuard],
        canLoad: [ChecklistGuard],
        resolve: {
            listaStatus: ChecklistStatusListaResolver,
        },
        component: ChecklistGridPageComponent,
    },
    {
        path: ':id/inicio',
        canActivate: [ChecklistGuard],
        canLoad: [ChecklistGuard],
        resolve: {
            checklist: ChecklistDetalheResolver,
            cotacoesCliente: CotacaoClienteContarResolver,
        },
        component: ChecklistInicioPageComponent,
    },
    {
        path: ':id/contato',
        canActivate: [ChecklistGuard, ChecklistStepGuard],
        canLoad: [ChecklistGuard],
        resolve: {
            checklist: ChecklistDetalheResolver,
            contatos: ChecklistContatoEmpresaListaResolver,
            produtoPortal: ChecklistProdutoPortalResolver,
        },
        component: ChecklistContatoEmpresaPageComponent,
    },
    {
        path: ':id/conectividade',
        canActivate: [ChecklistGuard, ChecklistStepGuard],
        canLoad: [ChecklistGuard],
        resolve: {
            checklist: ChecklistDetalheResolver,
            conectividades: ChecklistConectividadeListaResolver,
            produtoPortal: ChecklistProdutoPortalResolver,
        },
        component: ChecklistConectividadePageComponent,
    },
    {
        path: ':id/dados-empresa',
        canActivate: [ChecklistGuard, ChecklistStepGuard],
        canLoad: [ChecklistGuard],
        resolve: {
            checklist: ChecklistDetalheResolver,
            relacionamentos: ChecklistDadosEmpresaListaResolver,
            bancos: BancoListaResolver,
            empresas: EmpresaListaResolver,
            produtoPortal: ChecklistProdutoPortalResolver,
            listaDocumentos: DocumentoImporteChecklistDadosEmpresaListaResolver
        },
        component: ChecklistDadosEmpresaPageComponent,
    },
    {
        path: ':id/relacionamento',
        canActivate: [ChecklistGuard, ChecklistStepGuard],
        canLoad: [ChecklistGuard],
        resolve: {
            checklist: ChecklistDetalheResolver,
            bancos: BancoListaResolver,
            empresas: EmpresaListaResolver,
            produtoPortal: ChecklistProdutoPortalResolver,
        },
        component: ChecklistRelacionamentoPageComponent,
    },
    {
        path: ':id/configuracao',
        canActivate: [ChecklistGuard, ChecklistStepGuard],
        canLoad: [ChecklistGuard],
        resolve: {
            checklist: ChecklistDetalheResolver,
            modulos: CotacaoModuloChecklistListaResolver,
            configuracoes: ChecklistCondiguracaoDetalheResolver,
            dsnameLimite: ChecklistCondiguracaoDsnameLimiteResolver,
            direcoes: ChecklistDsnameDirecaoListaResolver,
            dsnameConfigTipos: ChecklistDsnameConfigTipoListaResolver,
            produtoPortal: ChecklistProdutoPortalResolver,
        },
        component: ChecklistConfiguracaoPageComponent,
    },
    {
        path: ':id/finalizacao-step',
        canActivate: [ChecklistGuard, ChecklistStepGuard],
        canLoad: [ChecklistGuard],
        resolve: {
            checklist: ChecklistDetalheResolver,
            produtoPortal: ChecklistProdutoPortalResolver,
        },
        component: ChecklistConfirmacaoStepPageComponent,
    },
    {
        path: ':id/bu',
        canActivate: [ChecklistGuard, ChecklistStepGuard],
        canLoad: [ChecklistGuard],
        resolve: {
            checklist: ChecklistDetalheResolver,
            produtoPortal: ChecklistProdutoPortalResolver,
        },
        component: ChecklistBuPageComponent,
    },
    {
        path: ':id/finalizacao',
        canActivate: [ChecklistGuard, ChecklistStepGuard],
        canLoad: [ChecklistGuard],
        resolve: {
            checklist: ChecklistDetalheResolver,
            produtoPortal: ChecklistProdutoPortalResolver,
        },
        component: ChecklistConfirmacaoChecklistPageComponent,
    },

    // {
    //     path: ':id/visualizar',
    //     canActivate: [ChecklistGuard, ChecklistDetalheGuard],
    //     canLoad: [ChecklistGuard],
    //     resolve: {
    //         checklist: ChecklistDetalheResolver,
    //         detalhe: ChecklistVisualizacaoDetalheResolver,
    //         contatos: ChecklistContatoEmpresaListaResolver,
    //         conectividades: ChecklistConectividadeListaResolver,
    //         direcoes: ChecklistDsnameDirecaoListaResolver,
    //         produtoPortal: ChecklistProdutoPortalResolver,
    //     },
    //     component: ChecklistDetalhePageComponent,
    // },

    /********************************************************
     *                  VISUALIZACAO
     *******************************************************/
    {
        path: ':id/visualizar/contato',
        canActivate: [ChecklistGuard],
        canLoad: [ChecklistGuard],
        resolve: {
            isVisualizacao: ChecklistIsVisualizacaoResolver,
            checklist: ChecklistDetalheResolver,
            contatos: ChecklistContatoEmpresaListaResolver,
            produtoPortal: ChecklistProdutoPortalResolver,
        },
        component: ChecklistContatoEmpresaPageComponent,
    },
    {
        path: ':id/visualizar/conectividade',
        canActivate: [ChecklistGuard],
        canLoad: [ChecklistGuard],
        resolve: {
            isVisualizacao: ChecklistIsVisualizacaoResolver,
            checklist: ChecklistDetalheResolver,
            conectividades: ChecklistConectividadeListaResolver,
            produtoPortal: ChecklistProdutoPortalResolver,
        },
        component: ChecklistConectividadePageComponent,
    },
    {
        path: ':id/visualizar/dados-empresa',
        canActivate: [ChecklistGuard],
        canLoad: [ChecklistGuard],
        resolve: {
            isVisualizacao: ChecklistIsVisualizacaoResolver,
            checklist: ChecklistDetalheResolver,
            relacionamentos: ChecklistDadosEmpresaListaResolver,
            bancos: BancoListaResolver,
            empresas: EmpresaListaResolver,
            produtoPortal: ChecklistProdutoPortalResolver,
        },
        component: ChecklistDadosEmpresaPageComponent,
    },
    {
        path: ':id/visualizar/relacionamento',
        canActivate: [ChecklistGuard],
        canLoad: [ChecklistGuard],
        resolve: {
            isVisualizacao: ChecklistIsVisualizacaoResolver,
            checklist: ChecklistDetalheResolver,
            bancos: BancoListaResolver,
            empresas: EmpresaListaResolver,
            produtoPortal: ChecklistProdutoPortalResolver,
        },
        component: ChecklistRelacionamentoPageComponent,
    },
    {
        path: ':id/visualizar/configuracao',
        canActivate: [ChecklistGuard],
        canLoad: [ChecklistGuard],
        resolve: {
            isVisualizacao: ChecklistIsVisualizacaoResolver,
            checklist: ChecklistDetalheResolver,
            modulos: CotacaoModuloChecklistListaResolver,
            configuracoes: ChecklistCondiguracaoDetalheResolver,
            dsnameLimite: ChecklistCondiguracaoDsnameLimiteResolver,
            direcoes: ChecklistDsnameDirecaoListaResolver,
            dsnameConfigTipos: ChecklistDsnameConfigTipoListaResolver,
            produtoPortal: ChecklistProdutoPortalResolver,
        },
        component: ChecklistConfiguracaoPageComponent,
    },


    { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ChecklistRoutingModule { }
