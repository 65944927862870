import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContratoItemListaResolver } from '../contrato/shared/resolvers/contrato-itens-lista.resolver';
import { CotacaoStatusListaResolver } from '../cotacao/shared/resolvers/cotacao-status-lista.resolver';
import { StatusFinanceiroListaResolver } from '../cotacao/shared/resolvers/status-financeiro-lista.resolver';
import { TipoCobrancaListaResolver } from '../cotacao/shared/resolvers/tipo-cobranca-lista.resolver';
import { TipoSetupFinanceiroListaResolver } from '../cotacao/shared/resolvers/tipo-setup-financeiro-lista.resolver';
import { FaturamentoGuard } from './faturamento.guard';
import { FaturamentoEditarDetalhePageComponent } from './pages/faturamento-detalhe-page/faturamento-editar-detalhe-page.component';
import { FaturamentoGridPageComponent } from './pages/faturamento-grid-page/faturamento-grid-page.component';
import { FaturamentoRecorrenteDetalhePageComponent } from './pages/faturamento-recorrente-detalhe-page/faturamento-recorrente-detalhe-page.component';
import { FaturamentoEmissorListaResolver } from './shared/resolvers/faturamento-emissor-lista.resolver';
import { FaturamentoParcelaStatusResolver } from './shared/resolvers/faturamento-parcela-status.resolver';
import { FaturamentoParcelarListaResolver } from './shared/resolvers/faturamento-parcelar-lista.resolver';
import { FaturamentoParcelasListaResolver } from './shared/resolvers/faturamento-parcelas-lista.resolver';
import { FaturamentoProdutoListaResolver } from './shared/resolvers/faturamento-produto-lista.resolver';
import { FaturamentoRecorrenteDadosComplementaresResolver } from './shared/resolvers/faturamento-recorrente-dados-complementares.resolver';
import { FaturamentoRecorrenteEmpresaResolver } from './shared/resolvers/faturamento-recorrente-empresa.resolver';
import { FaturamentoStatusFaturamentoRecorrenteListaResolver } from './shared/resolvers/faturamento-status-financeiro-recorrente.resolver';
import { FaturamentoRecorrenteItemListaResolver } from './shared/resolvers/faturamento-recorrente-item-lista.resolver';
import { FaturamentoRecorrenteStatusResolver } from './shared/resolvers/faturamento-recorrente-status.resolver';
import { FaturamentoRecorrenteResolver } from './shared/resolvers/faturamento-recorrente.resolver';
import { FaturamentoTipoFaturamentoListaResolver } from './shared/resolvers/faturamento-tipo-faturamento-lista.resolver';
import { FaturamentoResolver } from './shared/resolvers/faturamento.resolver';

const routes: Routes = [
    {
        path: '',
        canActivate: [FaturamentoGuard],
        canLoad: [FaturamentoGuard],
        resolve: {
            listaStatus: CotacaoStatusListaResolver,
            listaStatusRecorrente: FaturamentoStatusFaturamentoRecorrenteListaResolver,
            listaStatusFinanceiro: StatusFinanceiroListaResolver,
            listaTipoSetup: TipoSetupFinanceiroListaResolver,
            listaTipoFaturamento: FaturamentoTipoFaturamentoListaResolver,
            listaTipoCobranca: TipoCobrancaListaResolver,
        },
        component: FaturamentoGridPageComponent,
    },
    {
        path: 'cadastro/:id',
        canActivate: [FaturamentoGuard],
        canLoad: [FaturamentoGuard],
        resolve: {
            faturamento: FaturamentoResolver,
            faturamentoParcelas: FaturamentoParcelasListaResolver,
            faturamentoProdutos: FaturamentoProdutoListaResolver,
            listaStatus: CotacaoStatusListaResolver,
            listaTipoSetup: TipoSetupFinanceiroListaResolver,
            listaStatusFinanceiro: StatusFinanceiroListaResolver,
            listaTipoFaturamento: FaturamentoTipoFaturamentoListaResolver,
            listaTipoCobranca: TipoCobrancaListaResolver,
            listaEmissor: FaturamentoEmissorListaResolver,
            listaParcelar: FaturamentoParcelarListaResolver,
            listaStatusNota: FaturamentoParcelaStatusResolver,
        },
        component: FaturamentoEditarDetalhePageComponent,

    },

    {
        path: 'cadastro/:id/visualizar',
        canActivate: [FaturamentoGuard],
        canLoad: [FaturamentoGuard],
        resolve: {
            faturamento: FaturamentoResolver,
            faturamentoParcelas: FaturamentoParcelasListaResolver,
            faturamentoProdutos: FaturamentoProdutoListaResolver,
            listaStatus: CotacaoStatusListaResolver,
            listaTipoSetup: TipoSetupFinanceiroListaResolver,
            listaStatusFinanceiro: StatusFinanceiroListaResolver,
            listaTipoFaturamento: FaturamentoTipoFaturamentoListaResolver,
            listaTipoCobranca: TipoCobrancaListaResolver,
            listaEmissor: FaturamentoEmissorListaResolver,
            listaParcelar: FaturamentoParcelarListaResolver,
            listaStatusNota: FaturamentoParcelaStatusResolver,
        },
        component: FaturamentoEditarDetalhePageComponent,

    },

    {
        path: 'recorrente/:id',
        canActivate: [FaturamentoGuard],
        canLoad: [FaturamentoGuard],
        resolve: {
            faturamento: FaturamentoRecorrenteResolver,
            faturamentoRecorrenteEmpresa: FaturamentoRecorrenteEmpresaResolver,
            faturamentoRecorrenteDadosComplementares: FaturamentoRecorrenteDadosComplementaresResolver,
            listaFaturamentoItens: FaturamentoRecorrenteItemListaResolver,
            listaStatus: FaturamentoRecorrenteStatusResolver,
            listaTipoCobranca: TipoCobrancaListaResolver,
            listaEmissor: FaturamentoEmissorListaResolver,
            listaItensContrato: ContratoItemListaResolver,
        },
        component: FaturamentoRecorrenteDetalhePageComponent,

    },

    {
        path: 'recorrente/:id/visualizar',
        canActivate: [FaturamentoGuard],
        canLoad: [FaturamentoGuard],
        resolve: {
            faturamento: FaturamentoRecorrenteResolver,
            faturamentoRecorrenteEmpresa: FaturamentoRecorrenteEmpresaResolver,
            faturamentoRecorrenteDadosComplementares: FaturamentoRecorrenteDadosComplementaresResolver,
            listaFaturamentoItens: FaturamentoRecorrenteItemListaResolver,
            listaStatus: FaturamentoRecorrenteStatusResolver,
            listaTipoCobranca: TipoCobrancaListaResolver,
            listaEmissor: FaturamentoEmissorListaResolver,
            listaItensContrato: ContratoItemListaResolver,
        },
        component: FaturamentoRecorrenteDetalhePageComponent,

    },

    { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class FaturamentoRoutingModule { }
