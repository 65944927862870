import {
    Component,
    EventEmitter,
    Injector,
    OnInit,
    Output,
} from '@angular/core';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { DataUtil } from '@utils/utils/class/data.util';
import { FiltroGmud } from '../../shared/interfaces/filtro-gmud.interface';
import { GmudAmbiente } from '../../shared/interfaces/gmud-ambiente.interface';
import { GmudProduto } from '../../shared/interfaces/gmud-produto.interface';
import { GmudResponsavelDesenvolvimento } from '../../shared/interfaces/gmud-responsavel-desenvolvimento.interface';
import { GmudResponsavelProducao } from '../../shared/interfaces/gmud-responsavel-producao.interface';
import { GmudStatusGmud } from '../../shared/interfaces/gmud-status-gmud.interface';
import { GmudStatus } from '../../shared/interfaces/gmud-status.interface';
import { GmudService } from '../../shared/services/gmud.service';

@Component({
    selector: 'gmud-grid-filtro',
    templateUrl: './gmud-grid-filtro.component.html',
    styleUrls: ['./gmud-grid-filtro.component.scss'],
})
export class GmudGridFiltroComponent extends FormFiltroBase<FiltroGmud> implements OnInit {

    listaStatusImp: Array<GmudStatus> = [];
    listaStatusGmud: Array<GmudStatusGmud> = [];
    listaProdutos: Array<GmudProduto> = [];
    listaAmbientes: Array<GmudAmbiente> = [];
    listaResponsavelProducoes: Array<GmudResponsavelProducao> = [];
    listaResponsavelDesenvolvimentos: Array<GmudResponsavelDesenvolvimento> = [];

    @Output() filtrar = new EventEmitter<FiltroGmud>();

    constructor(
        injector: Injector,
        protected _gmudService: GmudService
    ) {
        super('GmudGrid', injector, GmudlistGridFiltroCombos.combos());
    }

    ngOnInit() {
        this._route.data.subscribe((data) => {
            this.optionList.listaStatusImp = data.impStatuss;
            this.optionList.listaStatusGmud = data.statussGmud;
            this.optionList.listaProdutos = data.produtos;
            this.optionList.listaAmbientes = data.ambientes;
            this.optionList.listaResponsavelProducoes = data.usuarios;
            this.optionList.listaResponsavelDesenvolvimentos = data.usuarios;

            this.refreshOptionsConfig();
        });
    }

    camposFiltro(): string[] {
        return [
            'statusImp',
            'statusGmud',
            'numeroImp',
            'numeroGmud',
            'produto',
            'ambiente',
            'dataInicio',
            'dataFim',
            'responsavelProducao',
            'responsavelDesenvolvimento'
        ];
    }

    protected crateForm(): { [key: string]: any } {
        return {
            statusImp: [],
            statusGmud: [],
            numeroImp: [],
            numeroGmud: [],
            produto: [],
            ambiente: [],
            tipoData: [],
            dataInicio: [],
            dataFim: [],
            responsavelProducao: [],
            responsavelDesenvolvimento: [],
        };
    }

    filtrarExecEnter(event): void {
        if (event.keyCode === 13) {
            this.filtrarExec();
        }
    }

    filtrarExec(): void {
        const statusImp = this.form.get('statusImp').value;
        const statusGmud = this.form.get('statusGmud').value;
        const numeroImp = this.form.get('numeroImp').value;
        const numeroGmud = this.form.get('numeroGmud').value;
        const produto = this.form.get('produto').value;
        const ambiente = this.form.get('ambiente').value;
        const tipoData = this.form.get('tipoData').value;
        const dataInicio = this.form.get('dataInicio').value;
        const dataFim = this.form.get('dataFim').value;
        let dataCriacaoInicio = '';
        let dataCriacaoFinal = '';
        let dataExecucaoInicio = '';
        let dataExecucaoFinal = '';

        if (tipoData === 'data-criacao') {
            dataCriacaoInicio = dataInicio ? DataUtil.toDateBackend(dataInicio) : '';
            dataCriacaoFinal = dataFim ? DataUtil.toDateBackend(dataFim) : '';
        }
        if (tipoData === 'data-hora-execucao') {
            dataExecucaoInicio = dataInicio ? DataUtil.toDateBackend(dataInicio) : '';
            dataExecucaoFinal = dataFim ? DataUtil.toDateBackend(dataFim) : '';
        }
        const responsavelProducao = this.form.get('responsavelProducao').value;
        const responsavelDesenvolvimento = this.form.get('responsavelDesenvolvimento').value;

        const filtro: FiltroGmud = {
            impStatus: statusImp,
            gmudStatus: statusGmud,
            impNumero: numeroImp,
            gmudNumero: numeroGmud,
            produto,
            ambiente,
            dataCriacaoInicio,
            dataCriacaoFinal,
            dataExecucaoInicio,
            dataExecucaoFinal,
            respProducao: responsavelProducao,
            respDesenvolvimento: responsavelDesenvolvimento,
        };

        this.filtrar.emit(filtro);
    }
}


export class GmudlistGridFiltroCombos {
    static combos() {
        return [
            {
                listName: 'listaStatusImp',
                filterName: 'filterStatusImp',
                fieldValue: 'nome',
            },
            {
                listName: 'listaStatusGmud',
                filterName: 'filterstatusGmud',
                fieldValue: 'nome',
            },
            {
                listName: 'listaStatusGmud',
                filterName: 'filterstatusGmud',
                fieldValue: 'nome',
            },
            {
                listName: 'listaProdutos',
                filterName: 'filterProduto',
                fieldValue: 'nome',
            },
            {
                listName: 'listaAmbientes',
                filterName: 'filterAmbiente',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaResponsavelProducoes',
                filterName: 'filterResponsavelProducao',
                fieldValue: 'nome',
            },
            {
                listName: 'listaResponsavelDesenvolvimentos',
                filterName: 'filterResponsavelDesenvolvimento',
                fieldValue: 'nome',
            },
        ];
    }
}