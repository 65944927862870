import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Injector, OnInit, SimpleChanges } from '@angular/core';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { environment } from 'environments/environment';
import { Notificacao } from '../../shareds/interfaces/notificacao.interface';
import { NotificacaoService } from '../../shareds/services/notificacao.service';

@Component({
  selector: 'notificacao-historico',
  templateUrl: './notificacao-historico.component.html',
  styleUrls: ['./notificacao-historico.component.scss']
})
export class NotificacaoHistoricoComponent extends GridBase<Notificacao, FiltroBase, NotificacaoService> implements OnInit {

  selection = new SelectionModel<Notificacao>(true, []);

  constructor(
    protected _service: NotificacaoService,
    protected _injector: Injector,
    protected _changeDetectorRefs: ChangeDetectorRef,
    private _notificacaoService: NotificacaoService
  ) {
    super(_service, _injector, _changeDetectorRefs, 'NotificacaoGrid')
  }

  ngOnInit(): void {
    this.filtro = this.filtro ? this.filtro : {};
    this.filtro.sortField = this.filtro.sortField ? this.filtro.sortField : 'dataCriacao';
    this.filtro.sortOrder = this.filtro.sortOrder ? this.filtro.sortOrder : 'desc';
    this.initGrid(this.filtro)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.atualizarGrid(this.filtro || {}, () => this.selection.clear());
  }

  configDisplayedColumns(): GridCollumn[] {
    return [
      { chave: 'select' },
      { chave: 'data', label: 'Data' },
      { chave: 'mensagem', label: 'Mensagem' },
      { chave: 'tipo', label: 'Tipo' }
    ]
  }

  lidoTodos() {
    this._spinner.show()
    this._notificacaoService.lidoTodos().subscribe(
      () => {
        this._spinner.hide();
        this.atualizarGrid();
      },
      error => this.error(error)
    )
  }

  naoLidoTodos() {
    this._spinner.show()
    this._notificacaoService.naoLidoTodos().subscribe(
      () => {
        this._spinner.hide();
        this.atualizarGrid();
      },
      error => this.error(error)
    )
  }

  getMensagem(notificacao: Notificacao) {
    if (notificacao.link) {
      const link = notificacao.link.replace('/#/', '')
      return `<a style="color:#fff" href="${environment.host}/${link}">${notificacao.mensagem}</a>`;
    }
    return notificacao.mensagem;
  }

  selecionaLido(row) {
    if (row.lido) {
      this._notificacaoService.naoLido(row.id).subscribe(
        () => {
          this.atualizarGrid();
        },
        error => this.error(error)
      )
    }
    else {
      this._notificacaoService.lido(row.id).subscribe(
        () => {
          this.atualizarGrid();
        },
        error => this.error(error)
      )
    }
  }

}

