import { getLocaleTimeFormat } from '@angular/common';
import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { FiltroPermissao } from 'app/main/acl/permissao/shareds/interfaces/filtro-permissao.interface';
import { PermissaoGrupo } from 'app/main/acl/permissao/shareds/interfaces/permissao-grupo.interface';
import { Permissao } from 'app/main/acl/permissao/shareds/interfaces/permissao.interface';
import { PermissaoService } from 'app/main/acl/permissao/shareds/services/permissao.service';
import { UsuarioTipo } from 'app/main/usuario/shared/interfaces/usuario-tipo.interface';
import { Perfil } from '../../shareds/interfaces/perfil.interface';
import { PerfilService } from '../../shareds/services/perfil.service';

interface PermissaoCheckbox {
    permissao: Permissao;
    checked: boolean;
}

@Component({
    selector: 'perfil-form',
    templateUrl: './perfil-form.component.html',
    styleUrls: ['./perfil-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PerfilFormComponent extends FormBase implements OnInit {
    perfil: Perfil;
    permissaoGrupo: PermissaoGrupo;
    permissoes: Array<PermissaoCheckbox>;
    usuarioTipos: Array<UsuarioTipo>;
    permissoesGrupo: Array<PermissaoGrupo>;

    constructor(
        injector: Injector,
        private _permissaoService: PermissaoService,
        private _perfilService: PerfilService
    ) {
        super(injector);

        this._route.data.subscribe((data) => {
            let usuarioTipos = [];
            this.perfil = data.perfil || {};
            if (data.usuarioTipos) {
                usuarioTipos = data.usuarioTipos.filter(
                    (u) => u.is_permitido_adicionar_editar_perfil === true
                );
            }
            this.usuarioTipos = usuarioTipos;
            this.permissoesGrupo = data.permissoesGrupo || [];
            this.setForm(this.perfil);
        });
    }

    ngOnInit(): void { }

    selecionaGrupoPermissao(): void {
        const grupo: PermissaoGrupo = this.permissaoGrupo;
        const filtro: FiltroPermissao = {
            permissaoGrupo: grupo.id,
        };
        this._spinner.show();
        this._permissaoService.find(filtro).subscribe(
            (permissoes) => {
                this._spinner.hide();
                this.permissaoChecked(permissoes.data);
            },
            (error) => this.error(error)
        );
    }

    selecionaPermissao(perm: PermissaoCheckbox): void {
        perm.checked
            ? this.adicionaPermissao(perm.permissao)
            : this.removePermissao(perm.permissao);
    }

    salvar(): void {
        this.perfil = this.getForm();

        if (!this.perfil.permissoes || this.perfil.permissoes.length === 0) {
            this.error('Seleciona pelo menos uma permissão');
            return;
        }

        this._spinner.show();
        this._perfilService.save(this.perfil).subscribe(
            () => {
                this.sucess('Perfil salvo com sucesso');
                this._router.navigateByUrl('/acl/perfil');
            },
            (error) => this.error(error)
        );
    }

    protected crateForm(): { [key: string]: any } {
        return {
            nome: ['', Validators.required],
            tipo: ['', Validators.required],
        };
    }

    private setForm(perfil: Perfil): void {
        this.form.patchValue({
            nome: perfil.nome,
            tipo: perfil.tipo,
        });
    }

    private getForm(): Perfil {
        this.perfil.nome = this.form.get('nome').value;
        this.perfil.tipo = this.form.get('tipo').value;
        return this.perfil;
    }

    private permissaoChecked(permissoes: Array<Permissao>): void {
        this.perfil.permissoes = this.perfil.permissoes
            ? this.perfil.permissoes
            : [];
        this.permissoes = [];

        permissoes.forEach((permissao) => {
            let checked = false;
            this.perfil.permissoes.forEach((perm) => {
                if (perm.nome === permissao.nome) {
                    checked = true;
                }
            });
            this.permissoes.push({
                permissao: permissao,
                checked: checked,
            });
        });
    }

    private adicionaPermissao(permissao: Permissao): void {
        this.perfil.permissoes = this.perfil.permissoes
            ? this.perfil.permissoes
            : [];
        this.perfil.permissoes.push(permissao);
    }

    private removePermissao(permissao: Permissao): void {
        this.perfil.permissoes = this.perfil.permissoes
            ? this.perfil.permissoes
            : [];
        this.perfil.permissoes.splice(
            this.perfil.permissoes.findIndex((p) => p.id === permissao.id),
            1
        );
    }
}
