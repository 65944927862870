import { SelectionModel } from '@angular/cdk/collections';
import {
    ChangeDetectorRef,
    Component,
    Injector,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { DownloadUtil } from '@utils/utils/class/download.util';
import { FiltroOs } from '../../shareds/interfaces/filtro-os.interface';
import { Os } from '../../shareds/interfaces/os.interface';
import { OsModalService } from '../../shareds/services/os-modal.service';
import { OsService } from '../../shareds/services/os.service';

@Component({
    selector: 'os-grid',
    templateUrl: './os-grid.component.html',
    styleUrls: ['./os-grid.component.scss'],
})
export class OsGridComponent
    extends GridBase<Os, FiltroOs, OsService>
    implements OnChanges, OnInit {
    selection = new SelectionModel<Os>(true, []);

    constructor(
        protected _service: OsService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _modalOs: OsModalService
    ) {
        super(_service, _injector, _changeDetectorRefs, 'OsGrid');
    }

    ngOnInit(): void {
        this.initGrid(this.filtro || {});
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {}, () => this.selection.clear());
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'select' },
            { chave: 'seleciona' },
            { chave: 'os', label: 'OS' },
            { chave: 'status', label: 'Status' },
            { chave: 'banco', label: 'Banco' },
            { chave: 'time', label: 'Time' },
            { chave: 'usuario', label: 'Responsável' },
            { chave: 'empresa', label: 'Empresa' },
            { chave: 'inscricao', label: 'Inscrição' },
            { chave: 'sistema', label: 'Sistema' },
            //{chave:'osRelaciona', label: ''},
            //{chave:'osAberturaRelaciona', label: ''},
            { chave: 'categoria', label: 'Categoria' },
            { chave: 'dataCriacao', label: 'Data criação' },
            { chave: 'dataFinalizacao', label: 'Data finalização' },
            { chave: 'dataPlanejada', label: 'Data planejada' },
        ];
    }

    editarEmLote() {
        const ossSelecionadas = this.selection.selected.map((v) => v.id);
        this._modalOs
            .osEditar({ idsOs: ossSelecionadas })
            .subscribe((result) => {
                if (result) {
                    this.selection.clear();
                    this.atualizarGrid(this.filtro);
                }
            });
    }

    vincularEmLote() {
        const ossSelecionadas = this.selection.selected.map((v) => v.id);
        this._modalOs
            .vincularProjetos({ idsOs: ossSelecionadas })
            .subscribe((result) => {
                if (result) {
                }
            });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows =
            this.paginacao.pageSize <= this.paginacao.length
                ? this.paginacao.pageSize
                : this.paginacao.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.listaDataSource.data.forEach((row) =>
                this.selection.select(row)
            );
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Os): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1
            }`;
    }

    visualizar(os: Os) {
        this._router.navigateByUrl(`os/${os.id}/visualizar`);
    }

    editar(os: Os) {
        this._router.navigateByUrl(`os/${os.id}/editar`);
    }

    excluir(os: Os) {
        this._confirmation
            .confirm({
                titulo: 'Deseja realmente Excluir está OS?',
                mensagem:
                    'Caso confirme poderá ocorrer Falha na fluxo do Project Manager',
            })
            .subscribe((result) => {
                if (result) {
                    this._spinner.show();
                    this._service.delete(os.id).subscribe(
                        () => {
                            this._spinner.hide();
                            this.atualizarGrid(this.filtro || {});
                        },
                        (error) => this.error(error)
                    );
                }
            });
    }

    gerarRelatorio(): void {
        this._spinner.show();
        this._service.gerarRelatorio(this.filtro).subscribe(
            (result) => {
                // DownloadUtil.base64(
                //     result.data.arquivo,
                //     result.data.name,
                //     result.data.type
                // );
                this.sucess("Seu relatório está em processamento, acompanhe a tela de retirada de relatórios para download")
            },
            (error) => this.error(error)
        );
    }
}
