import { Component, Injector, OnInit } from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { SimNao } from '@utils/interfaces/shareds/sim-nao.interface';
import { DataUtil } from '@utils/utils/class/data.util';
import { ChecklistConfiguracaoLayout } from 'app/main/checklist/shared/interfaces/checklist-configuracao.interface';
import { Direcao } from 'app/main/checklist/shared/interfaces/direcao.interface';
import { ChecklistConfiguracaoArquivoModalService } from 'app/main/checklist/shared/services/checklist-configuracao-arquivo-modal.service';
import { TimeModalService } from 'app/main/time/shareds/services/time-modal.service';
import { UsuarioModalService } from 'app/main/usuario/shared/services/usuario-modal.service';
import { environment } from 'environments/environment';
import { FiltroProjetoAbertura } from '../../shareds/interfaces/filtro-projeto-abertura.interface';
import { ProjetoStatus } from '../../shareds/interfaces/projeto-status.interface';
import {
    Projeto,
    ProjetoRequest,
} from '../../shareds/interfaces/projeto.interface';
import { StatusReport } from '../../shareds/interfaces/status-report';
import { ProjetoService } from '../../shareds/services/projeto.service';

@Component({
    selector: 'projeto-cadastro',
    templateUrl: './projeto-cadastro.component.html',
    styleUrls: ['./projeto-cadastro.component.scss'],
})
export class ProjetoCadastroComponent extends FormBase implements OnInit {
    filtro: FiltroProjetoAbertura = {};
    projeto: Projeto = {
        adquirentes: undefined
    };
    direcoes: Array<Direcao> = [];
    dsName: ChecklistConfiguracaoLayout = {
        checklistConfiguracaoDsname: [],
    };
    isView: boolean = false;
    statuss: Array<ProjetoStatus> = [];
    adquirentes: any;
    listaStatusReport: Array<StatusReport> = [];
    listaSimNao: Array<SimNao> = [];

    constructor(
        private _projetoService: ProjetoService,
        private _modalUsuario: UsuarioModalService,
        private _modalTime: TimeModalService,
        private _modalArquivo: ChecklistConfiguracaoArquivoModalService,
        private _injector: Injector
    ) {
        super(_injector);
    }

    ngOnInit() {
        if (
            /\/projeto\/visualizar\/[0-9]+/.test(
                this._route['_routerState'].snapshot.url
            )
        ) {
            this.isView = true;
        }
        this._route.data.subscribe((data) => {
            this.projeto = data.projeto;
            this.adquirentes = this.projeto.adquirentes
            this.direcoes = data.direcoes;
            this.statuss = data.statuss;
            this.listaStatusReport = data.listaStatusReport;
            this.listaSimNao = data.listaSimNao;

            this.setForm(data.projeto);
        });

        if (this.projeto) {
            this.dsName = this.projeto.dsName.find(
                (d) => d.moduloId === this.projeto.modulo.id
            ) || { checklistConfiguracaoDsname: [] };
        }
    }

    protected crateForm(): { [key: string]: any; } {
        return {
            projetoStatus: [],
            dataPlanejadaInicio: [],
            dataPlanejadaFim: [],
            dataRealInicio: [],
            dataRealFim: [],
            statusReport: [],
            projetoFaturado: [],
        }
    }

    filtrar(filtro: FiltroProjetoAbertura) {
        this.filtro = Object.assign({}, filtro);
    }

    pesquisaUsuario() {
        this._modalUsuario.usuarioPesquisa({}).subscribe((usuario) => {
            this.projeto.usuario = usuario;
        });
    }

    limparUsuario() {
        this.projeto.usuario = {};
    }

    pesquisaTime() {
        this._modalTime.timePesquisa({}).subscribe((time) => {
            this.projeto.time = time;
        });
    }

    limparTime() {
        this.projeto.time = {};
    }

    salvar() {
        const projetoStatus = this.form.get('projetoStatus').value;
        const dataPlanejadaInicio = this.form.get('dataPlanejadaInicio').value;
        const dataPlanejadaFim = this.form.get('dataPlanejadaFim').value;

        const dataRealInicio = this.form.get('dataRealInicio').value;
        const dataRealFim = this.form.get('dataRealFim').value;

        const statusReport = this.form.get('statusReport').value;
        const projetoFaturado = this.form.get('projetoFaturado').value;

        this.projeto.projetoStatus = projetoStatus;
        this._spinner.show();
        const projeto: ProjetoRequest = {
            id: this.projeto.id,
            idStatus: projetoStatus.id,
            idUsuario: this.projeto.usuario.id ? this.projeto.usuario.id : null,
            idTime: this.projeto.time.id ? this.projeto.time.id : null,
            idStatusReport: statusReport.id,
            dataPlanejadaInicio: dataPlanejadaInicio ? DataUtil.toDateBackend(dataPlanejadaInicio) : undefined,
            dataPlanejadaFim: dataPlanejadaFim ? DataUtil.toDateBackend(dataPlanejadaFim) : undefined,
            dataRealInicio: dataRealInicio ? DataUtil.toDateBackend(dataRealInicio) : undefined,
            dataRealFim: dataRealFim ? DataUtil.toDateBackend(dataRealFim) : undefined,
            faturado: projetoFaturado.codigo ? parseInt(projetoFaturado.codigo) : undefined

        };
        this._projetoService.update(projeto).subscribe(
            () => {
                this._spinner.hide();
                this._notification.sucess('Projeto salvo com sucesso!');
            },
            (error) => this.error(error)
        );
    }

    modalArquivo() {
        this._modalArquivo
            .checklistArquivo(
                {
                    idChecklist: this.projeto.checklist.id,
                    idChecklistConfiguracao: this.dsName.id,
                },
                true
            )
            .subscribe(() => { });
    }

    visualizarCotacao(idCotacao) {
        const link = `${environment.host}/cotacao/${idCotacao}`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }

    visualizarChecklist(idChecklist) {
        const link = `${environment.host}/checklist/${idChecklist}/visualizar`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }

    getNomeDirecao(idDirecao): string {
        let retorno = '';
        if (this.direcoes) {
            const direcao = this.direcoes.find((d) => d.id === idDirecao);
            retorno = direcao.nome || '';
        }
        return retorno;
    }

    getNomeDsname(dsnames: Array<any>): string {
        let nome = '';
        dsnames.forEach((d) => (nome += d.dsnameCampo.exemplo));
        return nome;
    }

    selectedOptionId(o1: any, o2: any) {
        return o1.id == o2.id;
    }

    setForm(projeto: Projeto) {
        const faturado = projeto.faturado ? this.listaSimNao.find(result => result.codigo === "1") : this.listaSimNao.find(result => result.codigo === "0")
        this.form.patchValue({
            projetoStatus: projeto.projetoStatus,
            dataPlanejadaInicio: projeto.dataPlanejadaInicio,
            dataPlanejadaFim: projeto.dataPlanejadaFim,
            dataRealInicio: projeto.dataRealInicio,
            dataRealFim: projeto.dataRealFim,
            statusReport: projeto.projetoStatusReport,
            projetoFaturado: faturado,
        })
    }
}
