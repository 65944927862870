import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import {
    ChecklistConfiguracao,
    ChecklistConfiguracaoLayout,
} from '../../shared/interfaces/checklist-configuracao.interface';
import {
    ChecklistDsname,
    ChecklistDsnameRequest,
} from '../../shared/interfaces/checklist-dsname.interface';
import { Direcao } from '../../shared/interfaces/direcao.interface';
import { ChecklistDsnameModalService } from '../../shared/services/checklist-dsname-modal.service';
import { ChecklistConfiguracaoService } from '../../shared/services/checklist-configuracao.service';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import {
    ChecklistDsnameConfigTipo,
    ChecklistDsnameConfig,
} from '../../shared/interfaces/checklist-dsname-config.interface';
import { ChecklistDsnameModal } from '../../shared/interfaces/checklist-dsname-modal.interface';
import { ChecklistConfiguracaoArquivoModalService } from '../../shared/services/checklist-configuracao-arquivo-modal.service';

@Component({
    selector: 'checklist-dsname-layout',
    templateUrl: './checklist-dsname-layout.component.html',
    styleUrls: ['./checklist-dsname-layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChecklistDsnameLayoutComponent extends FormBase implements OnInit {
    @Input() titulo: string;
    @Output() salvando = new EventEmitter();
    checklist: Checklist;
    dsnameConfigTipos: Array<ChecklistDsnameConfigTipo>;
    direcoes: Array<Direcao> = [];
    isVisualizacao: boolean = false;
    private _configuracao: ChecklistConfiguracaoLayout;
    private tamanhoArquivo: number;

    get configuracao(): ChecklistConfiguracaoLayout {
        return this._configuracao;
    }
    @Input() set configuracao(configuracao: ChecklistConfiguracaoLayout) {
        this.form.reset();
        this._configuracao = configuracao;
        if (
            this._configuracao.checklistConfiguracaoDsname &&
            this._configuracao.checklistConfiguracaoDsname.length > 0
        ) {
            this.setForm();
        } else {
            this.direcoes.forEach((direcao) => {
                this._configuracao.checklistConfiguracaoDsname.push({
                    direcaoId: direcao.id,
                });
            });
        }
    }

    constructor(
        private _injector: Injector,
        private _dsnameModal: ChecklistDsnameModalService,
        private _checklistConfiguracaoService: ChecklistConfiguracaoService,
        private _modalArquivo: ChecklistConfiguracaoArquivoModalService
    ) {
        super(_injector);

        this._route.data.subscribe((data) => {
            this.direcoes = data.direcoes;
            this.checklist = data.checklist;
            this.dsnameConfigTipos = data.dsnameConfigTipos;
            this.tamanhoArquivo = data.dsnameLimite
                ? data.dsnameLimite.limite
                : 23;
        });
    }

    ngOnInit() {
        this._route.data.subscribe((data) => {
            this.isVisualizacao = data.isVisualizacao;
        });
    }

    addDsname(direcao: Direcao) {
        const layout = this.getLayoutPorDirecao(direcao.id);
        const checklistDsname = layout.dsnames
            ? layout.dsnames
            : this.preencheDsnameConfigTipos(layout.checklistDsname);
        const dataModal: ChecklistDsnameModal = {
            dsnames: checklistDsname || [],
            tamanhoArquivo: this.tamanhoArquivo,
        };

        this._dsnameModal
            .checklistDsname(dataModal)
            .subscribe((ChecklistDsname: Array<ChecklistDsname>) => {
                if (ChecklistDsname) {
                    this.preencheDsname(ChecklistDsname, direcao);
                }
            });
    }

    modalArquivo() {
        this._modalArquivo
            .checklistArquivo({
                idChecklist: this.checklist.id,
                idChecklistConfiguracao: this.configuracao.id,
            },
                this.isVisualizacao)
            .subscribe(() => { });
    }

    atualizarObj() {
        console.log(this.configuracao);
    }

    gravar() {
        this.configuracao.checklistConfiguracaoDsname.forEach((layout) => {
            this.direcoes.forEach((d) => {
                if (d.id === layout.direcaoId) {
                    layout.checklistDsname = this.converteDname(layout.dsnames);
                    layout.layout = this.form.get('layout' + d.nome).value;
                    layout.observacao = this.form.get(
                        'observacao' + d.nome
                    ).value;
                }
            });
        });

        const obj = {
            checklistConfiguracao: [],
        };
        obj.checklistConfiguracao.push(this.configuracao);

        this._spinner.show();
        this._checklistConfiguracaoService
            .save(this.checklist.id, obj)
            .subscribe(
                (config) => {
                    this.sucess(`Configuração ${this.titulo} salvo com sucesso!`);
                    this.salvando.emit(true);
                },
                (error) => this.error(error)
            );
    }

    protected crateForm(): { [key: string]: any } {
        return {
            dsnameRemessa: ['', Validators.required],
            layoutRemessa: ['', Validators.required],
            observacaoRemessa: [],
            dsnameRetorno: ['', Validators.required],
            layoutRetorno: ['', Validators.required],
            observacaoRetorno: [],
        };
    }

    private preencheDsname(
        dsnames: Array<ChecklistDsname>,
        direcao: Direcao,
        isSetForm?: boolean
    ) {
        dsnames = dsnames ? dsnames : [];
        let campo = '';
        dsnames.forEach((d) => (campo += d.exemplo));
        this.form.get('dsname' + direcao.nome).setValue(campo);
        if (!isSetForm) {
            const layout = this.getLayoutPorDirecao(direcao.id);
            layout.dsnames = dsnames;
        }
    }

    private getLayoutPorDirecao(idDirecao) {
        const layout = this._configuracao.checklistConfiguracaoDsname.filter(
            (c) => c.direcaoId === idDirecao
        );
        return layout && layout.length > 0 ? layout[0] : null;
    }

    private setForm() {
        this.configuracao.checklistConfiguracaoDsname.forEach((layout) => {
            if (layout && layout.direcaoId) {
                this.direcoes.forEach((d) => {
                    if (d.id === layout.direcaoId) {
                        layout.dsnames = this.preencheDsnameConfigTipos(
                            layout.checklistDsname || []
                        );
                        this.preencheDsname(layout.dsnames, d, true);
                        this.form
                            .get('layout' + d.nome)
                            .setValue(layout.layout);
                        this.form
                            .get('observacao' + d.nome)
                            .setValue(layout.observacao);
                    }
                });
            }
        });
    }

    private preencheDsnameConfigTipos(
        checklistDsnames: Array<ChecklistDsnameRequest>
    ): Array<ChecklistDsname> {
        checklistDsnames = checklistDsnames ? checklistDsnames : [];
        const lista: Array<ChecklistDsname> = [];
        checklistDsnames.forEach((d) => {
            const dsnameCampoTipoId = d.dsnameCampoTipoId;
            const dsnameCampoId = d.dsnameCampoId;
            const campo = dsnameCampoId
                ? this.getDsname(dsnameCampoTipoId, dsnameCampoId)
                : null;
            lista.push({
                valor: d.campoValor,
                ordem: d.ordem,
                tipo: this.getDsnameTipo(dsnameCampoTipoId),
                campo: campo,
                exemplo: campo ? campo.exemplo : d.campoValor,
            });
        });
        return lista;
    }

    private getDsnameTipo(idTipo: number): ChecklistDsnameConfigTipo {
        const lista = this.dsnameConfigTipos.filter((t) => t.id === idTipo);
        return lista && lista.length > 0 ? lista[0] : null;
    }

    private getDsname(idTipo: number, idDsname: number): ChecklistDsnameConfig {
        const listaTipos: ChecklistDsnameConfigTipo = this.getDsnameTipo(
            idTipo
        );
        const lista = listaTipos.campos.filter((t) => t.id === idDsname);
        return lista && lista.length > 0 ? lista[0] : null;
    }

    private converteDname(
        dsnames: Array<ChecklistDsname>
    ): Array<ChecklistDsnameRequest> {
        const dsnamesRequest: Array<ChecklistDsnameRequest> = [];
        dsnames.forEach((dsname, index) => {
            dsnamesRequest.push({
                dsnameCampoId: dsname.campo ? dsname.campo.id : null,
                dsnameCampoTipoId: dsname.tipo ? dsname.tipo.id : null,
                ordem: dsname.ordem,
                campoValor: dsname.valor,
                exemplo: dsname.exemplo,
            });
        });

        return dsnamesRequest;
    }
}
