import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { ConfirmationService } from '@utils/utils/confirmation/confirmation.service';
import { ContratoEditar } from '../../shared/interfaces/contrato-editar.interface';
import { ContratoSituacao } from '../../shared/interfaces/contrato-situacao.interface';
import { ContratoStatus } from '../../shared/interfaces/contrato-status.interface';
import { ContratoEdicaoService } from '../../shared/services/contrato-edicao.service';
import { ContratoSituacaoService } from '../../shared/services/contrato-situacao.service';
import { ContratoStatusService } from '../../shared/services/contrato-status.service';

@Component({
    selector: 'editar-contrato',
    templateUrl: './editar-contrato.component.html',
    styleUrls: ['./editar-contrato.component.scss'],
})
export class EditarContratoComponent extends FormBase implements OnInit {
    private _idsContrato: Array<number>;
    isView: boolean = false;
    listaSituacoes: Array<ContratoSituacao> = [];
    listaStatusContrato: Array<ContratoStatus> = [];

    checkSituacao: boolean = false;
    checkStatusContrato: boolean = false;
    todosFull: boolean = false;

    @Output() atualizado = new EventEmitter<boolean>();

    get idsContrato() {
        return this._idsContrato;
    }

    @Input() set idsContrato(idsContrato: Array<number>) {
        this._idsContrato = idsContrato;
        if (idsContrato && idsContrato.length > 0) {
            this.isView = true;
        }
    }

    constructor(
        private _injector: Injector,
        private _constratoSituacaoService: ContratoSituacaoService,
        private _contratoStatusService: ContratoStatusService,
        private _service: ContratoEdicaoService
    ) {
        super(_injector);
    }

    ngOnInit() {
        this._spinner.show();
        this._constratoSituacaoService.find({}).subscribe(
            (result) => {
                this.listaSituacoes = result.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
        this._contratoStatusService.find({}).subscribe(
            (result) => {
                this.listaStatusContrato = result.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
    }

    selecionaCheckSituacao(check) {
        this.checkSituacao = check;
        if (!check) {
            this.form.get('situacao').setValue('');
        }
    }
    selecionaCheckStatusFinanceiro(check) {
        this.checkStatusContrato = check;
        if (!check) {
            this.form.get('statusContrato').setValue('');
        }
    }

    selecionaTodos(check) {
        if (!check) {
            this.form.reset();
        }
        this.checkSituacao = check;
        this.checkStatusContrato = check;
    }

    isDesabled(chave: string) {
        switch (chave) {
            case 'checkSituacao':
                return !this.checkSituacao;
                break;
            case 'checkStatusContrato':
                return !this.checkStatusContrato;
                break;
        }
    }

    alterar() {
        const situacao = this.form.get('situacao').value;
        const statusContrato = this.form.get('statusContrato').value

        const contratoEditar: ContratoEditar = {
            situacaoId: situacao ? situacao : undefined,
            statusId: statusContrato ? statusContrato : undefined,
            contratoId: this._idsContrato
        };

        this._confirmation
            .confirm({
                titulo:
                    'Tem certeza que deseja continuar com a alteração do contrato?',

            })
            .subscribe((result) => {
                if (result) {
                    this.alterarExec(contratoEditar);
                }
            });
    }

    private alterarExec(contratoEditar: ContratoEditar) {
        this._spinner.show();
        this._service.update(contratoEditar).subscribe(
            () => {
                this.sucess('Contratos alteradas com sucesso!');
                this.atualizado.emit(true);
            },
            (error) => this.error(error)
        );
    }

    protected crateForm(): { [key: string]: any } {
        return {
            situacao: [],
            statusContrato: [],
        };
    }
}
