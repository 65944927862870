import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ProjetoCaixaPostalService } from '../../shareds/services/projeto-caixa-postal.service';


export interface PeriodicElement {
    name: string;
    position: number;
    symbol: string;
}

@Component({
    selector: 'projeto-abertura-caixa-postal-grid',
    templateUrl: './projeto-abertura-caixa-postal-grid.component.html',
    styleUrls: ['./projeto-abertura-caixa-postal-grid.component.scss']
})
export class ProjetoAberturaCaixaPostalGridComponent implements OnInit {

    displayedColumns: string[] = ['seleciona', 'position', 'symbol'];
    listaDataSource = new MatTableDataSource<any>();
    @Output() selecionado = new EventEmitter<PeriodicElement>();

    _data: any = {};

    get filtro(): any {
        return this._data;
    }
    @Input() set data(value: any) {
        this._data = value;
        this._projetoCaixaPostalService.find(value).subscribe(caixaPostal => {
            this.listaDataSource = new MatTableDataSource<any>(caixaPostal.data);
        });
    }

    constructor(private _projetoCaixaPostalService: ProjetoCaixaPostalService) { }

    ngOnInit() {
    }

    /** The label for the checkbox on the passed row */
    selecionar(row?: PeriodicElement) {
        this.selecionado.emit(row);
    }

}

