import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { DataUtil } from '@utils/utils/class/data.util';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { FaturamentoParcela, FaturamentoParcelaItensRequest, FaturamentoParcelaRequest } from '../../shared/interfaces/faturamento-parcela.interface';
import { FaturamentoProduto } from '../../shared/interfaces/faturamento-produto.interface';
import { Faturamento } from '../../shared/interfaces/faturamento.interface';
import { FaturamentoService } from '../../shared/services/faturamento.service';

@Component({
    selector: 'faturamento-item-nota',
    templateUrl: './faturamento-item-nota.component.html',
    styleUrls: ['./faturamento-item-nota.component.scss']
})
export class FaturamentoItemNotaComponent extends FormBase implements OnInit {

    public faturamento: Faturamento = {};
    public faturamentoItens: Array<FaturamentoItemNota> = [];
    public faturamentoProdutos: Array<FaturamentoProduto> = []

    @Input() chave: number;
    @Input() qtdeParcelas: number;
    @Input() isEdit: boolean = true;
    @Output() values = new EventEmitter<FaturamentoItemNotaRetorno>();


    _itensValores: any;
    get itensValores(): any {
        return this._itensValores;
    }
    @Input() set itensValores(value: any) {
        this._itensValores = value;
        this.calculaValorRestante()
    }

    _faturamentoParcela: FaturamentoParcela;
    get faturamentoParcela(): FaturamentoParcela {
        return this._faturamentoParcela;
    }
    @Input() set faturamentoParcela(value: FaturamentoParcela) {
        this._faturamentoParcela = value;
        this.setForm(value)
    }

    constructor(
        injector: Injector,
        protected _faturamentoService: FaturamentoService
    ) {
        super(injector, FaturamentoItemNotaCombos.combos());
    }

    ngOnInit() {
        this._route.data.subscribe((data) => {
            this.faturamento = data.faturamento;
            this.faturamentoProdutos = data.faturamentoProdutos;
            this.getListaItemLinha(data.faturamentoProdutos)

            this.optionList.listaStatusNota = data.listaStatusNota;

            this.refreshOptionsConfig();
        });
    }

    protected crateForm(): { [key: string]: any } {
        return {
            id: [],
            statusNota: [],
            valorNota: [],
            diasVencer: [],
            dataEmissao: [],
            dataEmissaoStr: [],
            dataVencimento: [],
            dataPagamento: [],
            notaEmitida: [],
            itensLinha: [],
            valorItem: [],
            quantidade: [],
            valorFaturar: [],
        };
    }

    getForm() {
        const id = this.form.get('id').value;
        const statusNota = this.form.get('statusNota').value;
        const diasVencer = this.form.get('diasVencer').value;
        const valorNota = this.form.get('valorNota').value;
        const notaEmitida = this.form.get('notaEmitida').value;
        let dataEmissao = this.form.get('dataEmissao').value;
        let dataVencimento = this.form.get('dataVencimento').value;
        let dataPagamento = this.form.get('dataPagamento').value;
        dataEmissao = DataUtil.toDateBackend(dataEmissao);
        dataVencimento = DataUtil.toDateBackend(dataEmissao);
        dataPagamento = DataUtil.toDateBackend(dataEmissao);

        const parcela: FaturamentoParcelaRequest = Object.assign({}, this._faturamentoParcela, {
            faturamentoParcelaId: id,
            diasAVencer: diasVencer,
            dataEmissao: dataEmissao,
            valorNota: valorNota,
            notaEmitida: notaEmitida,
            itens: this.getFormFaturamentoItens(),
        })

        this.values.emit(Object.assign({}, {
            chave: this.chave,
            parcela: parcela
        }));
    }

    setForm(parcela: FaturamentoParcela) {
        let dataEmissao = new Date();
        if (!parcela.faturamentoParcelaStatus) {
            parcela.faturamentoParcelaStatus = this.optionList.listaStatusNota.find(status => status.descricao === "A emitir")
        }
        if (parcela.faturamentoParcelaStatus && parcela.faturamentoParcelaStatus.descricao !== "A emitir") {
            dataEmissao = parcela.dataEmissao ? new Date(parcela.dataEmissao) : new Date();
        }

        this.form.patchValue({
            id: parcela.id ? parcela.id : undefined,
            statusNota: parcela.faturamentoParcelaStatus ? parcela.faturamentoParcelaStatus.descricao : undefined,
            valorNota: undefined,
            diasVencer: parcela.diasAVencer || 7,
            dataEmissao: dataEmissao,
            dataEmissaoStr: DataUtil.toDateView(dataEmissao),
            dataVencimento: parcela.dataVencimento ? DataUtil.toDateView(parcela.dataVencimento) : undefined,
            dataPagamento: parcela.dataDePagamento ? DataUtil.toDateView(parcela.dataDePagamento) : undefined,
            notaEmitida: parcela.notaFiscal,
        })

        if (parcela.faturamentoItens && parcela.faturamentoItens.length > 0) {
            parcela.faturamentoItens.forEach((item, index) => {
                this.addItemLinha(item);
            })
        }
        else {
            this.addItemLinha();
            this.getForm();
        }

        this.somaValorNota();
        this.calculaDataVencimento();
    }

    addFormFaturamentoItem(data: FaturamentoItemNota, index: number) {
        this.form.addControl(`itensLinha${index}`, new FormControl());
        this.form.addControl(`filterItenLinha${index}`, new FormControl());
        this.form.addControl(`valorItem${index}`, new FormControl());
        this.form.addControl(`quantidade${index}`, new FormControl());
        this.form.addControl(`valorFaturar${index}`, new FormControl());
        this.form.addControl(`valorTotalItem${index}`, new FormControl());
        this.form.addControl(`valorRestanteItem${index}`, new FormControl());


        if (data) {
            this.addFormItemLinha(data, index)
        }
    }

    addFormItemLinha(data, index) {
        const itemLinha = data ? data.cotacaoItemCotacao : undefined;
        let valorFaturar = 0
        let valorRestante = 0;

        if (data.valorFaturar) {
            valorFaturar = parseFloat(data.valorFaturar)
        }
        else if (this.qtdeParcelas === 1) {
            valorFaturar = parseFloat(itemLinha.valorTotal)
        }

        if (this.itensValores[itemLinha.id]) {
            valorRestante = itemLinha.valorTotal - this.itensValores[itemLinha.id];
        }
        this.form.get(`itensLinha${index}`).setValue(data ? data.id : undefined);
        this.form.get(`valorItem${index}`).setValue(itemLinha.valorItem);
        this.form.get(`quantidade${index}`).setValue(itemLinha.quantidade);
        this.form.get(`valorFaturar${index}`).setValue(valorFaturar);
        this.form.get(`valorTotalItem${index}`).setValue(itemLinha.valorTotal);
        this.form.get(`valorRestanteItem${index}`).setValue(valorRestante);
    }

    removeFormFaturamentoItem(item: FaturamentoItemNota, index: number) {
        this.form.removeControl(`itensLinha${index}`);
        this.form.removeControl(`valorItem${index}`);
        this.form.removeControl(`quantidade${index}`);
        this.form.removeControl(`valorFaturar${index}`);
        this.form.removeControl(`valorTotalItem${index}`);
        this.form.removeControl(`valorRestanteItem${index}`);
    }

    limparFormFaturamentoItem(index: number) {
        this.form.get(`itensLinha${index}`).setValue('');
        this.form.get(`valorItem${index}`).setValue('');
        this.form.get(`quantidade${index}`).setValue('');
        this.form.get(`valorFaturar${index}`).setValue('');
        this.form.get(`valorTotalItem${index}`).setValue('');
    }

    setValorRestanteItem(index: number, valor: number) {
        this.form.get(`valorRestanteItem${index}`).setValue(valor);
    }

    removeItemLinha(item: FaturamentoItemNota, index: number) {
        if (this.faturamentoItens.length === 1) {
            this.limparFormFaturamentoItem(index)
        }
        else {
            this.removeFormFaturamentoItem(item, index)
            this.faturamentoItens.splice(index, 1)
        }
        this.getForm();
    }

    addItemLinha(item?: FaturamentoItemNota) {
        const index = this.faturamentoItens.length;
        this.addFormFaturamentoItem(item, index);
        this.faturamentoItens[index] = {};
    }

    somaValorNota() {
        let valorNota: number = 0;
        this.faturamentoItens.forEach((item, index) => {
            let valor: number = this.form.get(`valorFaturar${index}`).value || 0;
            valorNota += valor;
        });
        this.form.get(`valorNota`).setValue(valorNota);
        this.getForm();
    }

    calculaValorRestante() {
        const itensValores = this._itensValores

        const itens = this.getFormFaturamentoItens();
        if (itens && itens.length > 0) {
            itens.forEach((item, index) => {
                if (itensValores[item.faturamentoItemId]) {
                    const valorFaturado = itensValores[item.faturamentoItemId];
                    const valorTotal = item.valorTotalItem
                    const valorRestante = valorTotal - valorFaturado
                    this.setValorRestanteItem(index, valorRestante)
                }
            })
        }

    }

    validaValorFaturar(event, index) {
        const valoTotalItem = this.form.get(`valorTotalItem${index}`).value;
        const valoFaturar = this.form.get(`valorFaturar${index}`).value;
        if (valoFaturar > valoTotalItem) {
            this._notification.warning('O valor a faturar não pode ser maior do que o valor total do item!');
            this.form.get(`valorFaturar${index}`).setValue("");
            return;
        }
        this.somaValorNota();
    }

    calculaDataVencimento() {
        const diaVencer = this.form.get('diasVencer').value || 0;
        const dataEmissaoForm = this.form.get('dataEmissao').value;
        const dataEmissao = dataEmissaoForm ? DataUtil.toDateBackend(dataEmissaoForm) : new Date();
        const dataVencimento = moment(dataEmissao).add(diaVencer, 'day').format('DD/MM/YYYY');
        this.form.get('dataVencimento').setValue(dataVencimento)
        this.getForm();
    }

    verificaListaItemLinhaSelecionados(itemSelecionado) {
        const listaItemLinha: Array<any> = []
        let selecionado = false;

        if (!itemSelecionado) {
            return;
        }

        this.faturamentoItens.forEach((item: FaturamentoItemNota, index: number) => {
            const itensLinha = this.form.get(`itensLinha${index}`).value;
            if (itensLinha === itemSelecionado.value.id) {
                selecionado = true;
            }
        })

        if (selecionado) {
            this._notification.warning("Item já selecionado!");
            this.form.get(itemSelecionado.source.ngControl.name).setValue("");
            return;
        }

        const index = parseInt(itemSelecionado.source.ngControl.name.replace('itensLinha', ''))
        this.addDadosItemLinha(itemSelecionado.value, index);
        this.getForm()
        this.calculaValorRestante()
    }

    addDadosItemLinha(itemLinha, index) {
        const produto = this.faturamentoProdutos.find(produto => produto.id === itemLinha.id);
        this.addFormItemLinha(produto, index)

    }

    mostraBtoCancelarNota(): boolean {
        if (!this.isEdit) {
            return false;
        }

        if (this._faturamentoParcela && this._faturamentoParcela.faturamentoParcelaStatus) {
            const idStatus = this._faturamentoParcela.faturamentoParcelaStatus.id
            if ([1].find(id => id === idStatus)) {
                return true;
            }
        }

        return false;
    }

    mostraBtoEmitirNota(): boolean {
        if (!this.isEdit) {
            return false;
        }

        if (this._faturamentoParcela && this._faturamentoParcela.faturamentoParcelaStatus) {
            const idStatus = this._faturamentoParcela.faturamentoParcelaStatus.id
            if ([2, 3, 5].find(id => id === idStatus)) {
                return true;
            }
        }

        return false;
    }

    desabilitaParcela() {
        if (!this.isEdit) {
            return true;
        }

        if (this._faturamentoParcela && this._faturamentoParcela.faturamentoParcelaStatus) {
            const idStatus = this._faturamentoParcela.faturamentoParcelaStatus.id
            if ([2, 3, 5].find(id => id === idStatus)) {
                return false;
            }
        }

        return true;
    }

    private getListaItemLinha(produtos: Array<FaturamentoProduto>) {
        this.optionList.listaItenLinha = [];
        produtos.forEach(produto => {
            if (produto.cotacaoItemCotacao && produto.cotacaoItemCotacao.itemCotacao) {
                this.optionList.listaItenLinha.push({
                    id: produto.id,
                    codigo: produto.cotacaoItemCotacao.itemCotacao.codigo,
                    descricao: produto.cotacaoItemCotacao.itemCotacao.descricao
                })
            }
        })
    }

    private getFormFaturamentoItens() {
        const listaItens: Array<FaturamentoParcelaItensRequest> = [];
        this.faturamentoItens.forEach((item: FaturamentoItemNota, index: number) => {
            const itensLinha = this.form.get(`itensLinha${index}`).value;
            const valorFaturar = this.form.get(`valorFaturar${index}`).value;
            const valorTotalItem = this.form.get(`valorTotalItem${index}`).value;
            // const quantidade = this.form.get(`quantidade${index}`).value;

            listaItens.push({
                faturamentoItemId: itensLinha,
                valor: valorFaturar,
                valorTotalItem: valorTotalItem
            })
        })

        return listaItens;
    }

}

export class FaturamentoItemNotaCombos {
    static combos() {
        return [
            {
                listName: 'listaStatusNota',
                filterName: 'filterStatusNota',
                fieldValue: 'descricao'
            },
            {
                listName: 'listaItenLinha',
                fieldValue: 'descricao'
            }
        ]
    }
}

export interface FaturamentoItemNota {
    cotacaoItemCotacao?: any;
}

export interface FaturamentoItemNotaRetorno {
    chave: number;
    parcela: FaturamentoParcelaRequest;
}