import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Response } from '@utils/interfaces/http/response.interface';
import { RelacionamentoAberturaEditar } from '../interfaces/relacionamento-abertura-editar.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';

@Injectable({
    providedIn: 'root',
})
export class RelacionamentoAberturaEditarService extends ServiceBase<RelacionamentoAberturaEditar> {
    private path: string = `${environment.server}/relacionamento-abertura`;

    constructor(private _http: HttpClient) {
        super();
    }

    update(cotacaoEdicao: RelacionamentoAberturaEditar): Observable<Response<RelacionamentoAberturaEditar>> {
        const url = `${this.path}`;
        return this._http.put<Response<RelacionamentoAberturaEditar>>(url, cotacaoEdicao);
    }

    alteracaoEmLote(idsRelacionamentoAbertura: RelacionamentoAberturaEditar): Observable<Response<RelacionamentoAberturaEditar>> {
        const params: string = idsRelacionamentoAbertura.idsRelacionamentoAbertura ? this.toGetParams(idsRelacionamentoAbertura.idsRelacionamentoAbertura) : '';
        const url = `${this.path}/atualizar-lote/params`;
        return this._http.put<Response<RelacionamentoAberturaEditar>>(url, idsRelacionamentoAbertura);
    }
}
