import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { ModelBase } from '@utils/base/model-base/model-base';
import { ObjectUtil } from '@utils/utils/class/object.util';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { ChecklistConectividade } from '../../shared/interfaces/checklist-conectividade.interface';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { ChecklistConectividadeService } from '../../shared/services/checklist-conectividade.service';

@Component({
    selector: 'checklist-conectividade',
    templateUrl: './checklist-conectividade.component.html',
    styleUrls: ['./checklist-conectividade.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChecklistConectividadeComponent extends ComponentBase implements OnInit {
    @Input() checklist: Checklist;
    @Output() gravando = new EventEmitter();
    conectividades: Array<ChecklistConectividade> = [];
    conectividadeSelecionada: ChecklistConectividade;
    conectividadeForm: ModelBase;
    isVisualizacao: boolean = false;
    outros: string;

    constructor(
        private _checklistConectividadeService: ChecklistConectividadeService,
        private _injector: Injector
    ) {
        super(_injector);
        this.conectividadeSelecionada = {};
        this.outros = '';
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            data.conectividades.forEach((c: ChecklistConectividade) => {
                if (c.selected) {
                    this.conectividadeSelecionada = c;
                    if (c.isOutros) {
                        this.outros = c.descricao;
                    }
                    this.conectividadeForm = this.conectividadeSelecionada.json ? JSON.parse(this.conectividadeSelecionada.json) : undefined;
                }
            });
            this.conectividades = data.conectividades;
            this.isVisualizacao = data.isVisualizacao;
        });
    }

    selecionaConectividade(conectividade: ChecklistConectividade): void {
        if (!conectividade.isOutros) {
            this.outros = '';
        }
        this.conectividadeSelecionada = Object.assign({}, conectividade);
        this.conectividadeForm = this.conectividadeSelecionada.json;
    }

    gravar(): void {
        if (!this.conectividadeForm && !this.conectividadeSelecionada.isOutros) {
            this._notification.error('Preencha os campos da conectividade');
            return;
        }
        this._spinner.show();
        this._checklistConectividadeService
            .save(this.checklist.id, {
                id: this.conectividadeSelecionada.id,
                outros: this.outros,
                json: this.conectividadeForm ? JSON.stringify(this.conectividadeForm) : ''
            })
            .subscribe(
                () => {
                    this.gravando.emit(true);
                    this.sucess('Conectividade atualizada com sucesso');
                },
                (error) => this.error(error));
    }

    getConectividade(conectividadeForm: ModelBase) {
        if (!ObjectUtil.compare(conectividadeForm, this.conectividadeForm)) {
            this.conectividadeForm = Object.assign({}, conectividadeForm);
        }
    }

    mostraForm(conectividade: ChecklistConectividade, chave: string): boolean {
        let isViewForm = false;
        if (conectividade.chave == this.conectividadeSelecionada.chave && conectividade.chave === chave) {
            switch (conectividade.chave) {
                case 'FAST_WEB': isViewForm = true; break;
                case 'WEB_SERVICE_EDI': isViewForm = true; break;
                case 'API_OPEN_BANKING': isViewForm = true; break;
                case 'SFTP': isViewForm = true; break;
                case 'FINNET_FAST_CLIENT': isViewForm = true; break;
                case 'WEB_DAV': isViewForm = true; break;
                case 'CONNECT_DIRECT': isViewForm = true; break;
                case 'B2BI': isViewForm = true; break;
            }
        }

        return isViewForm;
    }

    mostaVisualizacaoConectividade(conectividade) {
        return this.isVisualizacao && conectividade.id === this.conectividadeSelecionada.id
    }

}
