import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { FaturamentoService } from 'app/main/faturamento/shared/services/faturamento.service';
import { TabelaDominio } from 'app/main/tabela-dominio/shareds/interfaces/tabela-dominio.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TipoSetupFinanceiroListaResolver implements Resolve<TabelaDominio> {

    constructor(private _financeiroService: FaturamentoService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): TabelaDominio | Observable<any> | Promise<any> {
        return this._financeiroService
            .findTipoSetup()
            .pipe(map((result) => result.data))
    }

}