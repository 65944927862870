import { AuthLocalStorageService } from '../services/auth-local-storage.service';
import {
    CanActivate,
    CanLoad,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
} from '@angular/router';
import { UsuarioLogado } from '@utils/interfaces/usuario/usuario-logado.interface';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { Role } from 'app/fuse-config/role';
import { PermissaoUtil } from '@utils/utils/class/permissao.utl';
import { AuthService } from 'app/main/public/auth/shared/services/auth.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export interface GuardConfigPermission {
    path: string;
    role: Role;
}

export abstract class BaseGuard
    implements CanActivate, CanLoad, CanActivateChild {
    usuarioLogado: UsuarioLogado;

    constructor(
        private _authService: AuthService,
        private _authLocalStorage: AuthLocalStorageService,
        private _notification: NotificationService,
        private router: Router
    ) {
        this.usuarioLogado = _authLocalStorage.getUsuario();
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.isAuthenticated();
    }

    canLoad(): Observable<boolean> | Promise<boolean> | boolean {
        return this.isAuthenticated();
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        let isAccess: boolean;
        const listConfigPermissions: Array<GuardConfigPermission> = this.configPermissions();
        const configPermission: GuardConfigPermission = listConfigPermissions.find(
            (config) => {
                if (config.path) {
                    const regex = new RegExp(`^${config.path}$`);
                    return regex.test(state.url);
                }
            }
        );

        if (configPermission && this.usuarioLogado) {
            isAccess = PermissaoUtil.isAccess(
                this.usuarioLogado,
                configPermission.role
            );
        }
/*
        if (!isAccess) {
            this._notification.warning(
                'Usuário sem permissão para acessar a página'
            );
            this.router.navigate(['home']);
        }
*/      isAccess = true;
        return isAccess;
    }

    protected abstract configPermissions(): Array<GuardConfigPermission>;

    private isAuthenticated(): Observable<boolean> {
        return this._authService.isLogado().pipe(
            switchMap((val) => {
                if (!val) {
                    this.router.navigate(['auth', 'login']);
                }
                return of(val);
            })
        )
    }
}
