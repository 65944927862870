import { ChangeDetectorRef, Component, EventEmitter, Injector, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';
import { UsuarioService } from 'app/main/usuario/shared/services/usuario.service';

@Component({
    selector: 'pesquisa-usuario',
    templateUrl: './pesquisa-usuario.component.html',
    styleUrls: ['./pesquisa-usuario.component.scss'],
})
export class PesquisaUsuarioComponent
    extends GridBase<Usuario, FiltroBase, UsuarioService>
    implements OnChanges, OnInit {
    @Output() selecionado = new EventEmitter();

    constructor(
        protected _service: UsuarioService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void {
        this.initGrid(this.filtro || {});
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {});
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'nome', label: '' },
            { chave: 'email', label: '' },
        ];
    }

    selecionar(usuario: Usuario) {
        this.selecionado.emit(usuario);
    }
}
