import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { ChecklistDadosEmpresa } from '../interfaces/checklist-dados-empresa.interface';
import { FiltroChecklistDadosEmpresa } from '../interfaces/filtro-checklist-dados-empresa.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistDadosEmpresaRelacionamentoService extends ServiceBase<ChecklistDadosEmpresa> {
    private path = `${environment.server}/checklist`;

    constructor(private _http: HttpClient) {
        super();
    }

    findRelacionamento(
        idChecklist?: number
    ): Observable<Response<Array<ChecklistDadosEmpresa>>> {
        const url = `${this.path}/${idChecklist}/checklist-relacionamento`;
        return this._http.get<Response<Array<ChecklistDadosEmpresa>>>(url);
    }

}
