import { Injectable, Injector } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { WebSocketService } from '@utils/services/web-socket.service';
import { Notificacao } from '../interfaces/notificacao.interface';
import { ConfiguracaoService, NotificacaoTipo } from 'app/main/configuracao/shareds/services/configuracao.service';

@Injectable({
    providedIn: 'root',
})
export class NotificacaoWebsocketService extends WebSocketService {
    private path = `${environment.server}/notificacao`;

    constructor(
        private _http: HttpClient,
        private _injector: Injector,
        private _configuracaoService: ConfiguracaoService
    ) {
        super(_injector);
    }

    totalNotificacoes(callback) {
        if (!this.ws) {
            callback(0);
            return;
        }


        this.ws.channel(`private-user.${this.usuarioLogado.id}`)
            .listen('.notificao', ((result: Notificacao) => {
                if (result.mensagem) {
                    this._configuracaoService.getConfiguracaoByChave(NotificacaoTipo.NOTIFICACAO_POPUP).subscribe(
                        config => {
                            if (config) {
                                const link = result.link.replace('/#/', '')
                                const mensagem = result.link ? `<a style="color:#fff" href="${environment.host}/${link}">${result.mensagem}</a>` : result.mensagem;
                                this._notification.info(mensagem, '', { enableHtml: true })
                            }
                        }
                    )
                }
                callback(result.qtde)
            }));
    }

}

