import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CotacaoStatus } from '../interfaces/cotacao-status.interface';
import { CotacaoStatusService } from '../services/cotacao-status.service';

@Injectable()
export class CotacaoStatusListaResolver implements Resolve<CotacaoStatus> {
    constructor(private _cotacaoStatusService: CotacaoStatusService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._cotacaoStatusService
            .find({})
            .pipe(map((result) => {
                return result.data.filter(val => val.descricao !== 'Cancelado')
            }));
    }
}
