import { Component, Injector, EventEmitter, OnInit, Output } from '@angular/core';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { FiltroRelatorio } from '../../shareds/interfaces/filtro-relatorio.interface';

@Component({
    selector: 'relatorio-grid-filtro',
    templateUrl: './relatorio-grid-filtro.component.html',
    styleUrls: ['./relatorio-grid-filtro.component.scss']
})
export class RelatorioGridFiltroComponent extends FormFiltroBase<FiltroRelatorio> implements OnInit {

    @Output() filtrar = new EventEmitter<FiltroRelatorio>();

    constructor(injector: Injector) {
        super('RelatorioGrid', injector, RelatorioGridFiltroCombos.combos());
    }

    ngOnInit() {
        this._route.data.subscribe((data) => {
            this.optionList.listaTipoRelatorios = data.listaTipoRelatorios;

            this.refreshOptionsConfig();
        });
    }

    camposFiltro(): string[] {
        return [];
    }

    protected crateForm(): { [key: string]: any; } {
        return {
            relatorioTipo: [],
        }
    }

    filtrarExecEnter(event): void {
        if (event.keyCode === 13) {
            this.filtrarExec();
        }
    }

    filtrarExec(): void {
        const relatorioTipo = this.form.get('relatorioTipo').value;

        const filtro: FiltroRelatorio = {
            relatorio: relatorioTipo ? relatorioTipo.id : undefined,
        };

        this.filtrar.emit(filtro);
    }

}

export class RelatorioGridFiltroCombos {
    static combos() {
        return [
            {
                listName: 'listaTipoRelatorios',
                filterName: 'filterTipoRelatorio',
                fieldValue: 'nome',
            },
        ]
    }
}

