import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { DownloadUtil } from '@utils/utils/class/download.util';
import { ProjetoDocumentoDownload } from '../../shareds/interfaces/projeto-documento-download.interface';
import { Projeto } from '../../shareds/interfaces/projeto.interface';
import { ProjetoDocumentoDownloadService } from '../../shareds/services/projeto-documento-download.service';
import { ProjetoDocumentoUploadService } from '../../shareds/services/projeto-documento-upload.service';

@Component({
  selector: 'projeto-download',
  templateUrl: './projeto-download.component.html',
  styleUrls: ['./projeto-download.component.scss']
})
export class ProjetoDownloadComponent extends ComponentBase implements OnInit {

  arquivosDownloads: Array<any> = [];
  @Input() projeto: Projeto;
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  files: Array<any>;
  uploaded: Boolean;

  arquivo: any;

  constructor(
    private _projetoDocumentoUploadService: ProjetoDocumentoUploadService,
    private _projetoDocumentoDownloadService: ProjetoDocumentoDownloadService,
    private _injector: Injector
  ) {
    super(_injector);
    this.projeto = {
      adquirentes: undefined
    };
  }

  ngOnInit() {
    this.atualizaListaDownloads();
  }

  // ==========================================
  //                  UPLOAD
  // ==========================================
  onFileDropped($event): void {
    this.prepareFile($event);
  }

  fileBrowseHandler(files): void {
    this.prepareFile(files);
  }

  deleteFile(file, i): void {
    if (file.progress > 0 && file.progress < 100) {
      return;
    }
    this.files.splice(i, 1);
  }

  deleteFiles(): void {
    this.files = null
  }

  upload(): void {
    this.uploaded = true
    if (this.projeto.id) {
      this.files.forEach((file, i) => {
        this._projetoDocumentoUploadService
          .upload(this.projeto.id, file)
          .subscribe(
            (event: HttpEvent<any>) => {
              if (event.type === HttpEventType.Response) {
                this._notification.sucess(
                  'Upload realizado com sucesso!'
                );

                if (this.files) {
                  if (this.files.length >= i + 1) {
                    this.uploaded = false
                    this.deleteFiles();
                    this.atualizaListaDownloads();
                  }
                }
              } else if (
                event.type === HttpEventType.UploadProgress
              ) {
                file.progress = Math.round(
                  (event.loaded * 100) / event.total
                );
              }
            },
            (error) => {
              this.error('Erro no upload do arquivo!');
            }
          );
      });
    }
  }

  prepareFile(files: FileList): void {
    this.uploaded = false
    this.files = Array.from(files);
    this.files.forEach(file => {
      file.progress = 0;
    })

    this.fileDropEl.nativeElement.value = '';
  }

  formatBytes(bytes, decimals = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return (
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    );
  }
  // ==========================================
  //                  DOWNLOAD
  // ==========================================
  download(arquivoDownload: ProjetoDocumentoDownload): void {
    this._spinner.show();
    this._projetoDocumentoDownloadService.download(arquivoDownload.id).subscribe(
      (result) => {
        DownloadUtil.base64(
          result.data.arquivo,
          result.data.name,
          result.data.type
        );
        this._spinner.hide();
      },
      (error) => this.error(error)
    );
  }

  remover(arquivoDownload: ProjetoDocumentoDownload): void {
    this._confirmation
      .confirm({ titulo: 'Deseja excluir esse arquivo?' })
      .subscribe((result) => {
        if (result) {
          this._spinner.show();
          this._projetoDocumentoDownloadService
            .delete(arquivoDownload.id)
            .subscribe(
              () => {
                this.sucess('Arquivo removido com sucesso!');
                this.atualizaListaDownloads();
              },
              (error) => this.error(error)
            );
        }
      });
  }

  private atualizaListaDownloads() {
    this._spinner.show();
    this._projetoDocumentoDownloadService.find(this.projeto.id).subscribe(
      (arquivos) => {
        this.arquivosDownloads = arquivos.data;
        this._spinner.hide();
      },
      (error) => this.error(error)
    );
  }

}
