import {
    ChangeDetectorRef,
    Component,
    Injector,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { ImportacaoCotacaoModalService } from 'app/main/importacao/importacao-cotacao/shared/services/importacao-cotacao-modal.service';
import { FiltroCotacao } from '../../shared/interfaces/filtro-cotacao.interface';
import { Cotacao } from '../../shared/interfaces/cotacao.interface';
import { CotacaoService } from '../../shared/services/cotacao.service';
import { CotacaoModalService } from '../../shared/services/cotacao-modal.service';
import { SelectionModel } from '@angular/cdk/collections';
import { environment } from 'environments/environment';
import { ContratoService } from 'app/main/contrato/shared/services/contrato.service';
import { FaturamentoService } from 'app/main/faturamento/shared/services/faturamento.service';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { CotacaoStatus } from '../../shared/interfaces/cotacao-status.interface';
import { CotacaoUtil } from '../../shared/utils/cotacao.util';
import { CotacaoStatusEnum } from '../../shared/enums/cotacao-status.enum';

@Component({
    selector: 'cotacao-grid',
    templateUrl: './cotacao-grid.component.html',
    styleUrls: ['./cotacao-grid.component.scss'],
})
export class CotacaoGridComponent extends GridBase<Cotacao, FiltroCotacao, CotacaoService> implements OnInit, OnChanges {
    public selection = new SelectionModel<Cotacao>(true, []);
    public listaCotacaoStatus: Array<CotacaoStatus> = []

    constructor(cotacaovalidacontratodalImportacaoCotacao: ImportacaoCotacaoModalService,
        private _modalCotacao: CotacaoModalService,
        private _contratoService: ContratoService,
        private _faturamentoService: FaturamentoService,
        protected _service: CotacaoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _modalImportacaoCotacao: ImportacaoCotacaoModalService
    ) {
        super(_service, _injector, _changeDetectorRefs, 'CotacaoGrid');
    }

    ngOnInit(): void {
        this.initGrid(this.filtro || {});
        this._route.data.subscribe((data) => {
            this.listaCotacaoStatus = data.statuss
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {}, () => this.selection.clear());
    }


    visualizar(cotacao: Cotacao): void {
        this._router.navigate(['cotacao', cotacao.id]);
    }

    editar() {
        const cotacoesSelecionadas = this.selection.selected.map((v) => v.id);
        this._modalCotacao
            .cotacaoEditar({ idCotacoes: cotacoesSelecionadas })
            .subscribe((result) => {
                if (result) {
                    this.selection.clear()
                    this.atualizarGrid(this.filtro);
                }
            });
    }

    historico(cotacao: Cotacao) {
        this._modalCotacao.historico(cotacao);
    }

    upload(): void {
        this._modalImportacaoCotacao.upload().subscribe((result) => { });
    }

    cancelar(cotacao: Cotacao) {
        this._confirmation.confirm({
            titulo: `Deseja realmente cancelar a contação 
            ${cotacao.numeroCotacao ? cotacao.numeroCotacao : ''}?`,
            mensagem: 'Ao ser cancelado a cotação não poderá mais ser alterada'
        }).subscribe(result => {
            if (result) {
                const cotacaoStatus = this.listaCotacaoStatus.find(val => val.descricao === 'Cancelado')
                cotacao = Object.assign({}, cotacao, {
                    idCotacaoStatus: cotacaoStatus.id,
                    valorSetupFaturado: CotacaoUtil.trataValorFaturado(cotacao.valorSetupFaturado)
                })

                this._service.update(cotacao).subscribe(
                    () => {
                        this.sucess('Cotação cancelada com sucesso');
                        this.atualizarGrid(this.filtro)
                    },
                    error => this.error(error)
                )
            }
        })
    }

    isMostraBtoCancelamento(cotacao: Cotacao): boolean {
        const status = cotacao.status;
        const statusBloqueio = [
            CotacaoStatusEnum.PAGAMENTO_EFETUADO,
            CotacaoStatusEnum.ISENTO,
            CotacaoStatusEnum.LIBERACAO_DIRETORIA_COM_SETUP,
            CotacaoStatusEnum.CANCELADO
        ]
        return !(statusBloqueio.includes(status.id))

    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows =
            this.paginacao.pageSize <= this.paginacao.length
                ? this.paginacao.pageSize
                : this.paginacao.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.listaDataSource.data.forEach((row) =>
                this.selection.select(row)
            );
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Cotacao): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1
            }`;
    }

    converteAcessoUsuario(
        acesso: 'criado' | 'preexistente' | 'acessado'
    ): string {
        let label = '';
        switch (acesso) {
            case 'criado':
                label = 'Usuário Criado';
                break;
            case 'preexistente':
                label = 'Usuário já existente';
                break;
            case 'acessado':
                label = 'Acessado';
                break;
            default:
                label = 'Não encontrado';
                break;
        }
        return label;
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'select' },
            { chave: 'seleciona' },
            { chave: 'status', label: 'Status' },
            { chave: 'nomeEmpresa', label: 'Nome da Empresa' },
            { chave: 'inscricao', label: 'Inscrição' },
            { chave: 'nomeCotacao', label: 'Nome da cotação' },
            { chave: 'numeroCotacao', label: 'Nº da cotação' },
            { chave: 'dataAceite', label: 'Data de aceite' },
            { chave: 'dataCriacao', label: 'Data de criação' },
            { chave: 'kickoffData', label: 'Data de Kick-off' },
            { chave: 'kickoffRealizado', label: 'Kick-off Realizado' },
            { chave: 'valorTotal', label: 'Valor total' },
            { chave: 'valorTotalRecorrente', label: 'Valor total recorrente' },
            { chave: 'acessoUsuario', label: 'Acesso usuário' },
            { chave: 'statusFinanceiro', label: 'Status financeiro' },
            { chave: 'tipoCobranca', label: 'Tipo de cobrança' },
            { chave: 'statusChecklist', label: 'Status do check-list' },
            { chave: 'valorFaturado', label: 'Valor do faturamento' },
            { chave: 'usuario', label: 'Responsável' },
            { chave: 'numeroContrato', label: 'Nº do contrato' },
        ];
    }

    gerarRelatorio(): void {
        this._spinner.show();
        this._service.gerarRelatorio(this.filtro).subscribe(
            (result) => {
                // DownloadUtil.base64(
                //     result.data.arquivo,
                //     result.data.name,
                //     result.data.type
                // );
                this.sucess("Seu relatório está em processamento, acompanhe a tela de retirada de relatórios para download");
            },
            (error) => this.error(error)
        );
    }

    criarContratos() {
        const cotacoesSelecionadas = this.selection.selected.map((v) => v.id);
        if (cotacoesSelecionadas && cotacoesSelecionadas.length > 0) {
            this._service.isContratos(cotacoesSelecionadas).subscribe(result => {
                if (result.data && result.data.length > 0) {
                    this._notification.error('Existem contratos criados para alguma cotação selecionada. Favor realizar filtros com a flag  Sem Contratos');
                }
                else {
                    this._confirmation.confirm({ titulo: 'Tem certeza que deseja criar CONTRATOS para as cotações selecionadas?' }).subscribe(confirmado => {
                        if (confirmado) {
                            this._spinner.show();
                            this._contratoService.createLote(cotacoesSelecionadas).subscribe(
                                result => {
                                    this.sucess("Contrato(s) criado(s) com sucesso!")
                                    this.atualizarGrid(this.filtro);
                                    this.selection.clear();
                                },
                                error => this.error(error)
                            )
                        }
                    })
                }
            })
        }
        else {
            this._notification.info('Selecione ao menos uma cotação');
        }
    }

    iniciarFaturamento() {
        const cotacoesSelecionadas = this.selection.selected.map((v) => v.id);
        if (cotacoesSelecionadas && cotacoesSelecionadas.length > 0) {
            this._service.isContratos(cotacoesSelecionadas).subscribe(result => {
                if (result.data && result.data.length > 0) {
                    this._confirmation.confirm({ titulo: 'Tem certeza que deseja iniciar o faturamento para as cotações selecionadas?' }).subscribe(confirmado => {
                        if (confirmado) {
                            this._spinner.show();
                            this._faturamentoService.iniciarFaturamento(cotacoesSelecionadas).subscribe(
                                result => {
                                    this.sucess("Faturamento(s) iniciado(s) com sucesso!")
                                    this.atualizarGrid(this.filtro);
                                    this.selection.clear();
                                },
                                error => this.error(error)
                            )
                        }
                    })
                }
                else {
                    this._notification.error('Não existem contratos criados para alguma cotação selecionada');
                }
            })
        }
        else {
            this._notification.info('Selecione ao menos uma cotação');
        }
    }

    visualizarContrato(idContrato) {
        const link = `${environment.host}/contrato/${idContrato}`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }
}
