import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { Empresa } from '../../shareds/interfaces/empresa.interface';
import { EmpresaService } from '../../shareds/services/empresa.service';

@Component({
    selector: 'empresa-cadastro',
    templateUrl: './empresa-cadastro.component.html',
    styleUrls: ['./empresa-cadastro.component.scss'],
})
export class EmpresaCadastroComponent extends ComponentBase implements OnInit {
    @Input() empresa: Empresa;
    @Output() salvo = new EventEmitter<boolean>();
    form: FormGroup;

    constructor(
        private _formBuilder: FormBuilder,
        private _empresaService: EmpresaService,
        private _injector: Injector
    ) {
        super(_injector);
    }

    ngOnInit() {
        this.form = this.createForm();
    }

    salvar() {
        const empresa: Empresa = {
            id: this.empresa.id ? this.empresa.id : undefined,
            inscricao: this.form.get('inscricao').value,
            nome: this.form.get('nome').value,
            idChecklist: this.empresa.idChecklist,
        };
        this._empresaService.save(empresa).subscribe(
            (empresaResp) => {
                this.sucess('Dados do empresa atualizados com sucesso');
                this.salvo.emit(true);
            },
            (error) => this.error(error)
        );
    }

    createForm() {
        return this._formBuilder.group({
            inscricao: [this.empresa.inscricao, Validators.required],
            nome: [this.empresa.nome, Validators.required],
        });
    }
}
