import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@utils/utils.module';
import { BancoService } from './shareds/services/banco.service';
import { BancoListaResolver } from './shareds/resolvers/banco-lista.resolver';
import { BancoRoutingModule } from './banco-routing.module';
import { BancoGridPageComponent } from './pages/banco-grid-page/banco-grid-page.component';
import { BancoGridComponent } from './components/banco-grid/banco-grid.component';
import {
    MatFormFieldModule,
    MatGridListModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule
} from '@angular/material';
import { BancoGuard } from './banco.guard';
import { BancoGridFiltroComponent } from './components/banco-grid-filtro/banco-grid-filtro.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalHistoricoBancoComponent } from './modal/modal-historico-banco/modal-historico-banco.component';
import { BancoModalService } from './shareds/services/banco-modal.service';
import { BancoHistoricoDetalheComponent } from './components/banco-historico-detalhe/banco-historico-detalhe.component';
import { BancoCadastroComponent } from './components/banco-cadastro/banco-cadastro.component';
import { BancoCadastroPageComponent } from './pages/banco-cadastro-page/banco-cadastro-page.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BancoDetalheResolver } from './shareds/resolvers/banco-detalhe.resolver';

@NgModule({
    declarations: [
        BancoGridPageComponent,
        BancoGridComponent,
        BancoGridFiltroComponent,
        ModalHistoricoBancoComponent,
        BancoHistoricoDetalheComponent,
        BancoCadastroComponent,
        BancoCadastroPageComponent,
    ],

    imports: [
        CommonModule,
        BancoRoutingModule,
        UtilsModule,

        //TABLE
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatListModule,
        MatGridListModule,
        MatTabsModule,

        //FORMULARIO
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgxMatSelectSearchModule,

    ],
    providers: [
        BancoService,
        BancoListaResolver,
        BancoDetalheResolver,
        BancoModalService,
        BancoGuard,
    ],
    entryComponents: [
        ModalHistoricoBancoComponent
    ]
})
export class BancoModule { }
