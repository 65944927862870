import { Injectable } from '@angular/core';
import { BaseGuard, GuardConfigPermission } from '@utils/guard/base.guard';
import { Role } from 'app/fuse-config/role';

@Injectable()
export class ChecklistGuard extends BaseGuard {
    protected configPermissions(): Array<GuardConfigPermission> {
        return [
            { path: '/checklist', role: Role.CHECKLIST_LISTAR },
            { path: '/checklist/[0-9]+/inicio', role: Role.CHECKLIST_LISTAR },
            {
                path: '/checklist/[0-9]+/contato',
                role: Role.CHECKLIST_CONTATO_ATUALIZAR,
            },
            {
                path: '/checklist/[0-9]+/conectividade',
                role: Role.CHECKLIST_CONECTIVIDADE_CADASTRAR,
            },
            {
                path: '/checklist/[0-9]+/dados-empresa',
                role: Role.CHECKLIST_RELACIONAMENTO_ATUALIZAR,
            },
            {
                path: '/checklist/[0-9]+/relacionamento',
                role: Role.CHECKLIST_RELACIONAMENTO_ATUALIZAR,
            },
            {
                path: '/checklist/[0-9]+/configuracao',
                role: Role.CHECKLIST_CONECTIVIDADE_CADASTRAR,
            },
            {
                path: '/checklist/[0-9]+/finalizacao-step',
                role: Role.CHECKLIST_CONTATO_ATUALIZAR,
            },
            {
                path: '/checklist/[0-9]+/bu',
                role: Role.CHECKLIST_CONTATO_ATUALIZAR,
            },
            {
                path: '/checklist/[0-9]+/finalizacao',
                role: Role.CHECKLIST_CONTATO_ATUALIZAR,
            },
            {
                path: '/checklist/[0-9]+/visualizar',
                role: Role.CHECKLIST_LISTAR,
            },


            {
                path: '/checklist/[0-9]+/visualizar/contato',
                role: Role.CHECKLIST_LISTAR,
            },
            {
                path: '/checklist/[0-9]+/visualizar/conectividade',
                role: Role.CHECKLIST_LISTAR,
            },
            {
                path: '/checklist/[0-9]+/visualizar/dados-empresa',
                role: Role.CHECKLIST_LISTAR,
            },
            {
                path: '/checklist/[0-9]+/visualizar/relacionamento',
                role: Role.CHECKLIST_LISTAR,
            },
            {
                path: '/checklist/[0-9]+/visualizar/configuracao',
                role: Role.CHECKLIST_LISTAR,
            },
        ];
    }
}
