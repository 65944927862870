import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    MatDatepickerModule,
    MatFormFieldModule,
    MatGridListModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
} from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@utils/utils.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { RelacionamentoAberturaGuard } from './relacionamento-abertura.guard';
import { RelacionamentoAberturaRoutingModule } from './relacionamento-abertura-routing.module';
import { RelacionamentoAberturaService } from './shared/services/relacionamento-abertura.service';
import { RelacionamentoAberturaGridFiltroComponent } from './components/relacionamento-abertura-grid-filtro/relacionamento-abertura-grid-filtro.component';
import { RelacionamentoAberturaDetalhePageComponent } from './pages/relacionamento-abertura-detalhe-page/relacionamento-abertura-detalhe-page.component';
import { RelacionamentoAberturaGridPageComponent } from './pages/relacionamento-abertura-grid-page/relacionamento-abertura-grid-page.component';
import { RelacionamentoAberturaGridComponent } from './components/relacionamento-abertura-grid/relacionamento-abertura-grid.component';
import { RelacionamentoAberturaEditarService } from './shared/services/relacionamento-abertura-editar.service';
import { ModalEditarRelacionamentoAberturaComponent } from './modal/modal-editar-relacionamento-abertura/modal-editar-relacionamento-abertura.component';
import { ModalHistoricoDetalheRelacionamentoAberturaComponent } from './modal/modal-historico-detalhe-relacionamento-abertura/modal-historico-detalhe-relacionamento-abertura.component';
import { ModalHistoricoRelacionamentoAberturaComponent } from './modal/modal-historico-relacionamento-abertura/modal-historico-relacionamento-abertura.component';
import { RelacionamentoAberturaModalService } from './shared/services/relacionamento-abertura-modal.service';
import { RelacionamentoAberturaHistoricoService } from './shared/services/relacionamento-abertura-historico.service';
import { RelacionamentoAberturaHistoricoDetalheComponent } from './components/relacionamento-abertura-historico-detalhe/relacionamento-abertura-historico-detalhe.component';
import { RelacionamentoAberturaHistoricoComponent } from './components/relacionamento-abertura-historico/relacionamento-abertura-historico.component';
import { EditarRelacionamentoAberturaComponent } from './components/editar-relacionamento-abertura/editar-relacionamento-abertura.component';
import { ModalEditarLoteRelacionamentoAberturaComponent } from './modal/modal-editar-lote-relacionamento-abertura/modal-editar-lote-relacionamento-abertura.component';
import { EditarLoteRelacionamentoAberturaComponent } from './components/editar-lote-relacionamento-abertura/editar-lote-relacionamento-abertura.component';

@NgModule({
    declarations: [
        // PAGES
        RelacionamentoAberturaDetalhePageComponent,
        RelacionamentoAberturaGridPageComponent,

        // COMPONENTES
        RelacionamentoAberturaGridComponent,
        RelacionamentoAberturaGridFiltroComponent,
        RelacionamentoAberturaHistoricoDetalheComponent,
        RelacionamentoAberturaHistoricoComponent,
        EditarRelacionamentoAberturaComponent,

        // MODAIS
        ModalEditarRelacionamentoAberturaComponent,
        ModalHistoricoDetalheRelacionamentoAberturaComponent,
        ModalHistoricoRelacionamentoAberturaComponent,
        ModalEditarLoteRelacionamentoAberturaComponent,
        EditarLoteRelacionamentoAberturaComponent,
    ],
    imports: [
        CommonModule,
        RelacionamentoAberturaRoutingModule,
        UtilsModule,
        NgxMatSelectSearchModule,


        //TABLE
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatListModule,
        MatGridListModule,

        //FORMULARIO
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatRadioModule,
    ],
    providers: [
        RelacionamentoAberturaGuard,
        RelacionamentoAberturaService,
        RelacionamentoAberturaEditarService,
        RelacionamentoAberturaModalService,
        RelacionamentoAberturaHistoricoService,
    ],
    entryComponents: [
        ModalEditarRelacionamentoAberturaComponent,
        ModalHistoricoDetalheRelacionamentoAberturaComponent,
        ModalHistoricoRelacionamentoAberturaComponent,
        ModalEditarLoteRelacionamentoAberturaComponent
    ],
})
export class RelacionamentoAberturaModule { }
