import {
    Component,
    Injector,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { SimNao } from '@utils/interfaces/shareds/sim-nao.interface';
import { DataUtil } from '@utils/utils/class/data.util';
import { Modulo } from 'app/main/modulo/shareds/interfaces/modulo.interface';
import { ModuloService } from 'app/main/modulo/shareds/services/modulo.service';
import { Produto } from 'app/main/produto/shareds/interfaces/produto.interface';
import { Time } from 'app/main/time/shareds/interfaces/time.interface';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';
import { FiltroProjeto } from '../../shareds/interfaces/filtro-projeto.interface';
import { ProjetoStatus } from '../../shareds/interfaces/projeto-status.interface';

@Component({
    selector: 'projeto-grid-filtro',
    templateUrl: './projeto-grid-filtro.component.html',
    styleUrls: ['./projeto-grid-filtro.component.scss'],
})
export class ProjetoGridFiltroComponent
    extends FormFiltroBase<FiltroProjeto>
    implements OnInit {
    produtos: Array<Produto> = [];
    modulos: Array<Modulo> = [];
    statuss: Array<ProjetoStatus> = [];
    times: Array<Time> = [];
    usuarios: Array<Usuario> = [];
    listaSimNao: Array<SimNao> = [];

    @Output() filtrar = new EventEmitter();

    constructor(
        private _injector: Injector,
        private _moduloService: ModuloService
    ) {
        super('ProjetoGrid', _injector, ProjetoGridFiltroCombos.combos());
    }

    ngOnInit() {
        this._route.data.subscribe((data) => {
            this.optionList.listaProdutos = data.produtos;
            this.optionList.listaStatuss = data.statuss;
            this.optionList.listaTimes = data.times;
            this.optionList.listaUsuarios = data.usuarios;
            this.optionList.listaStatusReports = data.listaStatusReports;
            this.optionList.modulos = data.modulos;
            this.listaSimNao = data.listaSimNao;

            this.refreshOptionsConfig();
        });
    }

    camposFiltro(): string[] {
        return [
            'status',
            'empresa',
            'projeto',
            'checklist',
            'inscricao',
            'cotacao',
            'produto',
            'modulo',
            'time',
            'usuario',
            'dataInicio',
            'dataFim',
            'statusReport',
            'projetoFaturado',
        ];
    }

    protected crateForm(): { [key: string]: any } {
        return {
            status: [],
            empresa: [],
            projeto: [],
            checklist: [],
            inscricao: [],
            cotacao: [],
            produto: [],
            modulo: [],
            time: [],
            usuario: [],
            tipoData: ['data-criacao'],
            dataInicio: [],
            dataFim: [],
            statusReport: [],
            projetoFaturado: [],
        };
    }

    filtrarExec(): void {
        const filtro: FiltroProjeto = {};

        //Time
        let time = this.form.get('time').value;
        time = time ? time : [];
        if (time) filtro.idTime = [].concat(time.map((t) => t.id || 'is_null'));

        //Responsável
        let usuario = this.form.get('usuario').value;
        usuario = usuario ? usuario : [];
        if (usuario)
            filtro.idResponsavel = [].concat(
                usuario.map((u) => u.id || 'is_null')
            );

        //StatusReport
        let statusReport = this.form.get('statusReport').value;
        if (statusReport) filtro.idProjetoStatusReport = statusReport.id;


        //Datas
        const tipoData = this.form.get('tipoData').value;
        const dataInicio = this.form.get('dataInicio').value;
        const dataFim = this.form.get('dataFim').value;
        const dataInicioStr = dataInicio ? DataUtil.toDateBackend(dataInicio) : '';
        const dataFimStr = dataFim ? DataUtil.toDateBackend(dataFim) : '';
        if (tipoData === 'data-criacao') {
            if (dataInicioStr) filtro.dataCadastroInicial = dataInicioStr;
            if (dataFimStr) filtro.dataCadastroFinal = dataFimStr;
        }
        if (tipoData === 'data-faturado') {
            if (dataInicioStr) filtro.dataFaturadoInicial = dataInicioStr;
            if (dataFimStr) filtro.dataFaturadoFinal = dataFimStr;
        }
        if (tipoData === 'data-planejada-inicial') {
            if (dataInicioStr) filtro.dataPlanejadaInicioInicial = dataInicioStr;
            if (dataFimStr) filtro.dataPlanejadaInicioFinal = dataFimStr;
        }
        if (tipoData === 'data-planejada-fim') {
            if (dataInicioStr) filtro.dataPlanejadaFimInicial = dataInicioStr;
            if (dataFimStr) filtro.dataPlanejadaFimFinal = dataFimStr;
        }
        if (tipoData === 'data-real-inicial') {
            if (dataInicioStr) filtro.dataRealInicioInicial = dataInicioStr;
            if (dataFimStr) filtro.dataRealInicioFinal = dataFimStr;
        }
        if (tipoData === 'data-real-fim') {
            if (dataInicioStr) filtro.dataRealFimInicial = dataInicioStr;
            if (dataFimStr) filtro.dataRealFimFinal = dataFimStr;
        }

        filtro.idProjetoStatus = this.form.get('status').value
            ? this.form.get('status').value.id
            : null;
        filtro.cotacaoNumero = this.form.get('cotacao').value;
        filtro.idProjeto = this.form.get('projeto').value;
        filtro.idChecklist = this.form.get('checklist').value;
        filtro.empresaNome = this.form.get('empresa').value;
        filtro.empresaInscricao = this.form.get('inscricao').value;
        filtro.idModulo = this.form.get('modulo').value
            ? this.form.get('modulo').value.id
            : null;
        filtro.idProduto = this.form.get('produto').value
            ? this.form.get('produto').value.id
            : null;

        const projetoFaturado = this.form.get('projetoFaturado').value;
        filtro.faturado = projetoFaturado ? projetoFaturado.codigo : null;

        this.filtrar.emit(filtro);
    }

    carregaOptionModulos(val: any) {
        if (this.form.get('produto').value) {
            const produto = this.form.get('produto').value;
            this._spinner.show();
            this._moduloService.findByProduto(produto.id).subscribe(
                (modulos) => {
                    this._spinner.hide();
                    this.modulos = modulos.data;
                },
                (error) => {
                    this.error(error);
                    this._spinner.hide();
                }
            );
        }
    }
}


export class ProjetoGridFiltroCombos {
    static combos() {
        return [
            {
                listName: 'listaProdutos',
                filterName: 'filterProdutos',
                fieldValue: 'nome',
            },
            {
                listName: 'modulos',
                filterName: 'filterModulo',
                fieldValue: 'nome',
            },
            {
                listName: 'listaStatuss',
                filterName: 'filterStatuss',
                fieldValue: 'nome',
            },
            {
                listName: 'listaTimes',
                filterName: 'filterTimes',
                fieldValue: 'nome',
            },
            {
                listName: 'listaUsuarios',
                filterName: 'filterUsuario',
                fieldValue: 'nome',
            },
            {
                listName: 'listaStatusReports',
                filterName: 'filterStatusReport',
                fieldValue: 'nome',
            },
        ];
    }
}