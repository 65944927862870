import {
    ChangeDetectorRef,
    Component,
    Injector,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { SelectionModel } from '@angular/cdk/collections';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { RelacionamentoAbertura } from '../../shared/interfaces/relacionamento-abertura.interface';
import { FiltroRelacionamentoAbertura } from '../../shared/interfaces/filtro-relacionamento-abertura.interface';
import { RelacionamentoAberturaService } from '../../shared/services/relacionamento-abertura.service';
import { RelacionamentoAberturaModalService } from '../../shared/services/relacionamento-abertura-modal.service';
import { environment } from 'environments/environment';
import { RelacionamentoAberturaAlterarLoteModalService } from '../../shared/services/relacionamento-abertura-alterar-lote-modal.service';

@Component({
    selector: 'relacionamento-abertura-grid',
    templateUrl: './relacionamento-abertura-grid.component.html',
    styleUrls: ['./relacionamento-abertura-grid.component.scss'],
})
export class RelacionamentoAberturaGridComponent extends GridBase<RelacionamentoAbertura, FiltroRelacionamentoAbertura, RelacionamentoAberturaService> implements OnInit, OnChanges {
    selection = new SelectionModel<RelacionamentoAbertura>(true, []);
    constructor(
        protected _service: RelacionamentoAberturaService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _modalRelacionamentoAbertura: RelacionamentoAberturaModalService,
        private _modalRelacionamentoAberturaAlterarLote: RelacionamentoAberturaAlterarLoteModalService
    ) {
        super(_service, _injector, _changeDetectorRefs, 'RelacionamentoAberturaGrid');
    }

    ngOnInit(): void {
        this.initGrid(this.filtro || {});
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {}, () => this.selection.clear());
    }

    selecionar(relacionamentoAbertura: RelacionamentoAbertura): void {
        this._router.navigate(['relacionamento-abertura', relacionamentoAbertura.id]);
    }

    editarLote(): void {
        const aberturaSelecionadas = this.selection.selected.map((v) => v.id);
        this._modalRelacionamentoAberturaAlterarLote.alteracaoEmLote({ idsRelacionamentoAbertura: aberturaSelecionadas }).subscribe((result) => { });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows =
            this.paginacao.pageSize <= this.paginacao.length
                ? this.paginacao.pageSize
                : this.paginacao.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.listaDataSource.data.forEach((row) =>
                this.selection.select(row)
            );
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: RelacionamentoAbertura): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1
            }`;
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'select' },
            { chave: 'seleciona' },
            { chave: 'statusProjeto', label: 'Status do projeto' },
            { chave: 'statusOs', label: 'Status da OS' },
            { chave: 'nomeEmpresa', label: 'Empresa' },
            { chave: 'inscricao', label: 'Inscrição' },
            { chave: 'banco', label: 'Banco' },
            { chave: 'agencia', label: 'Agência' },
            { chave: 'agenciaDV', label: 'DV' },
            { chave: 'conta', label: 'Conta' },
            { chave: 'contaDV', label: 'DV' },
            { chave: 'convenio', label: 'Convênio' },
            { chave: 'nomeCotacao', label: 'Nome da cotação' },
            { chave: 'numeroCotacao', label: 'N. da cotação' },
            { chave: 'projeto', label: 'Projeto' },
            { chave: 'os', label: 'OS' },
            { chave: 'responsavelProjeto', label: 'Responsável pelo projeto' },
            { chave: 'responsavelOs', label: 'Responsável pelo OS' },
            { chave: 'timeProjeto', label: 'Time do projeto' },
            { chave: 'timeOs', label: 'Time da OS' },
            { chave: 'produtoFinanceiro.nome', label: 'Produto Financeiro' },
            { chave: 'configuracao.layoutRemessa.descricao', label: 'Layout Remessa' },
            { chave: 'configuracao.dsnameRemessa.custoTrafego.descricao', label: 'Custo de Tráfego Remessa' },
            { chave: 'configuracao.dsnameRemessa.dsname', label: 'Dsname Remessa' },
            { chave: 'configuracao.dsnameRemessa.rename', label: 'Rename Remessa' },
            { chave: 'configuracao.layoutRetorno.descricao', label: 'Layout Retorno' },
            { chave: 'configuracao.dsnameRetorno.custoTrafego.descricao', label: 'Custo de Tráfego Retorno ' },
            { chave: 'configuracao.dsnameRetorno.dsname', label: 'Dsname Retorno' },
            { chave: 'configuracao.dsnameRetorno.rename', label: 'Rename Retorno' },
            { chave: 'caixaPostalRemessaOrigem', label: 'Caixa postal remessa origem' },
            { chave: 'caixaPostalRemessaDestino', label: 'Caixa postal remessa destino' },
            { chave: 'caixaPostalRetornoOrigem', label: 'Caixa postal retorno origem' },
            { chave: 'caixaPostalRetornoDestino', label: 'Caixa postal retorno destino' },
            { chave: 'caixaPostalAtivacaoPortalOrigem', label: 'Caixa postal ativação portal origem' },
            { chave: 'caixaPostalAtivacaoPortalDestino', label: 'Caixa postal ativação portal destino' },

        ];
    }

    gerarRelatorio(): void {
        this._spinner.show();
        this._service.gerarRelatorio(this.filtro).subscribe(
            (result) => {
                // DownloadUtil.base64(
                //     result.data.arquivo,
                //     result.data.name,
                //     result.data.type
                // );
                this.sucess("Seu relatório está em processamento, acompanhe a tela de retirada de relatórios para download");
            },
            (error) => this.error(error)
        );
    }

    visualizar(relacionamentoAbertura: RelacionamentoAbertura) {
        this._router.navigateByUrl(`projeto/abertura/visualizar/${relacionamentoAbertura.idOsAbertura}`);
    }

    historico(relacionamentoAbertura: RelacionamentoAbertura) {
        this._modalRelacionamentoAbertura.historico(relacionamentoAbertura);
    }

    ativacaoPortal() { }

    visualizarCotacao(idCotacao) {
        const link = `${environment.host}/cotacao/${idCotacao}`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }

    visualizarProjeto(idProjeto) {
        const link = `${environment.host}/projeto/cadastro/${idProjeto}`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }

    visualizarOs(idOs) {
        const link = `${environment.host}/os/${idOs}/editar`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }

}
