import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { CotacaoModulo } from 'app/main/cotacao/shared/interfaces/cotacao-modulo.interface';
import { ChecklistConfiguracaoLayout } from '../../shared/interfaces/checklist-configuracao.interface';
import { Checklist } from '../../shared/interfaces/checklist.interface';

@Component({
    selector: 'checklist-configuracao',
    templateUrl: './checklist-configuracao.component.html',
    styleUrls: ['./checklist-configuracao.component.scss'],
})
export class ChecklistConfiguracaoComponent implements OnInit {
    @Input() checklist: Checklist;
    @Output() gravando = new EventEmitter();
    recarrega: any;
    modulo: CotacaoModulo = {};
    configuracoes: Array<ChecklistConfiguracaoLayout> = [];
    configuracaoSelecionada: ChecklistConfiguracaoLayout;
    isVisualizacao: boolean = false;

    constructor(
        private _route: ActivatedRoute,
        private _notification: NotificationService
    ) { }

    ngOnInit() {
        this._route.data.subscribe((data) => {
            this.isVisualizacao = data.isVisualizacao;
            this.configuracoes = data.configuracoes;

            if (data.modulos && data.modulos.length > 0) {
                this.moduloSelecionado(data.modulos[0]);
            }
        });
    }

    getConfiguracaoPorModulo(idModulo: number): ChecklistConfiguracaoLayout {
        const config = this.configuracoes.filter(
            (c) => c.moduloId === idModulo
        );
        return config && config.length > 0 ? config[0] : null;
    }

    moduloSelecionado(modulo: CotacaoModulo) {
        if (modulo && modulo.id) {
            this.modulo = modulo;
            this.configuracaoSelecionada = this.getConfiguracaoPorModulo(
                this.modulo.id
            );
        } else {
            this._notification.error('Módulo não encontrado!');
        }
    }

    salvando(isSalvando) {
        if (isSalvando) {
            this.recarrega = Object.assign({}, { isRecarrega: true });
            this.gravando.emit(true);
        }
    }
}
