import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CotacaoDetalhePageComponent } from './pages/cotacao-detalhe-page/cotacao-detalhe-page.component';
import { CotacaoGridPageComponent } from './pages/cotacao-grid-page/cotacao-grid-page.component';
import { CotacaoGuard } from './cotacao.guard';
import { CotacaoDetalheResolver } from './shared/resolvers/cotacao-detalhe.resolver';
import { CotacaoStatusListaResolver } from './shared/resolvers/cotacao-status-lista.resolver';
import { ChecklistStatusListaResolver } from '../checklist/shared/resolvers/checklist-status-lista.resolver';
import { StatusFinanceiroListaResolver } from './shared/resolvers/status-financeiro-lista.resolver';
import { TipoCobrancaListaResolver } from './shared/resolvers/tipo-cobranca-lista.resolver';
import { UsuarioListaResolver } from '../usuario/resolvers/usuario-lista.resolver';
import { ProdutoListaResolver } from '../produto/shareds/resolvers/produto-lista.resolver';
import { CotacaoStatusListaFullResolver } from './shared/resolvers/cotacao-status-lista-full.resolver';

const routes: Routes = [
    {
        path: '',
        canActivate: [CotacaoGuard],
        canLoad: [CotacaoGuard],
        resolve: {
            statuss: CotacaoStatusListaFullResolver,
            statussChecklist: ChecklistStatusListaResolver,
            statussFinanceiro: StatusFinanceiroListaResolver,
            tipoCobrancas: TipoCobrancaListaResolver,
            usuarios: UsuarioListaResolver,
            listaProdutos: ProdutoListaResolver,
        },
        component: CotacaoGridPageComponent,
    },
    {
        path: ':id',
        canActivate: [CotacaoGuard],
        canLoad: [CotacaoGuard],
        resolve: {
            cotacao: CotacaoDetalheResolver,
            statuss: CotacaoStatusListaResolver,
            statussFinanceiro: StatusFinanceiroListaResolver,
            tipoCobrancas: TipoCobrancaListaResolver,
            usuarios: UsuarioListaResolver,
        },
        component: CotacaoDetalhePageComponent,
    },

    { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CotacaoRoutingModule { }
