import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalHistoricoChecklistComponent } from '../../modal/modal-historico-checklist/modal-historico-checklist.component';
import { ModalHistoricoDetalheChecklistComponent } from '../../modal/modal-historico-detalhe-checklist/modal-historico-detalhe-checklist.component';
import { ChecklistHistorico } from '../interfaces/checklist-historico.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistModalService {
    constructor(public dialog: MatDialog) {}

    historico(data: any) {
        const dialogRef = this.dialog.open(ModalHistoricoChecklistComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    historicoDetalhe(data: ChecklistHistorico) {
        const dialogRef = this.dialog.open(
            ModalHistoricoDetalheChecklistComponent,
            {
                width: '90%',
                data: data,
            }
        );

        return dialogRef.afterClosed();
    }
}
