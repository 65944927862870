import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { Banco } from '../../shareds/interfaces/banco.interface';
import { BancoService } from '../../shareds/services/banco.service';

@Component({
  selector: 'banco-historico-detalhe',
  templateUrl: './banco-historico-detalhe.component.html',
  styleUrls: ['./banco-historico-detalhe.component.scss']
})
export class BancoHistoricoDetalheComponent implements OnInit {

  @Input() historico: Banco = {}
  stored: any;

  constructor(
    protected _service: BancoService,
    protected _injector: Injector,
    protected _changeDetectorRefs: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this._service.historico(this.historico.id)
      .subscribe((data) => {
        this.stored = data.data;
      });
  }

}
