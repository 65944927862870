import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificacaoHistoricoPageComponent } from './pages/notificacao-historico-page/notificacao-historico-page.component';
import { NotificacaoHistoricoComponent } from './components/notificacao-historico/notificacao-historico.component';
import { UtilsModule } from '@utils/utils.module';
import { NotificacaoRoutingModule } from './notificacao-routing.module';
import { NotificacaoGuard } from './notificacao.guard';
import { NotificacaoService } from './shareds/services/notificacao.service';
import { MatDialogModule, MatPaginatorModule, MatSortModule, MatTableModule, MatTooltipModule } from '@angular/material';
import { NotificacaoWebsocketService } from './shareds/services/notificacao-websocket.service';

@NgModule({
  declarations: [NotificacaoHistoricoPageComponent, NotificacaoHistoricoComponent],
  imports: [
    CommonModule,
    NotificacaoRoutingModule,
    UtilsModule,

    MatDialogModule,

    // TABLE
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule,
  ],
  providers: [
    NotificacaoGuard,
    NotificacaoService,
    NotificacaoWebsocketService
  ]
})
export class NotificacaoModule { }
