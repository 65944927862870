import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { DownloadUtil } from '@utils/utils/class/download.util';
import { ConfirmationService } from '@utils/utils/confirmation/confirmation.service';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import {
    ChecklistConfiguracaoArquivo,
    ChecklistConfiguracaoArquivoDownload,
} from '../../shared/interfaces/checklist-configuracao-arquivo.interface';
import { ChecklistConfiguracaoLayout } from '../../shared/interfaces/checklist-configuracao.interface';
import { ChecklistConfiguracaoArquivoService } from '../../shared/services/checklist-configuracao-arquivo.service';
import { ChecklistConfiguracaoService } from '../../shared/services/checklist-configuracao.service';
@Component({
    selector: 'checklist-configuracao-arquivo-download',
    templateUrl: './checklist-configuracao-arquivo-download.component.html',
    styleUrls: ['./checklist-configuracao-arquivo-download.component.scss'],
})
export class ChecklistConfiguracaoArquivoDownloadComponent extends ComponentBase implements OnInit {
    arquivosDownloads: Array<ChecklistConfiguracaoArquivoDownload> = [];
    @Input() arquivo: ChecklistConfiguracaoArquivo;
    @Input() isView: boolean = false;
    private _atualizar: any;

    get atualizar(): any {
        return this._atualizar;
    }

    @Input() set atualizar(atualizar: any) {
        if (atualizar && atualizar.isAtualizar) {
            this.atualizaListaDownloads();
        }
    }

    constructor(
        private _checklistConfiguracaoArquivoService: ChecklistConfiguracaoArquivoService,
        private _checklistConfiguracaoService: ChecklistConfiguracaoService,
        private _inject: Injector,
        private spinner: SpinnerService
    ) {
        super(_inject);
    }

    ngOnInit() {
        this.atualizaListaDownloads();
    }

    download(arquivoDownload: ChecklistConfiguracaoArquivoDownload): void {
        this.spinner.show();
        this._checklistConfiguracaoArquivoService
            .download(this.arquivo, arquivoDownload.id)
            .subscribe(
                (result) => {
                    DownloadUtil.base64(
                        result.data.arquivo,
                        result.data.name,
                        result.data.type
                    );
                    this.spinner.hide();
                },
                (error) => this.error(error));
    }

    remover(arquivoDownload: ChecklistConfiguracaoArquivoDownload): void {
        this._confirmation
            .confirm({ titulo: 'Deseja excluir esse arquivo?' })
            .subscribe((result) => {
                if (result) {
                    this.spinner.show();
                    this._checklistConfiguracaoArquivoService
                        .delete(this.arquivo, arquivoDownload.id)
                        .subscribe(
                            () => {
                                this.spinner.hide();
                                this.sucess('Arquivo removido com sucesso!');
                                this.atualizaListaDownloads();
                            },
                            (error) => {
                                this.spinner.hide();
                                this.error(error);
                            }
                        );
                }
            });
    }

    private atualizaListaDownloads() {
        this.spinner.show();
        this._checklistConfiguracaoService
            .find({ idChecklist: this.arquivo.idChecklist })
            .subscribe(
                (c) => {
                    const configuracoes: Array<ChecklistConfiguracaoLayout> =
                        c.data.checklistConfiguracaos;
                    const configuracao = configuracoes.find(
                        (c) => c.id === this.arquivo.idChecklistConfiguracao
                    );
                    if (configuracao) {
                        this.arquivosDownloads =
                            configuracao.checklistConfiguracaoAnexo;
                    }
                    this.spinner.hide();
                },
                (error) => this.error(error));
    }
}
