import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FaturamentoProduto } from '../../shared/interfaces/faturamento-produto.interface';

@Component({
  selector: 'faturamento-produto-aba',
  templateUrl: './faturamento-produto-aba.component.html',
  styleUrls: ['./faturamento-produto-aba.component.scss']
})
export class FaturamentoProdutoAbaComponent implements OnInit {

  public listaProdutos: Array<FaturamentoProduto> = []

  constructor(
    private _route: ActivatedRoute
  ) { }

  ngOnInit() {
    this._route.data.subscribe((data) => {
      this.listaProdutos = data.listaProdutos;
    });
  }

}
