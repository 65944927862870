import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ChecklistRelacionamento } from '../../shared/interfaces/checklist-relacionamento.interface';

@Component({
    selector: 'modal-checklist-relacionamento-empresa-cadastro',
    templateUrl:
        './modal-checklist-relacionamento-empresa-cadastro.component.html',
    styleUrls: [
        './modal-checklist-relacionamento-empresa-cadastro.component.scss',
    ],
})
export class ModalChecklistRelacionamentoEmpresaCadastroComponent
    implements OnInit {
    tituloModal: string = 'Selecionar Dados da Empresa';

    constructor(
        public dialogRef: MatDialogRef<ModalChecklistRelacionamentoEmpresaCadastroComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
    ngOnInit() {}

    fechar(): void {
        this.dialogRef.close();
    }
}
